import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import ChangePasswordDialog from './ChangePasswordDialog'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { CHANGE_USERNAME } from '../graphql'
import MFASection from './MFASection'
import GenerateRecoveryKeyDialog from './GenerateRecoveryKeyDialog'

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: 'flex',
      maxWidth: '600px',
    },
    pwBtn: {
      marginLeft: theme.spacing(2),
    },
    snackbar: {
      marginBottom: theme.spacing(2),
    },
    textField: {
      maxWidth: '400px',
    }
  }
})

const Profile = ({ auth: { user, refetch } }) => {
  const classes = useStyles()
  const [showPasswordDialog, setShowPasswordDialog] = useState(false)
  const [showRecoveryKeyDialog, setShowRecoveryKeyDialog] = useState(false)
  const [changePasswordResult, setChangePasswordResult] = useState()

  const [userName, setUserName] = useState(user?.name)

  const [changeUserName, { data: userNameData, loading: userNameChanging, error: userNameError }] = useMutation(CHANGE_USERNAME, {
    variables: { name: userName },
    onCompleted: () => {
      refetch()
    }
  })

  useEffect(() => {
    document.title = 'Profile'
  }, [])

  useEffect(() => {
    setUserName(user.name)
  }, [user])


  const handleClosePasswordDialog = (result) => {
    setShowPasswordDialog(false)
    setChangePasswordResult(result)
  }

  const handleCloseRecoveryKeyDialog = (result) => {
    setShowRecoveryKeyDialog(false)
  }

  const handleCloseSnackbar = () => {
    setChangePasswordResult()
  }

  return (
    <Box m={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Account Details</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <Box width='100%' m={2} display='flex' flexDirection='column' sx={{
              gap: '16px', maxWidth: '400px'
            }}>
              <Box width='100%' display='flex' justifyContent='start' alignItems='center'>
                <TextField
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  disabled={userNameChanging}
                  fullWidth
                  required
                  label='Name'
                  className={classes.textField}
                  autoFocus
                />
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.pwBtn}
                  disabled={userNameChanging || !userName || userName === user.name}
                  onClick={changeUserName}
                >
                  Save
                </Button>
              </Box>
              <TextField
                value={user.email}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
                label='Email'
                placeholder=''
                className={classes.textField}
              />
              <Box mt={1} display='flex'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowPasswordDialog(true)
                  }}
                >
                  Change password
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.pwBtn}
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowRecoveryKeyDialog(true)
                  }}
                >
                  Change recovery key
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Two-factor Authentication</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <MFASection
            enabled={user?.mfa?.enabled}
            subject={user?.email}
            refetch={refetch}
          ></MFASection>
        </Grid>
      </Grid>
      <ChangePasswordDialog
        open={showPasswordDialog}
        onClose={handleClosePasswordDialog}
      />
      <GenerateRecoveryKeyDialog
        open={showRecoveryKeyDialog}
        onClose={handleCloseRecoveryKeyDialog}
      />
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={changePasswordResult !== undefined}
        variant={changePasswordResult && changePasswordResult.status}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={
          <span id='message-id'>
            {changePasswordResult && changePasswordResult.message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={classes.close}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Box>
  )
}

export default Profile
