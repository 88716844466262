import { useQuery, useSubscription } from '@apollo/client'
import {
  GET_SESSION,
  GET_PARTICIPANTS,
  SESSION_CHANGED,
  SESSION_PARTICIPANT_ADDED,
  SESSION_PARTICIPANTS_REMOVED,
} from '../graphql'
import Session from './Session'

const SessionWithData = ({ sessionId, ...otherProps }) => {
  const {
    data: { session } = {},
    error,
    loading,
  } = useQuery(GET_SESSION, {
    variables: { id: sessionId },
  })

  const { data: { participants } = [] } = useQuery(GET_PARTICIPANTS, {
    variables: { sessionId },
  })

  useSubscription(SESSION_CHANGED, {
    variables: { sessionId },
    skip: loading,
  })

  useSubscription(SESSION_PARTICIPANT_ADDED, {
    variables: { sessionId },
    skip: loading,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData?.data?.sessionParticipantAdded) {
        return
      }
      const participant = subscriptionData?.data?.sessionParticipantAdded
      const data = client.cache.readQuery({
        query: GET_PARTICIPANTS,
        variables: { sessionId },
      })
      if (
        participant &&
        data?.participants &&
        !data.participants.find((p) => p.user._id === participant.user._id)
      ) {
        client.cache.writeQuery({
          query: GET_PARTICIPANTS,
          variables: { sessionId },
          data: {
            participants: [...data.participants, participant],
          },
        })
      }
    },
  })

  useSubscription(SESSION_PARTICIPANTS_REMOVED, {
    variables: { sessionId },
    skip: loading,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const removed = subscriptionData?.data?.sessionParticipantsRemoved
      const data = client.cache.readQuery({
        query: GET_PARTICIPANTS,
        variables: { sessionId },
      })
      client.cache.writeQuery({
        query: GET_PARTICIPANTS,
        variables: { sessionId },
        data: {
          participants: data.participants.filter(
            (p) => !removed.find((r) => r === p.user._id)
          ),
        },
      })
    },
  })

  return (
    <Session
      session={session}
      participants={participants}
      error={error}
      loading={loading}
      {...otherProps}
    />
  )
}

export default SessionWithData
