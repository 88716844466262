import { useState } from 'react'
import { useMutation } from '@apollo/client'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import PasswordInput from './PasswordInput'
import ProgressButton from './ProgressButton'

import { LOGIN } from '../graphql'

const PasswordValidation = ({ username, buttonLabel, onComplete }) => {
  const [password, setPassword] = useState('')

  const [login, { data, loading, error }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data?.login?.status === 'ok') {
        onComplete(password)
      }
    },
  })

  const handleChange = (evt) => {
    setPassword(evt.target.value)
  }

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter' && canValidate()) {
      validatePassword()
    }
  }

  const handleClickValidate = () => {
    validatePassword()
  }

  const canValidate = () => {
    return !loading && username !== '' && password !== ''
  }

  const validatePassword = () => {
    login({
      variables: {
        username,
        password,
      },
    })
  }

  const validationError = () => {
    if (error || data?.login?.status === 'invalidPassword') {
      return true
    }
    return undefined
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <PasswordInput
          disabled={loading}
          error={validationError()}
          password={password}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box mt={{ xs: 0, sm: 1 }}>
          <ProgressButton
            variant='contained'
            color='primary'
            loading={loading}
            disabled={!Boolean(canValidate())}
            onClick={handleClickValidate}
          >
            {buttonLabel || 'Validate'}
          </ProgressButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PasswordValidation
