import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import MatrixCard from './MatrixCard'

const useStyles = makeStyles((theme) => ({
  titleText: {
    flex: 1,
  },
  content: {
    overflowY: 'auto',
  },
}))

const SelectMatrixDialog = ({ open, currentId, matrices, onClose }) => {
  const classes = useStyles()

  const [matrixId, setMatrixId] = useState()

  useEffect(() => {
    setMatrixId(currentId)
  }, [currentId])

  const handleClick = (evt, matrixId) => {
    setMatrixId(matrixId)
    onClose?.(evt, undefined, matrixId)
  }

  const handleClose = (evt, reason) => {
    onClose?.(evt, reason, matrixId)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyDown={(e) => {
        const key = e?.key
        if (key?.startsWith('Arrow')) {
          const i = matrices?.findIndex((m) => m._id === matrixId)
          let newIndex
          if (key === 'ArrowRight') {
            newIndex = Math.min(i + 1, matrices.length - 1)
          } else if (key === 'ArrowLeft') {
            newIndex = Math.max(i - 1, 0)
          } else if (key === 'ArrowDown') {
            // Note: assumes sm with 3 per row
            newIndex = Math.min(i + 3, matrices.length - 1)
          } else if (key === 'ArrowUp') {
            // Note: assumes sm with 3 per row
            newIndex = Math.max(i - 3, 0)
          }
          if (newIndex !== undefined && newIndex !== i) {
            setMatrixId(matrices?.[newIndex]._id)
          }
        } else if ('Enter' === key) {
          e.preventDefault()
          handleClose(e, undefined, matrixId)
        } else if ('Escape' === key) {
          e.preventDefault()
          onClose?.(e)
        }
      }}
    >
      <DialogTitle>
        <Box display='flex' justifyContent='stretch' alignItems='center'>
          <Typography className={classes.titleText} variant='h6'>
            Select matrix
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={1} align='center' justifyContent='flex-start'>
          {matrices?.map((m, i) => (
            <Grid
              item
              xs={12}
              sm={matrices.length > 2 ? 4 : 6}
              key={`sm-dlg-${i}`}
            >
              <MatrixCard
                matrix={m}
                selected={matrixId === m?._id}
                onClick={(e) => handleClick(e, m._id)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectMatrixDialog
