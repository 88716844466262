import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { CREATE_SESSION, GET_SESSIONS } from '../graphql'
import routes from '../routes'
import { isValidEmail, isGuest } from '../util'

import MatrixCard from './MatrixCard'
import ParticipantsInput from './ParticipantsInput'
import SelectMatrixDialog from './SelectMatrixDialog'

const useStyles = makeStyles((theme) => {
  return {
    alert: {
      margin: '1rem',
    },
    title: {
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    grid: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    textField: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    selectMatrixButton: {
      marginTop: theme.spacing(1),
      alignSelf: 'flex-end',
    },
  }
})

const CreateSession = ({ auth: { user, org } }) => {
  const classes = useStyles()
  const [participants, setParticipants] = useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [pendingParticipant, setPendingParticipant] = useState()
  const [showDialog, setShowDialog] = useState(false)
  const [matrixId, setMatrixId] = useState(org?.matrices?.[0]?._id)

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'New Risk Assessment'
  }, [])

  const [createSession, { loading }] = useMutation(CREATE_SESSION, {
    onCompleted: (data) => {
      navigate(`${routes.RISK_ASSESSMENTS}/${data.createSession._id}`)
    },
  })

  const handleAddParticipant = (value) => {
    const newValue = [...participants]
    newValue.push(value)
    setParticipants(newValue)
  }

  const handleDeleteParticipant = (participant, index) => {
    const newValue = [...participants]
    newValue.splice(index, 1)
    setParticipants(newValue)
  }

  const handleBlurParticipant = (e) => {
    const pEmail = e.target.value
    if (!isValidEmail(pEmail) || participants.includes(pEmail)) {
      return
    }
    if (e.relatedTarget && e.relatedTarget.id === 'create-session-btn') {
      setPendingParticipant(pEmail)
      return
    }
    setPendingParticipant()
    const newValue = [...participants]
    newValue.push(pEmail)
    setParticipants(newValue)
  }

  const matrix = org?.matrices?.find(
    (m) => matrixId === m._id
  )

  const handleSelectMatrixClick = (e) => {
    setShowDialog(true)
  }

  const handleCloseDialog = (e, reason, newMatrixId) => {
    setShowDialog(false)
    if (newMatrixId) {
      setMatrixId(newMatrixId)
    }
  }

  const handleCreateSession = (e) => {
    const parts = [...participants]
    if (pendingParticipant) {
      parts.push(pendingParticipant)
    }
    const matrixId = matrix?._id
    createSession({
      variables: {
        input: {
          name,
          description,
          participants: parts.map((p) => ({ email: p })),
          matrixId,
        },
      },
      update: (store, { data: { createSession } }) => {
        try {
          const data = store.readQuery({ query: GET_SESSIONS })
          store.writeQuery({
            query: GET_SESSIONS,
            data: {
              sessions: [...data.sessions, createSession],
            },
          })
        } catch (_) {
          /* ignore */
        }
      },
    })
  }
  const disabled = isGuest(org?.billing)
  if (disabled) {
    return (
      <Alert className={classes.alert} severity='error'>
        Your current plan does not allow you to create risk assessments.
      </Alert>
    )
  }

  return (
    <Container>
      <Box maxWidth='840px' my={{ xs: 2, sm: 4 }}>
        <Typography variant='h6' className={classes.title}>
          Create Risk Assessment
        </Typography>
        <Paper className={classes.grid}>
          <Grid container spacing={4} justifyContent='space-around'>
            <Grid
              item
              xs={12}
              sm={6}
              spacing={2}
              container
              direction='column'
              justifyContent='flex-start'
            >
              <Grid item>
                <TextField
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  required
                  id='standard-required'
                  label='Name'
                  className={classes.textField}
                  autoFocus
                />
              </Grid>
              <Grid item>
                <TextField
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                  id='standard-multiline-static'
                  label='Description'
                  multiline
                  minRows='4'
                  className={classes.textField}
                />
              </Grid>
              <Grid item>
                <ParticipantsInput
                  value={participants}
                  onAdd={handleAddParticipant}
                  onDelete={handleDeleteParticipant}
                  onBlur={handleBlurParticipant}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              spacing={2}
              justifyContent='center'
              align='center'
            >
              <Box display='flex' flexDirection='column' justifyContent='center'>
                {matrix && (
                  <MatrixCard
                    large
                    matrix={matrix}
                    onClick={handleSelectMatrixClick}
                  />
                )}
                <Button
                  disabled={!matrix}
                  className={classes.selectMatrixButton}
                  variant='contained'
                  color='primary'
                  onClick={handleSelectMatrixClick}
                >
                  Matrix
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12} container justifyContent='flex-end' align='center'>
          <Grid item>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => {
                navigate(`${routes.RISK_ASSESSMENTS}`)
              }}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button
              id='create-session-btn'
              className={classes.button}
              variant='contained'
              color='primary'
              disabled={loading || !name}
              onClick={handleCreateSession}
            >
              CREATE
            </Button>
          </Grid>
        </Grid>
        <SelectMatrixDialog
          open={showDialog}
          currentId={matrixId}
          matrices={org?.matrices}
          onClose={handleCloseDialog}
        />
      </Box>
    </Container>
  )
}

export default CreateSession
