import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as Logo } from '../svg/logo.svg'
import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginLeft: theme.spacing(3),
      width: 140,
      maxHeight: '34.5px',
      height: 'auto',
      cursor: 'pointer',
    },
  }
})

const BasicHeader = () => {
  const classes = useStyles()
  return (
    <AppBar position='static'>
      <Toolbar>
        <a href={routes.MAIN}>
          <Logo id='header-logo' className={classes.logo} />
        </a>
      </Toolbar>
    </AppBar>
  )
}

export default BasicHeader
