import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'

import { isProvinceRequired } from '../countries'

import InvoiceAddressDetails from './InvoiceAddressDetails'

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(2),
    },
  }
})

const InvoiceAddressDialog = (props) => {
  const { open, onClose, invoiceAddress } = props
  const classes = useStyles()
  const [firstName, setFirstName] = useState(invoiceAddress?.firstName ?? '')
  const [lastName, setLastName] = useState(invoiceAddress?.lastName ?? '')
  const [companyName, setCompanyName] = useState(
    invoiceAddress?.companyName ?? ''
  )
  const [streetAddress1, setStreetAddress1] = useState(
    invoiceAddress?.streetAddress1 ?? ''
  )
  const [streetAddress2, setStreetAddress2] = useState(
    invoiceAddress?.streetAddress2 ?? ''
  )
  const [country, setCountry] = useState(invoiceAddress?.country ?? '')
  const [city, setCity] = useState(invoiceAddress?.city ?? '')
  const [province, setProvince] = useState(invoiceAddress?.province ?? '')
  const [postalCode, setPostalCode] = useState(invoiceAddress?.postalCode ?? '')

  const handleChangeFirstName = (evt) => {
    setFirstName(evt.target.value)
  }

  const handleChangeLastName = (evt) => {
    setLastName(evt.target.value)
  }

  const handleChangeCompanyName = (evt) => {
    setCompanyName(evt.target.value)
  }

  const handleChangeStreetAddress1 = (evt) => {
    setStreetAddress1(evt.target.value)
  }

  const handleChangeStreetAddress2 = (evt) => {
    setStreetAddress2(evt.target.value)
  }

  const handleChangeCountry = (evt, value) => {
    setCountry(value)
  }

  const handleChangeCity = (evt) => {
    setCity(evt.target.value)
  }

  const handleChangeProvince = (evt) => {
    setProvince(evt.target.value)
  }

  const handleChangePostalCode = (evt) => {
    setPostalCode(evt.target.value)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleSave = () => {
    onClose &&
      onClose({
        firstName,
        lastName,
        companyName,
        streetAddress1,
        streetAddress2,
        country,
        city,
        province,
        postalCode,
      })
  }

  const canSave = () => {
    return (
      firstName &&
      lastName &&
      companyName &&
      streetAddress1 &&
      country &&
      city &&
      (isProvinceRequired(country) ? province : true) &&
      postalCode &&
      (firstName !== invoiceAddress?.firstName ||
        lastName !== invoiceAddress?.lastName ||
        companyName !== invoiceAddress?.companyName ||
        streetAddress1 !== invoiceAddress?.streetAddress1 ||
        streetAddress2 !== invoiceAddress?.streetAddress2 ||
        country !== invoiceAddress?.country ||
        province !== invoiceAddress?.province ||
        postalCode !== invoiceAddress?.postalCode)
    )
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box p={2}>
          <Typography variant='h6' className={classes.title}>
            Edit Invoice Address
          </Typography>
          <InvoiceAddressDetails
            firstName={firstName}
            lastName={lastName}
            companyName={companyName}
            streetAddress1={streetAddress1}
            streetAddress2={streetAddress2}
            country={country}
            city={city}
            province={province}
            postalCode={postalCode}
            onChangeFirstName={handleChangeFirstName}
            onChangeLastName={handleChangeLastName}
            onChangeCompanyName={handleChangeCompanyName}
            onChangeStreetAddress1={handleChangeStreetAddress1}
            onChangeStreetAddress2={handleChangeStreetAddress2}
            onChangeCountry={handleChangeCountry}
            onChangeCity={handleChangeCity}
            onChangeProvince={handleChangeProvince}
            onChangePostalCode={handleChangePostalCode}
          ></InvoiceAddressDetails>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button
            disabled={!canSave()}
            variant='contained'
            color='primary'
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvoiceAddressDialog
