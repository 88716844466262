import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import QRCode from 'qrcode.react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import { getUri as get2FAUri } from '../twoFactorAuth'

const useStyles = makeStyles((theme) => {
  return {
    link: {
      marginTop: theme.spacing(1),
    },
    key: {
      marginTop: theme.spacing(1),
    },
  }
})

const TwoFactorAuthKey = ({ value, subject }) => {
  const [showKey, setShowKey] = useState(false)
  const classes = useStyles()
  const handleShowKey = (evt) => {
    setShowKey(!showKey)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      minWidth={250}
    >
      <Box display='flex' flexDirection='column' alignItems='flex-end'>
        <QRCode value={get2FAUri(subject, 'RiskAce', value)} />
        <Link
          className={classes.link}
          component='button'
          color='textPrimary'
          onClick={handleShowKey}
        >
          {showKey ? 'Hide key' : 'Show key'}
        </Link>
      </Box>
      {showKey && (
        <Typography className={classes.key} variant='body1'>
          {value}
        </Typography>
      )}
    </Box>
  )
}

export default TwoFactorAuthKey
