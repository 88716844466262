import { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

import { COUNTRIES, getFromCountryCode } from '../countries'

// From https://material-ui.com/components/autocomplete/

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
})

const CountrySelect = (props) => {
  const classes = useStyles()

  const { value, onChange, ...otherProps } = props

  const handleChange = (evt, newValue) => {
    onChange && onChange(evt, newValue?.code)
  }

  return (
    <Autocomplete
      classes={{
        option: classes.option,
        ...otherProps?.classes,
      }}
      value={getFromCountryCode(value)}
      options={COUNTRIES}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code}) +{option.phone}
        </Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...otherProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      onChange={handleChange}
    />
  )
}

export default CountrySelect
