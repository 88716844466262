import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AccountCircle from '@material-ui/icons/AccountCircle'

import withAuth from './auth'

import { ReactComponent as Logo } from '../svg/logo.svg'
import { ReactComponent as Symbol } from '../svg/ra-symbol.svg'

import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: 'white',
    },
    symbol: {
      margin: theme.spacing(0, 2, 0, 1),
      width: 31,
      maxHeight: '30px',
      height: 'auto',
      cursor: 'pointer',
    },
    logo: {
      margin: theme.spacing(0, 4, 0, 2),
      width: 120,
      maxHeight: '30px',
      height: 'auto',
      cursor: 'pointer',
    },
    tab: {
      textTransform: 'inherit',
    },
    spacer: {
      flex: 1,
    },
    tab2Root: {
      minWidth: 'unset',
    },
    tab2Wrapper: {
      '&::after': {
        content: "'!';",
        position: 'absolute',
        fontSize: '8px',
        fontWeight: 800,
        right: '8px',
        top: '8px',
        clear: 'both',
        height: '12px',
        width: '12px',
        color: 'white',
        backgroundColor: theme.palette.error.dark,
        borderRadius: '50%',
        display: 'inline-block',
      },
    },
    profileButton: {
      marginLeft: 'auto',
    },
    userMenuDivider: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    userMenuIndicator: {
      height: '16px',
      width: '16px',
      color: 'white',
      backgroundColor: theme.palette.error.dark,
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: 800,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '8px',
      marginBottom: '2px',
    },
  }
})

const firstSegment = /^\/[^/]*/

const Header = ({ auth }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()
  const path = location?.pathname?.match(firstSegment)?.[0] ?? routes.RISK_ASSESSMENTS

  const [tab, setTab] = useState(
    path === routes.RISK_ASSESSMENTS || path === routes.ACTIONS ? path : false
  )
  const [tab2, setTab2] = useState(
    path === routes.PROFILE || path === routes.ORGANIZATION
      ? routes.PROFILE
      : false
  )

  const [showUserMenu, setShowUserMenu] = useState(false)

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only('xs'))

  let hasInvitations = auth?.invitations?.length > 0 ?? false

  const userTabRef = useRef()

  useEffect(() => {
    if (path === routes.PROFILE || path === routes.ORGANIZATION) {
      setTab(false)
      setTab2(routes.PROFILE)
    } else if (path === routes.RISK_ASSESSMENTS || path === routes.ACTIONS) {
      setTab(path)
      setTab2(false)
    }
  }, [path])

  const handleSessionsClick = (evt) => {
    evt.stopPropagation()
    navigate(routes.RISK_ASSESSMENTS)
  }

  const handeUserClick = () => {
    setShowUserMenu(true)
  }

  const handleProfileClick = () => {
    setShowUserMenu(false)
    navigate(routes.PROFILE)
  }

  const handleOrganizationClick = () => {
    setShowUserMenu(false)
    if (hasInvitations) {
      navigate(routes.INVITATIONS)
      return
    }
    navigate(routes.ORGANIZATION)
  }

  const handleLogoutClick = () => {
    auth?.logout()
  }

  const handleCloseUserMenu = () => {
    setShowUserMenu(false)
  }

  const getUserMenu = () => (
    <Menu
      open={showUserMenu}
      anchorEl={userTabRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={handleCloseUserMenu}
    >
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <MenuItem onClick={handleOrganizationClick}>Organization {hasInvitations && <span className={classes.userMenuIndicator}>!</span>}</MenuItem>
      <Divider className={classes.userMenuDivider}></Divider>
      <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
    </Menu>
  )

  return (
    <AppBar position='static' className={classes.root}>
      <Box display='flex' alignItems='center'>
        {xs ? (
          <Symbol
            id='header-icon'
            className={classes.symbol}
            onClick={handleSessionsClick}
          />
        ) : (
          <Logo
            id='header-logo'
            className={classes.logo}
            onClick={handleSessionsClick}
          />
        )}
        <Tabs value={tab}>
          <Tab
            className={classes.tab}
            value={routes.RISK_ASSESSMENTS}
            label='Assessments'
            component={Link}
            to={routes.RISK_ASSESSMENTS}
          />
          <Tab
            className={classes.tab}
            value={routes.ACTIONS}
            label='Actions'
            component={Link}
            to={routes.ACTIONS}
          />
        </Tabs>
        <div className={classes.spacer} />
        <Tabs value={tab2}>
          <Tab
            classes={{ root: classes.tab2Root, wrapper: hasInvitations ? classes.tab2Wrapper : undefined }}
            value={routes.PROFILE}
            onClick={handeUserClick}
            icon={<AccountCircle />}
            ref={userTabRef}
          ></Tab>
        </Tabs>
        {getUserMenu()}
      </Box>
    </AppBar>
  )
}

export default withAuth(Header)
