const routes = {
  MAIN: '/',
  ACTIONS: '/actions',
  RISK_ASSESSMENTS: '/riskassessments',
  ORGANIZATION: '/organization',
  INVITATIONS: '/invitations',
  PROFILE: '/profile',
  PROFILE_2FA: '/profile/2fa',
  LOGIN: '/login',
  SIGNUP: '/signup',
  VERIFY_ACCOUNT: '/verifyAccount',
  COOKIE_POLICY: '/policies/cookies',
  TERMS: '/policies/terms',
  PRIVACY_POLICY: '/policies/privacy',
  SECURITY_POLICY: '/policies/security',
}

export default routes
