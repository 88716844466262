import { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import withAuth from '../components/auth'
import ActionTable from '../components/ActionTable'
import { ActionsToolbar } from '../components/SessionsToolbar/ActionsToolbar'

const ActionsPage = ({ auth: { user } }) => {
  const [filter, setFilter] = useState({
    statuses: ['not_started', 'acknowledged', 'in_progress'],
    owner: true,
  })

  useEffect(() => {
    document.title = 'Actions'
  })

  const handleSearchFilterChanged = (value) => {
    setFilter({ ...filter, search: value })
  }

  const handleStatusFilterChanged = (value) => {
    setFilter({ ...filter, statuses: value })
  }

  const handleOwnerFilterChanged = (value) => {
    setFilter({ ...filter, owner: value })
  }

  return (
    <Container>
      <Box display='flex' flexDirection='column' paddingTop={1}>
        <ActionsToolbar
          filter={filter}
          user={user}
          onSearchFilterChange={handleSearchFilterChanged}
          onStatusFilterChange={handleStatusFilterChanged}
          onOwnerFilterChange={handleOwnerFilterChanged}
        />
        <ActionTable user={user} filter={filter}></ActionTable>
      </Box>
    </Container>
  )
}

export default withAuth(ActionsPage)
