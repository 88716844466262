import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Skeleton from '@material-ui/lab/Skeleton'

import Lock from '@material-ui/icons/Lock'
import MoreIcon from '@material-ui/icons/MoreHoriz'

import routes from '../../routes'
import { formatDate } from '../../util'

const useStyles = makeStyles((theme) => ({
  table: {
    cursor: 'pointer',
  },
  lockCell: {
    lineHeight: 'unset',
  },
  rowLockCell: {
    lineHeight: 'unset',
    paddingRight: theme.spacing(3),
  },
  lockIcon: {
    color: theme.palette.text.disabled,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  ownerChip: {
    color: theme.palette.primary.dark,
  },
  updatedCell: {
    color: theme.palette.primary.dark,
  },
}))

const _SessionTable = ({
  user,
  sessions,
  loading,
  showClosed,
  onSessionMenuClick,
}) => {
  const navigate = useNavigate()
  const handleRowClick = (session) => {
    navigate(`${routes.RISK_ASSESSMENTS}/${session._id}`)
  }

  const classes = useStyles()

  const [sortDescending, setSortDescending] = useState(true)
  const [sortColumn, setSortColumn] = useState('updated')

  const headerData = [
    {
      id: 'status',
      width: 2.5,
      className: classes.lockCell,
      children: (
        <Tooltip title={'Status'}>
          <Lock size='small' className={classes.lockIcon} />
        </Tooltip>
      ),
    },
    {
      id: 'name',
      padding: showClosed ? 'none' : 'normal',
      children: 'Name',
    },
    {
      id: 'owner',
      width: '20%',
      children: 'Owner',
    },
    {
      id: 'org',
      width: '15%',
      children: 'Organization',
    },
    {
      id: 'updated',
      width: '10%',
      children: 'Updated',
    },
    {
      id: 'actions',
      width: 2.5,
    },
  ]

  if (!showClosed) {
    headerData.splice(0, 1)
  }

  const rowProps = [
    {
      id: 'status',
      className: classes.rowLockCell,
      width: 2.5,
      padding: 'none',
      align: 'center',
    },
    {
      id: 'name',
      padding: showClosed ? 'none' : 'normal',
      align: 'left',
    },
    {
      id: 'owner',
      width: '20%',
      align: 'left',
    },
    {
      id: 'org',
      width: '15%',
      align: 'left',
    },
    {
      id: 'updated',
      className: classes.updatedCell,
      width: '10%',
      align: 'left',
    },
    {
      id: 'actions',
      padding: 'none',
      width: 2.5,
    },
  ]

  const handleSortLabelClick = (id) => {
    if (id === sortColumn) {
      setSortDescending(!sortDescending)
    } else {
      setSortDescending(false)
      setSortColumn(id)
    }
  }

  const handleSessionMenuClick = (evt, session) => {
    evt.stopPropagation()
    onSessionMenuClick && onSessionMenuClick(evt, session)
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy])
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (sortDescending, orderBy) => {
    return sortDescending
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getSortedSessions = () => {
    const transformed = sessions.map((s) => ({
      session: s,
      status: s.status,
      name: s.name,
      owner: (s.owner && s.owner.name) || '',
      org: s.organizationName,
      updated: new Date(s.updatedAt),
    }))
    return stableSort(transformed, getComparator(sortDescending, sortColumn))
  }

  const sortedSessions = getSortedSessions()

  const createTableRows = () => {
    if (loading) {
      return [1, 2, 3].map((i) => {
        return (
          <TableRow key={`skeleton${i}`}>
            {showClosed && <TableCell {...rowProps[0]} />}
            <TableCell {...rowProps[1]}>
              <Skeleton variant='text' width={300} height={24} />
            </TableCell>
            <TableCell {...rowProps[2]}>
              <Skeleton variant='text' width={60} height={24} />
            </TableCell>
            <TableCell {...rowProps[3]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
            <TableCell {...rowProps[4]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
            <TableCell {...rowProps[5]}>
              <IconButton size='small' disabled>
                <MoreIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        )
      })
    } else {
      return sortedSessions && sortedSessions.length > 0 ? (
        sortedSessions.map(({ session }) => (
          <TableRow
            key={session._id}
            hover
            onClick={() => handleRowClick(session)}
          >
            {showClosed && (
              <TableCell {...rowProps[0]}>
                {' '}
                {session.status === 'closed' && (
                  <Tooltip title={'Closed'}>
                    <Lock size='small' className={classes.lockIcon} />
                  </Tooltip>
                )}
              </TableCell>
            )}
            <TableCell {...rowProps[1]}>{session.name}</TableCell>
            <TableCell {...rowProps[2]}>
              {session.owner?._id === user._id ? (
                <Chip size='small' label='Me' variant='outlined' />
              ) : (
                session.owner?.name
              )}
            </TableCell>
            <TableCell {...rowProps[3]}>
              {session.organizationName}
            </TableCell>
            <TableCell {...rowProps[4]}>
              {formatDate(session.updatedAt)}
            </TableCell>
            <TableCell {...rowProps[5]}>
              <IconButton
                onClick={(evt) => handleSessionMenuClick(evt, session)}
              >
                <MoreIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell style={{ fontSize: 18 }} colSpan={4} align='center'>
            No matching risk assessments
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((h) => {
              return (
                <TableCell key={h.id} {...h}>
                  <TableSortLabel
                    active={sortColumn === h.id}
                    direction={
                      h.id === sortColumn
                        ? sortDescending
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                    }
                    onClick={() => {
                      handleSortLabelClick(h.id)
                    }}
                    children={h.children}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>{createTableRows()}</TableBody>
      </Table>
    </TableContainer>
  )
}

const SessionTable = _SessionTable
export { SessionTable }
