import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography'

import routes from '../routes'
import BasicHeader from '../components/BasicHeader'
import TwoFactorCodeValidation from '../components/TwoFactorCodeValidation'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkmark: {
      width: '8rem',
      height: '8rem',
    },
  }
})

const TwoFactorValidationPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const [codeValidated, setCodeValidated] = useState()

  useEffect(() => {
    document.title = 'Verify code'
  })

  useEffect(() => {
    let timer
    if (codeValidated) {
      timer = setTimeout(
        () => navigate(location?.state?.referrer || routes.RISK_ASSESSMENTS),
        1000
      )
    }
    return () => timer && clearTimeout(timer)
  }, [codeValidated, navigate, location])

  const handleVerified = () => {
    setCodeValidated(true)
  }

  return (
    <div className={classes.root}>
      <BasicHeader />
      <div className={classes.content}>
        <Box mb={4} maxWidth={{ xs: 200, sm: '100%' }}>
          <Typography variant='body1'>
            {codeValidated
              ? 'Verification code successfully validated!'
              : 'Enter the verification code from your authenticator'}
          </Typography>
        </Box>
        {codeValidated ? (
          <CheckCircleOutlineIcon
            color='secondary'
            className={classes.checkmark}
          />
        ) : (
          <TwoFactorCodeValidation
            disabled={codeValidated}
            onVerified={handleVerified}
          />
        )}
      </div>
    </div>
  )
}

export default TwoFactorValidationPage
