import { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

import { SearchInput } from './SearchInput'
import { getActionStatusLabel } from '../../utils/actions'

const useStyles = makeStyles((theme) => {
  return {
    search: {
      marginRight: theme.spacing(2),
    },
    checkbox: {
      fontSize: theme.typography.body2.fontSize,
    },
    checkboxRoot: {
      paddingRight: theme.spacing(0.5),
    },
    createButton: {
      marginLeft: 'auto',
    },
    statusButton: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.body1.fontWeight,
      textTransform: 'unset',
    },
    menuItem: {
      padding: `0 0 0 ${theme.spacing(1)}px`,
    },
  }
})

const ActionsToolbar = ({
  filter,
  user,
  onSearchFilterChange,
  onStatusFilterChange,
  onOwnerFilterChange,
}) => {
  const classes = useStyles()
  const statusFilterRef = useRef(null)
  const [showStatusFilterMenu, setShowStatusFilterMenu] = useState(false)

  const toggleShowStatusFilterMenu = (evt) => {
    setShowStatusFilterMenu(!showStatusFilterMenu)
  }

  const handleCloseStatusFilterMenu = () => {
    setShowStatusFilterMenu(false)
  }

  const handleOwnerClick = () => {
    onOwnerFilterChange?.(!filter.owner)
  }

  const toggleStatusFilter = (status) => {
    let result
    if (filter?.statuses.includes(status)) {
      result = filter.statuses.filter((s) => s !== status)
    } else {
      result = [...filter.statuses, status]
    }
    onStatusFilterChange && onStatusFilterChange(result)
  }

  return (
    <Toolbar className={classes.toolbar} disableGutters>
      <SearchInput
        classes={{ root: classes.search }}
        onChange={onSearchFilterChange}
      />
      <Tooltip title={'Only show risk assessments owned by me'}>
        <FormControlLabel
          classes={{ label: classes.checkbox }}
          control={
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              size='small'
              checked={filter.owner}
              onChange={handleOwnerClick}
              color='primary'
            />
          }
          label='Owned by me'
        />
      </Tooltip>
      <Tooltip title={'Status filter'}>
        <Button
          ref={statusFilterRef}
          className={classes.statusButton}
          variant='outlined'
          size='small'
          color='primary'
          endIcon={<ArrowDropDown />}
          onClick={toggleShowStatusFilterMenu}
        >
          Status
        </Button>
      </Tooltip>
      <Menu
        open={showStatusFilterMenu}
        anchorEl={statusFilterRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseStatusFilterMenu}
      >
        <MenuItem classes={{ root: classes.menuItem }}>
          <FormControlLabel
            classes={{ label: classes.checkbox }}
            control={
              <Checkbox
                value='not_started'
                size='small'
                checked={filter?.statuses?.includes('not_started')}
                onChange={() => toggleStatusFilter('not_started')}
                color='primary'
              />
            }
            label={getActionStatusLabel('not_started')}
          />
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          <FormControlLabel
            classes={{ label: classes.checkbox }}
            control={
              <Checkbox
                value='acknowledged'
                size='small'
                checked={filter?.statuses?.includes('acknowledged')}
                onChange={() => toggleStatusFilter('acknowledged')}
                color='primary'
              />
            }
            label={getActionStatusLabel('acknowledged')}
          />
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          <FormControlLabel
            classes={{ label: classes.checkbox }}
            control={
              <Checkbox
                value='in_progress'
                size='small'
                checked={filter?.statuses?.includes('in_progress')}
                onChange={() => toggleStatusFilter('in_progress')}
                color='primary'
              />
            }
            label={getActionStatusLabel('in_progress')}
          />
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          <FormControlLabel
            classes={{ label: classes.checkbox }}
            control={
              <Checkbox
                value='completed'
                size='small'
                checked={filter?.statuses?.includes('completed')}
                onChange={() => toggleStatusFilter('completed')}
                color='primary'
              />
            }
            label={getActionStatusLabel('completed')}
          />
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          <FormControlLabel
            classes={{ label: classes.checkbox }}
            control={
              <Checkbox
                value='rejected'
                size='small'
                checked={filter?.statuses?.includes('rejected')}
                onChange={() => toggleStatusFilter('rejected')}
                color='primary'
              />
            }
            label={getActionStatusLabel('rejected')}
          />
        </MenuItem>
      </Menu>
    </Toolbar>
  )
}

export { ActionsToolbar }
