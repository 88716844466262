import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ReactComponent as Logo } from '../svg/logo.svg'
import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(10),
    },
    logo: {
      width: 150,
      height: 'auto',
      cursor: 'pointer',
    },
    link: {
      margin: theme.spacing(0, 1),
      color: 'white',
    },
    dark: {
      color: 'black',
    },
    loginButton: {
      marginLeft: theme.spacing(1),
    },
    white: {
      color: 'white',
    },
    drawer: {
      minWidth: '10rem',
    },
  }
})

const links = [
  {
    t: 'FEATURES',
    l: '/#index-features',
  },
  {
    t: 'ABOUT US',
    l: '/about',
  },
  {
    t: 'PRICING',
    l: '/#index-pricing',
  },
]

const PageHeader = ({ dark }) => {
  const classes = useStyles()

  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const handleContactClicked = (e) => {
    window.location.href = `mailto:info\u0040riskace.com`
  }

  const handleContactClickedDrawer = () => {
    setShowDrawer(false)
    window.location.href = `mailto:info\u0040riskace.com`
  }

  const handleLinkClicked = (href) => {
    setShowDrawer(false)
    window.location.href = href
  }

  const linkClassName = dark ? `${classes.link} ${classes.dark}` : classes.link
  return (
    <Container maxWidth={'lg'} classes={{ maxWidthLg: classes.header }}>
      <a href={routes.MAIN}>
        <Logo className={classes.logo} />
      </a>
      <Box
        display='flex'
        flex={1}
        justifyContent='flex-end'
        alignItems='center'
      >
        <Hidden smUp>
          {!showDrawer && (
            <IconButton edge='end' onClick={toggleDrawer}>
              <MenuIcon className={dark ? classes.dark : classes.white} />
            </IconButton>
          )}
          <Drawer
            open={showDrawer}
            anchor='right'
            classes={{ paper: classes.drawer }}
            onClose={toggleDrawer}
          >
            <Box>
              <List>
                {links.map((l) => (
                  <ListItem
                    button
                    key={`m-${l.t}`}
                    onClick={() => handleLinkClicked(l.l)}
                  >
                    <ListItemText primary={l.t} />
                  </ListItem>
                ))}
                <ListItem button onClick={handleContactClickedDrawer}>
                  <ListItemText primary={'CONTACT'} />
                </ListItem>
              </List>
              <Button
                variant='contained'
                color='secondary'
                component={'a'}
                href='/login'
                className={classes.loginButton}
              >
                LOG IN
              </Button>
            </Box>
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          {links.map((l) => (
            <Button
              key={l.t}
              className={linkClassName}
              component={'a'}
              href={l.l}
            >
              {l.t}
            </Button>
          ))}
          <Button className={linkClassName} onClick={handleContactClicked}>
            CONTACT
          </Button>
          <Button
            variant='contained'
            color='secondary'
            component={'a'}
            href='/login'
            className={classes.loginButton}
          >
            LOG IN
          </Button>
        </Hidden>
      </Box>
    </Container>
  )
}

export default PageHeader
