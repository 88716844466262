import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
    listIndicator: {
      height: '0.75rem',
      width: '0.75rem',
      minHeight: '0.75rem',
      minWidth: '0.75rem',
      borderRadius: '2px',
      boxShadow: '1px 1px 2px darkgrey',
      opacity: '0.8',
    },
    headerMain: {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
    title: {
      flex: 1,
      marginLeft: theme.spacing(1),
    },
  }
})

const ActionRisk = (props) => {
  const { risk } = props
  const classes = useStyles()
  let className = classes.root
  if (props.className) {
    className += ` ${props.className}`
  }
  return (
    <Paper className={className}>
      <div className={classes.headerMain}>
        <div
          className={classes.listIndicator}
          style={{
            backgroundColor:
              risk.position && risk.color ? risk.color : 'transparent',
          }}
        />
        <Typography
          variant='body2'
          className={classes.title}
        >{`${risk.identifier}. ${risk.title}`}</Typography>
      </div>
    </Paper>
  )
}

export default ActionRisk
