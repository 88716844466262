import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import ProgressButton from '../components/ProgressButton'
import PasswordInput from '../components/PasswordInput'

import { SET_2FA } from '../graphql'

const useStyles = makeStyles((theme) => {
  return {
    button: {
      color: theme.palette.primary.dark,
    },
    pwInput: {
      width: '100%',
    },
    checkmark: {
      width: '4rem',
      height: '4rem',
    },
  }
})

const DisableTwoFactorAuthDialog = ({ open, username, onClose }) => {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState()
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (open) {
      setPassword('')
      setShowError()
      setCompleted(false)
    }
  }, [open])

  const [setTwoFactorAuth, { loading, data }] = useMutation(SET_2FA, {
    onCompleted: (data) => {
      setShowError(
        data.setTwoFactorAuth === 'invalidPassword' ? true : undefined
      )
      setCompleted(true)
    },
  })

  const canSubmit = () => {
    return !loading && username !== '' && password !== ''
  }

  const handleChange = (evt) => {
    setShowError()
    setPassword(evt.target.value)
  }

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter' && canSubmit()) {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setTwoFactorAuth({
      variables: {
        input: { enabled: false, password },
      },
    })
  }

  const handleClose = (evt) => {
    !loading && onClose && onClose()
  }

  const getContentText = () => {
    return completed && data && data.setTwoFactorAuth === 'ok'
      ? 'Two-factor authentication sucessfully disabled'
      : 'Enter your password and submit to disable two-factor authentication.'
  }

  const getContent = () => {
    const status = completed && data ? data.setTwoFactorAuth : 'default'
    switch (status) {
      case 'ok':
        return (
          <Box display='flex' justifyContent='center'>
            <CheckCircleOutlineIcon
              color='secondary'
              className={classes.checkmark}
            />
          </Box>
        )
      case 'error':
        return (
          <Alert className={classes.alert} severity='error'>
            There was a problem disabling two-factor authentication
          </Alert>
        )
      default:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <PasswordInput
                className={classes.pwInput}
                disabled={loading}
                error={showError}
                password={password}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box mt={{ xs: 0, sm: 1 }}>
                <ProgressButton
                  variant='contained'
                  color='primary'
                  loading={loading}
                  disabled={!Boolean(canSubmit())}
                  onClick={handleSubmit}
                >
                  Submit
                </ProgressButton>
              </Box>
            </Grid>
          </Grid>
        )
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Disable two-factor authentication</DialogTitle>
      <DialogContent>
        <DialogContentText>{getContentText()}</DialogContentText>
        {getContent()}
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisableTwoFactorAuthDialog
