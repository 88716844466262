import * as React from 'react'
import { useState, useRef } from 'react'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import AttachFile from '@material-ui/icons/AttachFile'
import Lock from '@material-ui/icons/Lock'

import ProgressButton from './ProgressButton'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      boxSizing: 'border-box',
    },
    content: {
      padding: theme.spacing(4, 6),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(2),
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '12rem',
      justifyContent: 'flex-start',
    },
    icon: {
      color: 'grey',
      paddingRight: theme.spacing(2),
    },
    input: {
      width: '100%',
      maxWidth: '20rem',
      minHeight: '4.25rem',
    },
    button: {
      marginTop: '2rem',
      alignSelf: 'flex-end',
    },
    row: {
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',
    },
    keyFile: {
      flex: 1,
      textOverflow: 'ellipsis',
      maxWidth: '10rem',
      overflow: 'hidden',
    },
    browseButton: {
      marginLeft: theme.spacing(1),
    },
  }
})

const ResetPasswordForm = ({ disabled, submitting, onSubmit }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [keyFile, setKeyFile] = useState('')

  const passwordInput = React.useRef(null)
  const confirmPasswordInput = React.useRef(null)

  const keyInputRef = useRef()

  const handleClickBrowse = (e) => {
    keyInputRef.current.click()
  }

  const handleKeySelected = (e) => {
    e.stopPropagation()
    if (e.target.files && e.target.files.length > 0) {
      setKeyFile(e.target.files[0])
    }
  }

  const handleChangePassword = (event) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (confirmPassword) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match')
      } else if (confirmPasswordError) {
        setConfirmPasswordError('')
      }
    }
    setPasswordError(error)
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && confirmPasswordInput.current) {
      confirmPasswordInput.current.focus()
    }
  }

  const handleChangeConfirmPassword = (event) => {
    const newPassword = event.target.value
    setConfirmPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (newPassword) {
      if (newPassword !== password) {
        error = 'Passwords do not match'
      }
    }
    setConfirmPasswordError(error)
  }

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword)
  }

  const isValidPassword = (password) => {
    return password && password !== 'kuk'
  }

  const classes = useStyles()

  const canSubmit = () => {
    return (
      !disabled &&
      isValidPassword(password) &&
      isValidPassword(confirmPassword) &&
      password === confirmPassword &&
      keyFile
    )
  }

  const handleSubmit = (event) => {
    onSubmit && onSubmit(password, keyFile)
  }

  return (
    <Paper className={`${classes.root}`}>
      <div className={classes.content}>
        <form className={classes.form}>
          <div className={classes.row}>
            <Lock className={classes.icon} />
            <FormControl
              required
              error={Boolean(passwordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='password'>New password</InputLabel>
              <Input
                id='password'
                type={showPassword ? 'text' : 'password'}
                value={password}
                inputRef={passwordInput}
                onChange={handleChangePassword}
                onKeyPress={handlePasswordKeyPress}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordError && (
                <FormHelperText>{passwordError}</FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={classes.row}>
            <Lock className={classes.icon} />
            <FormControl
              required
              error={Boolean(confirmPasswordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='confirmpassword'>
                Confirm Password
              </InputLabel>
              <Input
                id='confirmpassword'
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                inputRef={confirmPasswordInput}
                onChange={handleChangeConfirmPassword}
              />
              {confirmPasswordError && (
                <FormHelperText>{confirmPasswordError}</FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={classes.row}>
            <AttachFile className={classes.icon} />
            <div className={classes.keyFile}>
              {keyFile ? keyFile.name : 'Select key file'}
            </div>
            <Button
              className={classes.browseButton}
              variant='contained'
              color='primary'
              onClick={handleClickBrowse}
              type='button'
            >
              BROWSE...
            </Button>
            <input
              ref={keyInputRef}
              type='file'
              name='key'
              style={{ display: 'none' }}
              onChange={handleKeySelected}
            />
          </div>
          <FormControl>
            <ProgressButton
              disabled={!canSubmit()}
              loading={submitting}
              classes={{ root: classes.button }}
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              type='button'
            >
              SUBMIT
            </ProgressButton>
          </FormControl>
        </form>
      </div>
    </Paper>
  )
}

export default ResetPasswordForm
