import { useState } from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { ORGANIZATION_MEMBERS } from '../graphql'

import AlertSnackbar from '../components/AlertSnackbar'
import InviteUserDialog from './InviteUserDialog'
import OrganizationMember from './OrganizationMember'
import ConfirmationDialog from './ConfirmationDialog'
import ResetMemberPasswordDialog from './ResetMemberPasswordDialog'


const ADD_MEMBER = gql`
  mutation ($organizationId: ID!, $userId: ID!, $role: OrganizationMemberRole!) {
    addMember: addMember(organizationId: $organizationId, userId: $userId, role: $role) {
      createdAt 
      createdBy {
        _id
        name
        email
      }
      user {
        _id
        name
        email
      }
      role
      status
    }
  }
`

const REMOVE_MEMBER = gql`
  mutation ($organizationId: ID!, $userId: ID!) {
    removeMember: removeMember(organizationId: $organizationId, userId: $userId)
  }
`

const RESTORE_MEMBER = gql`
  mutation ($organizationId: ID!, $userId: ID!) {
    restoreMember: restoreMember(organizationId: $organizationId, userId: $userId)
  }
`

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  members: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}))

const OrganizationMembers = ({ user, organization }) => {
  const classes = useStyles()

  const organizationId = organization?._id
  const ownerId = organization?.ownerId
  const isOwner = user?._id === ownerId ?? false

  const { data, refetch: fetchMembers } = useQuery(ORGANIZATION_MEMBERS, {
    variables: { id: organizationId },
    skip: !organizationId,
    fetchPolicy: 'network-only',
  })

  const isAdmin = isOwner || data?.organizationMembers?.find((m) => m.role === 'admin' && m.user._id === user?._id)

  const [addMember] = useMutation(ADD_MEMBER, {
    onCompleted: (data) => {
      setSnackbarOptions({
        open: true,
        text: `User ${data?.inviteUser?.user?.email ?? ''} added`,
        severity: 'success',
      })
      fetchMembers()
    },
    onError: (error) => {
      setSnackbarOptions({
        open: true,
        text: 'There was a problem adding the user',
        severity: 'error',
      })
    },
  })

  const [removeMember] = useMutation(REMOVE_MEMBER, {
    onCompleted: (data) => {
      if (data?.removeMember) {
        setSnackbarOptions({
          open: true,
          text: 'User removed from organization',
          severity: 'success',
        })
        fetchMembers()
        return
      }
      setSnackbarOptions({
        open: true,
        text: 'There was a problem removing the user from the organization',
        severity: 'error',
      })
    },
    onError: (error) => {
      setSnackbarOptions({
        open: true,
        text: 'There was a problem removing the user from the organization',
        severity: 'error',
      })
    },
  })

  const [restoreMember] = useMutation(RESTORE_MEMBER, {
    onCompleted: (data) => {
      if (data?.restoreMember) {
        setSnackbarOptions({
          open: true,
          text: 'User restored',
          severity: 'success',
        })
        fetchMembers()
        return
      }
      setSnackbarOptions({
        open: true,
        text: 'There was a problem restoring the user',
        severity: 'error',
      })
    },
    onError: (error) => {
      setSnackbarOptions({
        open: true,
        text: 'There was a problem restoring the user',
        severity: 'error',
      })
    },
  })

  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [resetPasswordDialogData, setResetPasswordDialogData] = useState({ open: false })
  const [userMenuOptions, setUserMenuOptions] = useState({ open: false })
  const [selectedMembership, setSelectedMembership] = useState()
  const [snackbarOptions, setSnackbarOptions] = useState({ open: false })
  const [confirmDialogData, setConfirmDialogData] = useState({ open: false })

  const handleAddUserClick = () => {
    setShowAddUserDialog(!showAddUserDialog)
  }

  const handleCloseInviteUserDialog = (user, role) => {
    setShowAddUserDialog(!showAddUserDialog)
    if (user && role) {
      addMember({
        variables: { organizationId, userId: user._id, role },
      })
    }
  }

  const handleCloseResetPasswordDialog = (result) => {
    setResetPasswordDialogData({
      ...resetPasswordDialogData,
      open: false
    })
    if (result?.status === 'error') {
      setSnackbarOptions({
        open: true,
        text: 'There was a problem resetting the password',
        severity: 'error',
      })
    }
  }

  const handleMoreClick = (evt, membership) => {
    let label
    switch (membership?.status) {
      case 'active':
        label = 'Archive user...'
        break
      case 'pending':
      case 'declined':
        label = 'Revoke invitation...'
        break
      default:
        label = 'Restore user...'
        break
    }
    setUserMenuOptions({ open: true, anchor: evt.currentTarget, label })
    setSelectedMembership(membership)
  }

  const handleCloseUserMenu = () => {
    setUserMenuOptions({ ...userMenuOptions, open: false })
    setSelectedMembership()
  }

  const handleRemoveUser = () => {
    const user = selectedMembership?.user
    const status = selectedMembership?.status
    setUserMenuOptions({ ...userMenuOptions, open: false })
    setSelectedMembership()
    setConfirmDialogData({
      title: status === 'active' ? 'Archive user' : 'Revoke invitation',
      content: (
        <>
          Are you sure you want to remove the user <strong>{user?.email}</strong>?
        </>
      ),
      btn: 'CONFIRM',
      open: true,
      data: { user, action: 'remove' },
    })
  }

  const handleRestoreUser = () => {
    const user = selectedMembership?.user
    setUserMenuOptions({ ...userMenuOptions, open: false })
    setSelectedMembership()
    setConfirmDialogData({
      title: 'Restore user',
      content: (
        <>
          Restore user <strong>{user?.email}</strong>?
        </>
      ),
      btn: 'CONFIRM',
      open: true,
      data: { user, action: 'restore' },
    })
  }

  const handleResetPassword = () => {
    const user = selectedMembership?.user
    setUserMenuOptions({ ...userMenuOptions, open: false })
    setSelectedMembership()
    setResetPasswordDialogData({ user, open: true })
  }

  const handleSnackbarClose = () => {
    setSnackbarOptions({ ...snackbarOptions, open: false })
  }

  const handleCloseConfirmationDialog = () => {
    setConfirmDialogData({ ...confirmDialogData, data: undefined, open: false })
  }

  const handleConfirm = (data) => {
    const userId = data?.user?._id
    const action = data.action
    setConfirmDialogData({ ...confirmDialogData, data: undefined, open: false })
    if (action === 'remove') {
      removeMember({
        variables: { organizationId, userId },
      })
    } else {
      restoreMember({
        variables: { organizationId, userId },
      })
    }
  }

  const members = data?.organizationMembers ?? []

  const getUserMenu = () => <Menu
    open={userMenuOptions?.open ?? false}
    anchorEl={userMenuOptions?.anchor}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    onClose={handleCloseUserMenu}
  >
    {selectedMembership?.status !== 'archived' && <MenuItem disabled={selectedMembership?.user?._id === ownerId || !isAdmin} onClick={handleRemoveUser}>{userMenuOptions?.label}</MenuItem>}
    {selectedMembership?.status === 'archived' && <MenuItem disabled={!isAdmin} onClick={handleRestoreUser}>{userMenuOptions?.label}</MenuItem>}
    {isAdmin && <MenuItem disabled={selectedMembership?.status !== 'active' || selectedMembership?.role === 'owner'} onClick={handleResetPassword}>Reset password...</MenuItem>}
  </Menu>


  const getMembersContent = () => {
    if (members.length === 0) {
      return undefined
    }
    return (
      <>
        <Typography variant='body1'>Members</Typography>
        <Divider className={classes.divider}></Divider>
        {members?.map((m) => (
          <OrganizationMember
            showMenu={isAdmin}
            key={`member${m?.user?._id}`}
            member={m}
            owner={m?.user?._id === ownerId}
            onMenuClick={(evt) => handleMoreClick(evt, m)}
          />
        ))}
        <ConfirmationDialog
          open={confirmDialogData?.open}
          title={confirmDialogData?.title}
          content={confirmDialogData?.content}
          confirmButtonText={confirmDialogData?.btn}
          data={confirmDialogData?.data}
          handleClose={handleCloseConfirmationDialog}
          handleConfirm={handleConfirm}
        />
      </>
    )
  }

  return (
    <Paper className={classes.paper}>
      {isAdmin &&
        <Box mt={2} display='flex' justifyContent='end'>
          <Button
            disabled={!isAdmin}
            variant='contained'
            color='secondary'
            onClick={handleAddUserClick}
          >
            Add user
          </Button>
        </Box>}
      {getMembersContent()}
      {getUserMenu()}
      <InviteUserDialog
        open={showAddUserDialog}
        onClose={handleCloseInviteUserDialog}
        owner={isOwner}
      />
      <ResetMemberPasswordDialog
        open={resetPasswordDialogData?.open}
        onClose={handleCloseResetPasswordDialog}
        user={resetPasswordDialogData?.user}
        organizationId={organization._id}
      />
      <AlertSnackbar
        open={snackbarOptions?.open ?? false}
        severity={snackbarOptions?.severity}
        text={snackbarOptions?.text}
        onClose={handleSnackbarClose}
      ></AlertSnackbar>
    </Paper>
  )
}

export default OrganizationMembers
