import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import PageHeader from '../components/PageHeader'
import Footer from '../components/Footer'

const useStyles = makeStyles((theme) => {
  return {
    main: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      flex: 1,
    },
  }
})

const CONTENT = {
  'project-risks': {
    heading: 'Project Risks',
    sections: [
      'Running a project? Need to manage the risks? A good project risk management plan isn’t reactive, it’s proactive. With RiskAce you can ensure your risks stay under control and do not turn into issues that impact your delivery.',
      'With collaborative risk assessments you can gather input from all project participants and stakeholders, regardless of them being in the office or on the road, categorize the risks and create action plans. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your project.',
      'Now you can also involve participants from outside your company to collaborate on RiskAce without a need for a license, so all contractors, suppliers and other various counterparties can help you get the full picture of the risks in your project.',
      'RiskAce is available for a free trial, register for free and experience the power of true distributed risk management.',
    ]
  },
  'gdpr': {
    heading: 'GDPR',
    sections: [
      'Spending a lot of time with Data Protection Impact Assessments (DPIA) or Data Transfer Impact Assessments (DTIA)? Would it not be wonderful to have a digital risk assessment tool to help gather input from stakeholders and speed up documentation of the data protection risks imposed on your privacy data? Following the GDPR regulations have never been easier.',
      'With RiskAce you get collaborative risk assessments where you gather input from all stakeholders, regardless of them being in the office or on the road. With drag and drop you categorize the risks, create action plans and reports. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your organization. RiskAce also support your templates and risk libraries which easily are imported to the tool.',
      'RiskAce is available for a free trial, register and experience the power of true distributed risk management.',
    ]
  },
  'operational-risks': {
    heading: 'Operational Risks',
    sections: [
      'When an operational risk event does occur, it can have profound, long-lasting spillover effects. Risk management is not the responsibility of a single department, it is the responsibility of everyone, from the chief executive down. Operational risk management is a process involving risk analysis, strategy and risk control to identify and reduce risks that may occur in daily business operations. The objective of this form of management is to control and minimize operational risks which are losses due to failures in processes, systems or by employees in the business.',
      'With RiskAce you get collaborative risk assessments where you gather input from all stakeholders, regardless of them being in the office or on the road. With drag and drop you categorize the risks, create action plans and reports. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your organization.',
      'RiskAce is available for a free trial, register for free and experience the power of true distributed risk management.',
    ]
  },
  'information-security': {
    heading: 'Information Security',
    sections: [
      'Every change in the organization has inherent security risks. And even worse, also events around you affect your risk situation. But you are already on top of that, meticulously following up cyber risks, performing risk assessments for all new changes to identify possible vulnerabilities such as XSS of SQL injection, general code defects or risk for stabilty and scalability issues.',
      'All that risk management takes up your time - gathering all the stakeholders, leading the assessments and generating reports. Why not try RiskAce?',
      'With RiskAce you get collaborative risk assessments where you gather input from all stakeholders, regardless of them being in the office or on the road. With drag and drop you categorize the risks, create action plans and reports. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your organization.',
      'RiskAce will reduce the burden and help your team to identify security risks before they materialize to harm your organization. And leave time to do more than creatign risk reports.',
      'RiskAce is available for a free trial, register for free and experience the power of true distributed risk management.',
    ]
  },
  'climate-risks': {
    heading: 'Climate Risks',
    sections: [
      'Climate challenges has changed the environmental risk landscape for organizations. What changes affects your organization and how do you prioritize your environmental investments?',
      'With RiskAce you get collaborative risk assessments where you gather input from all stakeholders, regardless of them being in the office or on the road. With drag and drop you categorize the risks, create action plans and reports. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your organization.',
      'RiskAce will help your team to identify the risks and assess the impact of your environmental risks. This way, your actions can be tailored to meet the greatest risks and help your organization adapt efficiently.',
      'RiskAce is available for a free trial, register for free and experience the power of true distributed risk management.',
    ]
  },
  'outsourcing': {
    heading: 'Outsourcing',
    sections: [
      'The biggest risk of outsourcing is the lack of control. If an organization uses outsourcing, this does not mean that the responsibility for risk management falls with them. On the contrary, you yourself must carefully map out what information is held by third parties and make a clear set of requirements. Plan to systematically follow up third-party risks as part of the other risk and internal control work.',
      'With RiskAce you get collaborative risk assessments where you gather input from all stakeholders, regardless of them being in the office or on the road. With drag and drop you categorize the risks, create action plans and reports. All risks and actions can be continuously updated and followed up upon, ensuring you are continuously on top of the risks affecting your organization.',
      'RiskAce is available for a free trial, register for free and experience the power of true distributed risk management.',
    ]
  },
}

const ArticlePage = ({ content }) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <PageHeader dark />
      <Container maxWidth={'lg'} className={classes.container}>
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          alignItems='flex-start'
          mt={4}
        >
          <Typography
            variant='h2'
            paragraph
          >
            {CONTENT[content]?.heading}
          </Typography>
          {CONTENT[content]?.sections?.map((s) =>
            <Typography
              variant='body1'
              paragraph
            >
              {s}
            </Typography>
          )}
        </Box>
      </Container>
      <Footer />
    </div>
  )
}

export default ArticlePage
