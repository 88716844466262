import { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'

import ButtonBase from '@material-ui/core/ButtonBase'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: ({ dragging, highlight }) =>
        dragging ? 'white' : highlight ? '#b2dfdb' : '#b2dfdb69',
      borderRadius: '2px',
      border: '2px dashed',
      opacity: ({ dragging }) => (dragging ? '20%' : '40%'),
      cursor: 'pointer',
      padding: '1rem 0 1rem 0',
    },
    image: {
      opacity: '70%',
      width: '2rem',
      height: '2rem',
    },
  }
})

const FileUpload = ({ label, onFilesSelected }) => {
  const [dragging, setDragging] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const classes = useStyles({ dragging, highlight })
  const fileInputRef = useRef()

  const handleClick = (e) => {
    fileInputRef.current.click()
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!dragging) {
      setDragging(true)
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!dragging) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (dragging) {
      setDragging(false)
    }
  }

  const handleDragEnd = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (dragging) {
      setDragging(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (dragging) {
      setDragging(false)
    }
    onFilesSelected?.(e?.dataTransfer?.files)
  }

  const handleMouseEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!dragging) {
      setHighlight(true)
    }
  }

  const handleMouseLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!dragging) {
      setHighlight(false)
    }
  }

  const handleFileSelected = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onFilesSelected?.(e?.target?.files)
  }

  return (
    <ButtonBase
      className={classes.container}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <CloudUpload className={classes.image} />
      <Typography>{label || 'Select or drop file'}</Typography>
      <input
        ref={fileInputRef}
        type='file'
        style={{ display: 'none' }}
        onChange={handleFileSelected}
      />
    </ButtonBase>
  )
}

export default FileUpload
