import { useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_SESSIONS, SESSION_CREATED, SESSION_DELETED } from '../graphql'

function withSessions(WrappedComponent) {
  return (props) => {
    // used to force rerender after cache updates
    // eslint-disable-next-line no-unused-vars
    const [rerender, setRerender] = useState()
    const { data, loading, error } = useQuery(GET_SESSIONS)
    useSubscription(SESSION_CREATED, {
      onSubscriptionData: ({
        client,
        subscriptionData: {
          data: { sessionCreated },
        },
      }) => {
        const data = client.readQuery({ query: GET_SESSIONS })
        const exists = data.sessions.find((s) => s._id === sessionCreated._id)
        if (exists) {
          return
        }
        client.writeQuery({
          query: GET_SESSIONS,
          data: {
            sessions: [...data.sessions, sessionCreated],
          },
        })
        setRerender(new Date().getTime())
      },
      skip: loading,
    })

    useSubscription(SESSION_DELETED, {
      onSubscriptionData: ({
        client,
        subscriptionData: {
          data: { sessionDeleted },
        },
      }) => {
        const data = client.readQuery({ query: GET_SESSIONS })
        if (data.sessions?.length > 0) {
          client.writeQuery({
            query: GET_SESSIONS,
            data: {
              sessions: data.sessions.filter((s) => s._id !== sessionDeleted),
            },
          })
        }
        setRerender(new Date().getTime())
      },
      skip: loading,
    })

    return (
      <WrappedComponent
        sessions={data ? data.sessions : []}
        loading={loading}
        error={error}
        {...props}
      />
    )
  }
}

export default withSessions
