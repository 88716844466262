import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import { Cookies } from 'react-cookie'

import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      padding: theme.spacing(1),
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.dark,
      fontSize: theme.typography.caption.fontSize,
      color: 'white',
      '& > span': {
        maxWidth: 600,
      },
    },
    close: {
      color: 'white',
    },
  }
})

const CookieNotice = () => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(!new Cookies().get('_ra_cc'))
  }, [])

  const handleClick = (e) => {
    let expires = new Date()
    expires.setFullYear(expires.getFullYear() + 2)
    new Cookies().set('_ra_cc', new Date().getTime(), {
      expires,
    })
    setVisible(false)
  }

  return visible ? (
    <Box className={classes.root}>
      <span>
        To help personalize content and provide a safer experience, we use
        cookies. By clicking or navigating the site, you agree to our use of
        cookies. To learn more, read our{' '}
        <Link component={RouterLink} to={routes.COOKIE_POLICY}>
          Cookies Policy
        </Link>
        .
      </span>
      <IconButton className={classes.close} onClick={handleClick}>
        <Close />
      </IconButton>
    </Box>
  ) : null
}

export default CookieNotice
