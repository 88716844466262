import { useRef, Fragment, useState } from 'react'
import ReactDOM from 'react-dom'
import { makeStyles } from '@material-ui/styles'
import Calendar from 'react-calendar'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import 'react-calendar/dist/Calendar.css'

import InputField from './InputField'

const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: 100,
      '& input': {
        cursor: 'pointer',
      },
    },
    popper: {
      zIndex: theme.zIndex.modal + 1,
    },
    calendar: {
      border: 'none',
      borderRadius: '5px',
      width: '300px',
      fontFamily: 'Roboto',
      '& *': {
        fontFamily: 'Roboto',
      },
      '& * abbr, button': {
        fontWeight: '500',
      },
      '& .react-calendar__navigation button': {
        minWidth: '34px',
        '&:disabled': {
          backgroundColor: 'transparent',
        },
      },
      '& .react-calendar__navigation__label:disabled': {
        color: 'black',
      },
      '& .react-calendar__navigation__arrow': {
        fontSize: 24,
      },
      '& .react-calendar__month-view': {
        padding: '0 1rem 1rem',
      },
      '& .react-calendar__month-view__weekdays__weekday': {
        '& > abbr': {
          textDecoration: 'none',
          color: theme.palette.text.disabled,
          cursor: 'default',
        },
      },
      '& .react-calendar__navigation__label': {
        fontSize: 14,
      },
      '& .react-calendar__tile:disabled': {
        color: 'lightgray',
        backgroundColor: 'transparent',
      },
      '& .react-calendar__tile--active': {
        borderRadius: '3px',
      },
    },
  }
})

const getDateString = (date) => {
  return date.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

const DatePicker = ({
  value,
  disabled,
  onChange,
  CalendarProps,
  ...otherProps
}) => {
  const [open, setOpen] = useState(undefined)
  const classes = useStyles()
  const domRef = useRef(null)
  const inputRef = useRef(null)

  const handleFocus = (e) => {
    setOpen(e.currentTarget)
  }

  const handleBlur = (e) => {
    if (!e.relatedTarget || !domRef.current) {
      return
    }
    if (
      ReactDOM.findDOMNode(domRef.current).getElementsByClassName(
        e.relatedTarget.className
      ).length === 0
    ) {
      setOpen(undefined)
    }
  }

  const handleClickAway = (e, r) => {
    if (e.target !== inputRef.current) {
      setOpen(undefined)
    }
  }

  const handleDateChange = (value) => {
    if (onChange) {
      onChange(value)
    }
    setOpen(undefined)
  }

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: 'narrow' })
  }

  const formatMonthYear = (locale, date) => {
    return date.toLocaleDateString(locale, { month: 'long', year: 'numeric' })
  }

  return (
    <Fragment>
      <InputField
        InputRootClass={classes.input}
        value={value ? getDateString(value) : ''}
        disabled={disabled}
        placeholder={disabled ? undefined : 'Set date'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputRef={inputRef}
        InputProps={{
          readOnly: true
        }}
        {...otherProps}
      />
      <Popper open={Boolean(open)} anchorEl={open} className={classes.popper}>
        <Paper>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Calendar
              ref={domRef}
              value={value}
              className={classes.calendar}
              minDate={new Date()}
              minDetail={'month'}
              onChange={handleDateChange}
              formatMonthYear={formatMonthYear}
              formatShortWeekday={formatShortWeekday}
              {...CalendarProps}
            />
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Fragment>
  )
}

export default DatePicker
