import { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import { Element } from 'react-scroll'

import Action from './Action'
import UserMenu from './UserMenu'

const useStyles = makeStyles((theme) => {
  return {
    placeholder: {
      height: '3rem',
      width: '100%',
      borderRadius: '5px',
      border: '2px dashed lightgrey',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    placeholderText: {
      color: 'lightgrey',
    },
  }
})

const ActionPlan = ({
  actions,
  sessionId,
  risks,
  sessionUsers,
  disabled,
  user,
  addAction,
  updateAction,
  deleteAction,
}) => {
  const [showUserMenu, setShowUserMenu] = useState()
  const [showActionMenu, setShowActionMenu] = useState()
  const [showPlaceholder, setShowPlaceholder] = useState()
  const [dragging, setDragging] = useState(0)

  const classes = useStyles()

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDragEnter = (e) => {
    if (dragging <= 0) {
      setShowPlaceholder(true)
    }
    setDragging(dragging + 1)
  }

  const handleDragLeave = (e) => {
    setDragging(dragging - 1)
    if (dragging - 1 <= 0) {
      setShowPlaceholder(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(0)
    setShowPlaceholder(false)
    const id = e.dataTransfer.getData('text')
    const element = id && document.getElementById(id)
    const riskId = element && element.dataset.riskid
    if (riskId) {
      const risk = risks.find((r) => r._id === riskId)
      if (!risk) {
        return
      }
      addAction({
        variables: {
          sessionId,
          input: {
            riskId: risk._id,
            title: `Mitigation for: ${risk.title}`,
            description: '',
            priority: 'medium',
          },
        },
      })
    }
  }

  const runUpdateMutation = (action, input) => {
    updateAction({
      variables: {
        sessionId,
        actionId: action._id,
        input,
      },
    })
  }

  const handleCloseUserMenu = () => {
    setShowUserMenu()
  }

  const handleCloseActionMenu = () => {
    setShowActionMenu()
  }

  const handleUserMenuSelect = (user) => {
    if (showUserMenu.action) {
      runUpdateMutation(showUserMenu.action, {
        ownerId: user?._id ?? null,
      })
    }
    setShowUserMenu()
  }

  const handleDeleteAction = () => {
    if (showActionMenu && showActionMenu.action) {
      deleteAction({
        variables: { sessionId, actionId: showActionMenu.action._id },
      })
    }
    setShowActionMenu()
  }

  return (
    <Grid
      container
      spacing={2}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {!disabled &&
        (showPlaceholder ||
          ((!actions || actions.length === 0) &&
            risks &&
            risks.length > 0)) && (
          <Grid item xs={12} className={classes.placeholder}>
            <Zoom direction='down' in={true}>
              <Typography
                variant='subtitle1'
                className={classes.placeholderText}
              >
                + Drop a risk on the plan to create an action for it
              </Typography>
            </Zoom>
          </Grid>
        )}
      <Fragment>
        {actions &&
          actions.map((a) => {
            const risk = risks && risks.find((r) => r._id === a.riskId)
            const handlePrioChanged = (priority) => {
              runUpdateMutation(a, { priority })
            }
            const handleStatusChanged = (status) => {
              runUpdateMutation(a, { status })
            }
            const handleResolutionAtChanged = (resolutionAt) => {
              runUpdateMutation(a, { resolutionAt })
            }
            const handleTitleChanged = (title) => {
              runUpdateMutation(a, { title })
            }
            const handleDescriptionChanged = (description) => {
              runUpdateMutation(a, { description })
            }
            const handleClickResponsible = (e, user) => {
              setShowUserMenu({ anchor: e.currentTarget, action: a })
            }
            const handleClickMore = (e) => {
              setShowActionMenu({ anchor: e.currentTarget, action: a })
            }

            return (
              <Grid item xs={12} key={a._id}>
                <Element name={a._id}>
                  <Action
                    data={{
                      action: a,
                      risk,
                      sessionId,
                      user,
                      disabled,
                      responsibleUser:
                        a.ownerId &&
                        sessionUsers.find?.((u) => u._id === a.ownerId),
                    }}
                    onPrioChanged={handlePrioChanged}
                    onStatusChanged={handleStatusChanged}
                    onResolutionAtChanged={handleResolutionAtChanged}
                    onTitleChanged={handleTitleChanged}
                    onDescriptionChanged={handleDescriptionChanged}
                    onClickResponsible={handleClickResponsible}
                    onClickMore={handleClickMore}
                  />
                </Element>
              </Grid>
            )
          })}
        <UserMenu
          disabled={disabled}
          open={showUserMenu !== undefined}
          users={
            showUserMenu?.action &&
            sessionUsers?.filter((u) => u._id !== showUserMenu.action.ownerId)
          }
          showUnassigned={showUserMenu?.action?.ownerId}
          anchorEl={showUserMenu && showUserMenu.anchor}
          onClose={handleCloseUserMenu}
          onSelect={handleUserMenuSelect}
        />
        <Menu
          disabled={disabled}
          open={showActionMenu !== undefined}
          anchorEl={showActionMenu?.anchor}
          onClose={handleCloseActionMenu}
        >
          <MenuItem onClick={handleDeleteAction}>Delete action</MenuItem>
        </Menu>
      </Fragment>
    </Grid>
  )
}

export default ActionPlan
