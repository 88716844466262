import { useRef, useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import InputField from './InputField'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 60,
    },
    input: {
      paddingLeft: theme.spacing(1),
    },
    menuItemGutters: {
      paddingLeft: theme.spacing(1),
    },
    popper: {
      zIndex: theme.zIndex.modal + 1,
    },
    list: {
      height: '200px',
      width: 60,
      overflowX: 'scroll',
    },
    selected: {},
  }
})

const generateTimes = (interval) => {
  const divisor = interval ? Math.round(60 / interval) : 2
  const result = []
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < divisor; j++) {
      const m = Math.round(60 / divisor) * j
      result.push(`${i < 10 ? `0${i}` : i}:${m < 10 ? '0' + m : m}`)
    }
  }
  return result
}

const getTimeValue = (value) => {
  const values = value.split(':')
  let h
  let m
  if (values.length > 1) {
    h = values[0].replace(/\D/g, '').substring(0, 2)
    m = values[1].replace(/\D/g, '').substring(0, 2)
  } else {
    const sanitized = values[0].replace(/\D/g, '')
    h = sanitized.substring(0, 2)
    m = sanitized.substring(2, 4)
  }
  if (h.length === 0) {
    h = '00'
  } else if (h.length === 1) {
    h = `0${h}`
  }
  if (m.length === 0) {
    m = '00'
  } else if (m.length === 1) {
    m = `${m}0`
  }
  return `${h}:${m}`
}

const TimePicker = ({ value, interval, disabled, onChange, ...otherProps }) => {
  const [showMenu, setShowMenu] = useState(undefined)
  const [menuValue, setMenuValue] = useState(undefined)
  const containerRef = useRef(null)
  const inputRef = useRef(null)
  const domRef = useRef(null)

  const classes = useStyles()
  const times = useMemo(() => generateTimes(interval), [interval])

  useEffect(() => {
    const selected =
      domRef.current && domRef.current.querySelector('.' + classes.selected)
    if (selected) {
      domRef.current.scrollTop = selected.offsetTop
    }
  })

  useEffect(() => {
    inputRef.current.value = value
    setMenuValue(times.find((t) => t === value))
  }, [value, times])

  const handleFocus = (e) => {
    if (!showMenu) {
      setShowMenu(e.currentTarget)
    }
  }

  const handleBlur = (e) => {
    if (domRef.current !== (e.relatedTarget && e.relatedTarget.parentNode)) {
      setShowMenu(false)
    }
    const v = getTimeValue(e.target.value)
    if (onChange) {
      onChange(v)
    }
  }

  const handleChange = (e) => {
    const v = getTimeValue(e.currentTarget.value)
    setMenuValue(times.find((t) => t === v))
  }

  const handleClick = (e) => {
    if (!showMenu) {
      setShowMenu(e.currentTarget)
    }
  }

  const handleKeyDown = (e) => {
    if (!showMenu) {
      if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setShowMenu(e.currentTarget)
      }
      return
    }
    if (e.key === 'ArrowDown') {
      const selected =
        domRef.current && domRef.current.querySelector('.' + classes.selected)
      if (selected) {
        const next = selected.nextSibling
        if (next) {
          e.target.value = next.textContent
          setMenuValue(next.textContent)
        }
      }
    } else if (e.key === 'ArrowUp') {
      const selected =
        domRef.current && domRef.current.querySelector('.' + classes.selected)
      if (selected) {
        const previous = selected.previousSibling
        if (previous) {
          e.target.value = previous.textContent
          setMenuValue(previous.textContent)
        }
      }
    } else if (e.key === 'Escape' || e.key === 'Enter') {
      e.stopPropagation()
      setShowMenu(undefined)
    }
  }

  const handleClickMenuItem = (e, t) => {
    e.stopPropagation()
    e.preventDefault()
    setShowMenu(undefined)
    if (onChange) {
      onChange(t)
    }
    inputRef.current.select()
  }

  return (
    <div ref={containerRef}>
      <InputField
        className={classes.root}
        defaultValue={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={handleClick}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        InputRootClass={classes.input}
      />
      <Popper
        className={classes.popper}
        open={Boolean(showMenu)}
        anchorEl={containerRef.current}
        transition
        keepMounted
        placement={'bottom-start'}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <MenuList ref={domRef} className={classes.list}>
                {times.map((t) => (
                  <MenuItem
                    key={t}
                    className={`${t === menuValue ? classes.selected : ''}`}
                    selected={t === menuValue}
                    onClick={(e) => handleClickMenuItem(e, t)}
                    classes={{ gutters: classes.menuItemGutters }}
                  >
                    {t}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default TimePicker
