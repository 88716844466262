import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Avatar from './Avatar'

const useStyles = makeStyles((theme) => {
  return {
    name: {
      marginLeft: theme.spacing(1),
    },
    root: ({ disabled }) => ({
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: disabled ? 'inherit' : 'rgba(0, 0, 0, 0.06)',
      },
      cursor: disabled ? 'inherit' : 'pointer',
      padding: theme.spacing(1),
    }),
  }
})

const UserLabel = ({
  className,
  add,
  defaultTooltip,
  user,
  small,
  selected,
  disabled,
  showStatus,
  onClick,
}) => {
  const classes = useStyles({ disabled })
  return (
    <Box
      className={`${classes.root}${(className && ' ' + className) || ''}`}
      display='flex'
      alignItems='center'
      onClick={!disabled ? onClick : undefined}
    >
      <Avatar
        disabled={disabled}
        showStatus={showStatus}
        user={user}
        small={small}
        add={add}
        defaultTooltip={defaultTooltip}
      />
      <Typography className={classes.name} variant='body1'>
        {user ? user?.name ?? '' : 'Unassigned'}
      </Typography>
    </Box>
  )
}

export default UserLabel
