import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'lightblue',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '50%',
      borderColor: 'white',
      width: '1.25rem',
      height: '1.25rem',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      cursor: 'grab',
      '&:hover': {
        borderWidth: '3px',
        borderColor: 'darkgrey',
      },
      '&:active': {
        cursor: 'grabbing',
      },
    },
    text: {
      margin: 0,
      padding: 0,
      color: 'black',
      fontSize: '0.75rem',
      fontWeight: 800,
      pointerEvents: 'none',
      userSelect: 'none',
    },
  }
})

const AssessmentIndicator = (props) => {
  const { disabled, number, allowDrag, riskId, tooltip, onMouseDown } = props
  const classes = useStyles()
  const handleDrag = (e) => {
    window.getSelection().removeAllRanges()
    if (!disabled && allowDrag) {
      e.dataTransfer.setData('text', e.target.id)
      e.dataTransfer.dropEffect = 'move'
    }
  }

  const preventDrag = (e) => {
    e.preventDefault()
    return false
  }

  const handleMouseDown = (e) => {
    onMouseDown(riskId)
  }

  return (
    <div
      id={`${riskId}-pos`}
      draggable={!disabled && allowDrag}
      onDragStart={handleDrag}
      data-riskid={riskId}
      className={props.className}
      style={props.style}
      onMouseDown={handleMouseDown}
    >
      <Tooltip TransitionComponent={Zoom} title={tooltip}>
        <div
          className={classes.root}
          style={disabled || !allowDrag ? { cursor: 'not-allowed' } : undefined}
        >
          <p
            className={classes.text}
            draggable={false}
            onDragStart={preventDrag}
          >
            {number}
          </p>
        </div>
      </Tooltip>
    </div>
  )
}

export default AssessmentIndicator
