import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import { scroller } from 'react-scroll'

import { ADD_ACTION } from '../graphql'
import RiskDialog from './RiskDialog'
import RiskItem from './RiskItem'
import ConfirmationDialog from './ConfirmationDialog'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        border: 'solid 1px lightgrey',
        borderRadius: '5px',
        padding: theme.spacing(1),
      },
    },
    addContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: theme.spacing(1),
    },
  }
})

const RiskList = (props) => {
  const {
    sessionId,
    risks,
    addRisk,
    updateRisk,
    deleteRisk,
    selectedRiskId,
    user,
    users,
    actions,
    disabled,
    actionThreshold,
    loading,
    onClickRisk,
  } = props
  const classes = useStyles()

  const [riskDialogData, setRiskDialogData] = useState()
  const [dlgData, setDlgData] = useState()

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only('xs'))

  const [
    addAction,
    { data: addActionData, loading: addActionLoading, error: addActionError },
  ] = useMutation(ADD_ACTION)

  useEffect(() => {
    if (addActionData && !addActionLoading && !addActionError) {
      setTimeout(() => {
        scroller.scrollTo(addActionData.addAction._id, {
          duration: 500,
          smooth: true,
        })
        document.getElementById(`${addActionData.addAction._id}-desc`)?.focus()
      }, 100)
    }
  }, [addActionData, addActionLoading, addActionError])

  const getRiskDialogData = (risk) => ({
    risk,
    users,
    canEdit: !disabled,
    canChangeOwner: !disabled,
    canClone: !disabled,
    canDelete: !disabled,
    canAddAction: !disabled,
  })

  const handleEditRisk = (risk) => {
    setRiskDialogData(getRiskDialogData(risk))
  }

  const handleClickRisk = (evt, risk) => {
    !xs && setRiskDialogData(getRiskDialogData(risk))
    onClickRisk && onClickRisk(risk)
  }

  const handleCloneRisk = (risk) => {
    setRiskDialogData({
      risk: {
        title: `${risk.title} - CLONE`,
        description: risk.description,
      },
      users,
      createMode: true,
    })
  }

  const handleDeleteRisk = (risk) => {
    setDlgData({
      action: 'delete',
      title: 'Delete risk',
      content: (
        <>
          Permanently delete risk <br />
          <strong>{risk.title}</strong> ?
        </>
      ),
      btn: 'DELETE',
      risk,
    })
  }

  const handleConfirm = (risk) => {
    const data = dlgData
    setDlgData()
    if (data && data.action === 'delete') {
      risk &&
        deleteRisk({
          variables: { sessionId, riskId: risk._id },
        })
    } else if (data && data.action === 'action') {
      handleAddAction(risk)
    }
  }

  const handleAddRisk = () => {
    setRiskDialogData({
      risk: {
        title: '',
        description: '',
      },
      users,
      createMode: true,
    })
  }

  const handleSubmitRisk = (risk) => {
    const riskId = riskDialogData?.risk?._id
    if (!riskId) {
      addRisk({
        variables: { sessionId, input: { ...risk } },
      })
    } else {
      updateRisk({
        variables: {
          sessionId,
          riskId,
          input: { ...risk },
        },
      })
    }
    setRiskDialogData()
  }

  const handleAddAction = (risk) => {
    addAction({
      variables: {
        sessionId,
        input: {
          riskId: risk._id,
          title: `Mitigation for: ${risk.title}`,
          description: '',
          priority: 'medium',
        },
      },
    })
  }

  const handleCloseRiskDialog = (evt) => {
    setRiskDialogData()
  }

  const handleCloseConfirmationDialog = () => {
    setDlgData()
  }

  const handleActionWarningClick = (risk) => {
    setDlgData({
      action: 'action',
      title: 'Create action',
      content: `Create action for '${risk.title}'?`,
      btn: 'OK',
      risk,
    })
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      {loading &&
        [0, 1, 2].map((s) => (
          <Grid item xs={12} key={`r-s-${s}`}>
            <RiskItem loading></RiskItem>
          </Grid>
        ))}
      {risks &&
        risks.map((r) => {
          const needsAction =
            r.position &&
            r.value >= actionThreshold &&
            actions &&
            !actions.find((a) => a.riskId === r._id)
          const allowAction = !disabled
          return (
            <Grid item xs={12} key={r._id}>
              <RiskItem
                risk={r}
                disabled={disabled}
                selected={selectedRiskId && selectedRiskId === r._id}
                includeEdit={xs}
                needsAction={needsAction}
                owner={users.find((u) => u._id === r.ownerId)}
                canClone={allowAction}
                canDelete={allowAction}
                canAddAction={allowAction}
                canChangeOwner={!disabled}
                canDrag={allowAction}
                onEdit={handleEditRisk}
                onClone={handleCloneRisk}
                onDelete={handleDeleteRisk}
                onClick={handleClickRisk}
                onAddAction={handleAddAction}
                onActionWarningClick={handleActionWarningClick}
              />
            </Grid>
          )
        })}
      {!disabled && (
        <Grid item xs={12} className={classes.addContainer}>
          <Tooltip title='Add risk'>
            <span>
              <Fab
                size='small'
                className={classes.button}
                color='secondary'
                disabled={loading}
                onClick={handleAddRisk}
              >
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </Grid>
      )}
      <RiskDialog
        open={!dlgData && riskDialogData !== undefined}
        onClose={handleCloseRiskDialog}
        createMode={riskDialogData?.createMode}
        risk={riskDialogData?.risk}
        user={user}
        currentOwner={
          riskDialogData?.risk?.ownerId &&
          users?.find((u) => u._id === riskDialogData.risk.ownerId)
        }
        sessionId={sessionId}
        users={riskDialogData?.users}
        canEdit={riskDialogData?.canEdit}
        canChangeOwner={riskDialogData?.canChangeOwner}
        canClone={riskDialogData?.canClone}
        canDelete={riskDialogData?.canDelete}
        canAddAction={riskDialogData?.canAddAction}
        onClone={handleCloneRisk}
        onDelete={handleDeleteRisk}
        onAddAction={handleAddAction}
        onSubmit={handleSubmitRisk}
      />
      <ConfirmationDialog
        open={Boolean(dlgData)}
        title={dlgData?.title}
        content={dlgData?.content}
        confirmButtonText={dlgData?.btn}
        handleClose={handleCloseConfirmationDialog}
        handleConfirm={handleConfirm}
        data={dlgData?.risk}
      />
    </Grid>
  )
}

export default RiskList
