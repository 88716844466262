import { useRef, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tooltip from '@material-ui/core/Tooltip'
import AssessmentIndicator from './AssessmentIndicator'
import { getCellPosition, numberTo2Decimals } from '../util'
import { drawAxis } from '../canvas'
import Stickyfill from 'stickyfilljs'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      position: 'sticky',
      top: theme.spacing(4),
    },
    grid: {
      borderSpacing: '1px',
    },
    cell: {
      borderRadius: '2px',
    },
    cellInner: {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
    over: {
      backgroundColor: 'black',
    },
    dim: {
      opacity: '0.5',
    },
    assessment: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      zIndex: 100,
      pointerEvents: 'auto',
    },
    hAxis: {
      width: ({ matrixSize }) => `${matrixSize}px`,
      height: '30px',
      [theme.breakpoints.down('xs')]: {
        bottom: theme.spacing(1),
      },
      position: 'absolute',
      right: theme.spacing(0.75),
      bottom: theme.spacing(0.5),
    },
    vAxis: {
      width: '30px',
      height: ({ matrixSize }) => `${matrixSize}px`,
      [theme.breakpoints.down('xs')]: {
        left: theme.spacing(0.5),
      },
      position: 'absolute',
      top: theme.spacing(0.5),
      left: 0,
    },
    dropTarget: {
      opacity: '0.3',
    },
    hNumber: {
      textAlign: 'center',
      paddingTop: theme.spacing(0.75),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(0.5),
        fontSize: theme.typography.pxToRem(12),
      },
      cursor: 'default',
    },
    vNumber: {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(0.5),
        fontSize: theme.typography.pxToRem(12),
      },
      cursor: 'default',
    },
  }
})

const getPosition = (e) => {
  const r = e.currentTarget.getBoundingClientRect()
  const x = e.clientX - r.left
  const y = e.clientY - r.top
  return {
    x: numberTo2Decimals(parseInt(e.currentTarget.dataset.x) + x / r.width),
    y: numberTo2Decimals(
      parseInt(e.currentTarget.dataset.y) + (1 - y / r.height)
    ),
  }
}

const Matrix = (props) => {
  const {
    matrix,
    risks,
    onClick,
    onDrop,
    disabled,
    onClickAssessment,
  } = props

  const classes = useStyles({ sizeX: matrix.size.x, sizeY: matrix.size.y })
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const maxSize = large ? 400 : 300
  let width
  let height
  if (matrix.size.x === matrix.size.y) {
    width = height = maxSize
  } else if (matrix.size.x > matrix.size.y) {
    width = maxSize
    height = Math.trunc((maxSize / matrix.size.x) * matrix.size.y)
  } else {
    width = Math.trunc((maxSize / matrix.size.y) * matrix.size.x)
    height = maxSize
  }

  const cellSize = maxSize / Math.max(matrix.size.x, matrix.size.y)

  const handleClick = (e) => {
    if (disabled) {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    if (onClick) {
      const { x, y } = getPosition(e)
      onClick(x, y)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.remove(classes.dropTarget)
    const id = e.dataTransfer.getData('text')
    const element = id && document.getElementById(id)
    const riskId = element && element.dataset.riskid
    const { x: targetX, y: targetY } = e.currentTarget.dataset
    const { x, y } = getPosition(e)
    if (disabled || !targetX || !targetY || !riskId) {
      return
    }
    if (onDrop) {
      onDrop(x, y, riskId)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { x: targetX, y: targetY } = e.target.dataset
    if (disabled || !targetX || !targetY) {
      return
    }
    e.target.classList.add(classes.dropTarget)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { x: targetX, y: targetY } = e.target.dataset
    if (disabled || !targetX || !targetY) {
      return
    }
    e.target.classList.remove(classes.dropTarget)
  }

  const rows = matrix.cells.slice().reverse()

  const generate = () => {
    const result = rows.map((row, y) => (
      <tr key={`matrix-row-${y}`}>
        <Tooltip
          title={
            matrix.vLabels?.[Math.abs(y - matrix.size.y) - 1] ??
            Math.abs(y - matrix.size.y)
          }
          placement={'left'}
        >
          <td className={classes.vNumber}>
            <span>{Math.abs(y - matrix.size.y)}</span>
          </td>
        </Tooltip>

        {row.map((cell, x) => (
          <td
            className={classes.cell}
            key={`matrix-cell-${x}-${y}`}
            data-x={x}
            data-y={Math.abs(y - matrix.size.y + 1)}
            style={{ backgroundColor: `${cell.color}` }}
            onClick={handleClick}
            onDropCapture={handleDrop}
            onDragOverCapture={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            width={`${cellSize - 1}px`}
            height={`${cellSize - 1}px`}
          >
            <div
              className={
                classes.cellInner
              } /* required for absolute positioning in IE 11 */
            >
              {risks &&
                risks.map((r) => {
                  const pos = r.position
                  if (!pos) return undefined
                  return (
                    (Math.trunc(pos.x) === x ||
                      (x === matrix.size.x - 1 && pos.x === matrix.size.x)) &&
                    Math.trunc(pos.y) === Math.abs(y - matrix.size.y + 1) && (
                      <AssessmentIndicator
                        className={classes.assessment}
                        style={getCellPosition(pos)}
                        disabled={disabled}
                        allowDrag={!disabled}
                        riskId={r._id}
                        onMouseDown={onClickAssessment}
                        key={`${r._id}-pos`}
                        number={r.identifier}
                        tooltip={r.title}
                      />
                    )
                  )
                })}
            </div>
          </td>
        ))}
      </tr>
    ))
    result.push(
      <tr key='hn'>
        {Array(matrix.size.x + 1)
          .fill()
          .map((e, i) => (
            <Tooltip key={`hn${i}`} title={matrix.hLabels?.[i - 1] ?? i}>
              <td className={classes.hNumber}>{i > 0 && <span>{i}</span>}</td>
            </Tooltip>
          ))}
      </tr>
    )
    return result
  }

  const hAxis = useRef(null)
  const vAxis = useRef(null)
  const tableRef = useRef(null)
  const container = useRef(null)
  const root = useRef(null)

  const drawAxes = () => {
    let canvas = hAxis.current
    canvas.width = canvas.clientWidth * 2
    canvas.height = canvas.clientHeight * 2
    let ctx = canvas.getContext('2d')
    drawAxis(
      ctx,
      0,
      0,
      canvas.width,
      canvas.height,
      'black',
      matrix.hAxisLabel || ''
    )
    canvas = vAxis.current
    canvas.width = canvas.clientWidth * 2
    canvas.height = canvas.clientHeight * 2
    ctx = canvas.getContext('2d')
    drawAxis(
      ctx,
      0,
      0,
      canvas.width,
      canvas.height,
      'black',
      matrix.vAxisLabel || '',
      true
    )
  }

  const handleResize = (evt) => {
    if (container.current) {
      drawAxes()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    drawAxes()
  })

  useEffect(() => {
    if (root.current) {
      Stickyfill.add(root.current)
    }
  }, [])

  return (
    <div ref={container}>
      <div className={classes.root} ref={root}>
        <table
          ref={tableRef}
          className={`${classes.grid} ${disabled && classes.dim}`}
        >
          <tbody>{generate()}</tbody>
        </table>
        <canvas
          className={classes.hAxis}
          width={`${width * 2}px`}
          height={'60px'}
          ref={hAxis}
        />
        <canvas
          className={classes.vAxis}
          width={'60px'}
          height={`${height * 2}px`}
          ref={vAxis}
        />
      </div>
    </div>
  )
}

export default Matrix
