import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Skeleton from '@material-ui/lab/Skeleton'

import ErrorIcon from '@material-ui/icons/Error'

import { useQuery, useSubscription } from '@apollo/client'

import { MY_ACTIONS, ACTION_ASSIGNED } from '../graphql'
import routes from '../routes'
import { formatDate } from '../util'
import { getActionStatusLabel } from '../utils/actions'

const useStyles = makeStyles((theme) => ({
  table: {
    cursor: 'pointer',
  },
  checkCell: {
    lineHeight: 'unset',
  },
  rowCheckCell: {
    lineHeight: 'unset',
    paddingRight: theme.spacing(3),
  },
  icon: {
    color: theme.palette.text.disabled,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  resolutionAt: {
    color: theme.palette.primary.dark,
  },
  status: {
    whiteSpace: 'nowrap',
  },
  error: {
    color: theme.palette.error.light,
    marginLeft: theme.spacing(0.5),
  },
}))

const ActionTable = ({ user, filter }) => {
  const navigate = useNavigate()
  const { data: { myActions: actions } = {}, loading, refetch } = useQuery(MY_ACTIONS, {
    variables: {
      filter: {
        statuses: filter.statuses,
        owners: filter.owner ? [user._id] : undefined,
      },
    },
    fetchPolicy: 'network-only',
  })

  useSubscription(ACTION_ASSIGNED, {
    skip: loading,
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { actionAssigned: action },
      },
    }) => {
      refetch()
    },
  })

  const handleRowClick = (action) => {
    navigate(`${routes.RISK_ASSESSMENTS}/${action.sessionId}?action=${action._id}`)
  }

  const classes = useStyles()

  const [sortDescending, setSortDescending] = useState(true)
  const [sortColumn, setSortColumn] = useState('updated')

  const headerData = [
    {
      id: 'title',
      padding: 'normal',
      children: 'Title',
    },
    {
      id: 'sessionName',
      children: 'Risk assessment',
    },
    {
      id: 'owner',
      children: 'Owner',
    },
    {
      id: 'updated',
      children: 'Updated',
    },
    {
      id: 'status',
      children: 'Status',
    },
    {
      id: 'resolution',
      children: 'Due date',
      className: classes.status,
    },
  ]

  const rowProps = [
    {
      id: 'title',
      padding: 'normal',
      width: '35%',
      align: 'left',
    },
    {
      id: 'sessionName',
      width: '35%',
    },
    {
      id: 'owner',
      width: '15%',
    },
    {
      id: 'updatedAt',
      className: classes.resolutionAt,
      align: 'left',
    },
    {
      id: 'status',
      className: classes.status,
    },
    {
      id: 'resolutionAt',
      className: classes.resolutionAt,
      align: 'left',
    },
  ]

  const getFilteredActions = () =>
    filter
      ? actions?.filter((a) => {
        const searchString = filter.search && filter.search.toLowerCase()
        const searchMatch = searchString
          ? a.title.toLowerCase().includes(searchString)
          : true
        const statusMatch = filter.statuses
          ? filter.statuses.includes(a.status)
          : true
        const ownerMatch = !filter.owner || a.ownerId === user._id
        return searchMatch && statusMatch && ownerMatch
      }) ?? []
      : actions

  const handleSortLabelClick = (id) => {
    if (id === sortColumn) {
      setSortDescending(!sortDescending)
    } else {
      setSortDescending(false)
      setSortColumn(id)
    }
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy])
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (sortDescending, orderBy) => {
    return sortDescending
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const getSortedActions = () => {
    const tmp =
      getFilteredActions().map((a) => ({
        ...a,
        status: a.status || 'Not started',
        resolution: a.resolutionAt
          ? new Date(a.resolutionAt)
          : new Date(8640000000000000),
        updated: new Date(a.updatedAt),
        owner: a.ownerName || a.ownerEmail || '',
      })) ?? []
    return stableSort(tmp, getComparator(sortDescending, sortColumn))
  }

  const sortedActions = getSortedActions()

  const showDueDateError = (action) => {
    if (!action?.resolutionAt || action?.status === 'completed') {
      return false
    }
    return (
      action.resolutionAt &&
      new Date(action.resolutionAt).getTime() < Date.now()
    )
  }

  const getOwner = (action) => {
    if (!action.ownerId) {
      return undefined
    }
    if (action.ownerId === user._id) {
      return <Chip size='small' label='Me' variant='outlined' />
    }
    return action.ownerName || action.ownerEmail
  }
  const createTableRows = () => {
    if (loading) {
      return [1, 2, 3].map((i) => {
        return (
          <TableRow key={`skeleton${i}`}>
            <TableCell {...rowProps[0]}>
              <Skeleton variant='text' width={300} height={24} />
            </TableCell>
            <TableCell {...rowProps[1]}>
              <Skeleton variant='text' width={60} height={24} />
            </TableCell>
            <TableCell {...rowProps[2]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
            <TableCell {...rowProps[3]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
            <TableCell {...rowProps[4]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
            <TableCell {...rowProps[5]}>
              <Skeleton variant='text' width={40} height={24} />
            </TableCell>
          </TableRow>
        )
      })
    } else {
      return sortedActions && sortedActions.length > 0 ? (
        sortedActions.map((action) => (
          <TableRow
            key={action._id}
            hover
            onClick={() => handleRowClick(action)}
          >
            <TableCell {...rowProps[0]}>{action.title}</TableCell>
            <TableCell {...rowProps[1]}>{action.sessionName}</TableCell>
            <TableCell {...rowProps[2]}>{getOwner(action)}</TableCell>
            <TableCell {...rowProps[3]}>
              {action.updatedAt ? formatDate(action.updatedAt) : ''}
            </TableCell>
            <TableCell {...rowProps[4]}>
              {getActionStatusLabel(action.status)}
            </TableCell>
            <TableCell {...rowProps[5]}>
              <Box display='flex' alignItems='center'>
                <span>
                  {action.resolutionAt ? formatDate(action.resolutionAt) : ''}
                </span>
                {showDueDateError(action) && (
                  <ErrorIcon className={classes.error} />
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell style={{ fontSize: 18 }} colSpan={6} align='center'>
            No matching actions
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((h, i) => {
              return (
                <TableCell key={h.id} {...h}>
                  <TableSortLabel
                    active={sortColumn === h.id}
                    direction={
                      h.id === sortColumn
                        ? sortDescending
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                    }
                    onClick={() => {
                      handleSortLabelClick(h.id)
                    }}
                    children={h.children}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>{createTableRows()}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default ActionTable
