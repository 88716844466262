import { useMutation, useQuery, useSubscription } from '@apollo/client'
import {
  ADD_RISK,
  CLEANUP_RISKS,
  DELETE_RISK,
  GET_ACTIONS,
  GET_RISKS,
  RISK_CHANGED,
  RISK_CREATED,
  RISK_DELETED,
  RISKS_IMPORTED,
  UPDATE_RISK,
} from '../graphql'

import { addColorAndValueToRisk, sortRisks } from '../util'

const useRisks = (sessionId, matrix) => {
  const { data, loading } = useQuery(GET_RISKS, {
    skip: !sessionId,
    variables: { sessionId },
  })

  useSubscription(RISK_CHANGED, {
    variables: { sessionId },
    skip: !sessionId || loading,
  })

  useSubscription(RISK_CREATED, {
    variables: { sessionId },
    skip: !sessionId || loading,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const risk = subscriptionData?.data?.riskCreated
      if (!risk) {
        return
      }
      const data = client.readQuery({
        query: GET_RISKS,
        variables: { sessionId },
      })
      const exists = data.risks.find((r) => r._id === risk._id)
      if (exists) {
        return
      }
      client.writeQuery({
        query: GET_RISKS,
        data: {
          risks: [...data.risks, risk],
        },
        variables: { sessionId },
      })
    },
  })
  useSubscription(RISK_DELETED, {
    variables: { sessionId },
    skip: !sessionId || loading,
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { riskDeleted },
      },
    }) => {
      const data = client.readQuery({
        query: GET_RISKS,
        variables: { sessionId },
      })
      client.writeQuery({
        query: GET_RISKS,
        variables: { sessionId },
        data: {
          risks: data.risks.filter((r) => r._id !== riskDeleted),
        },
      })
      const actionData = client.readQuery({
        query: GET_ACTIONS,
        variables: { sessionId },
      })
      if (!actionData.actions) {
        return
      }
      client.writeQuery({
        query: GET_ACTIONS,
        variables: { sessionId },
        data: {
          actions: actionData.actions.filter((a) => a.riskId !== riskDeleted),
        },
      })
    },
  })

  useSubscription(RISKS_IMPORTED, {
    variables: { sessionId },
    skip: !sessionId || loading,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const risks = subscriptionData?.data?.risksImported?.risks
      if (!risks) {
        return
      }
      const data = client.readQuery({
        query: GET_RISKS,
        variables: { sessionId },
      })
      const newRisks = risks.filter(
        (newRisk) => !data.risks.find((r) => r._id === newRisk._id)
      )
      if (newRisks.length === 0) {
        return
      }
      client.writeQuery({
        query: GET_RISKS,
        data: {
          risks: [...data.risks, ...newRisks],
        },
        variables: { sessionId },
      })
    },
  })
  const [addRisk] = useMutation(ADD_RISK, { ignoreResults: true })
  const [updateRisk] = useMutation(UPDATE_RISK, { ignoreResults: true })
  const [deleteRisk] = useMutation(DELETE_RISK, { ignoreResults: true })
  const [cleanupRisks] = useMutation(CLEANUP_RISKS, { ignoreResults: true })

  let risks = []
  if (matrix && data?.risks?.length > 0) {
    risks = data.risks.map((r) => {
      const newRisk = { ...r }
      addColorAndValueToRisk(newRisk, matrix)
      return newRisk
    })
    sortRisks(risks, matrix)
  }

  return [
    risks ?? [],
    loading,
    { addRisk, updateRisk, deleteRisk, cleanupRisks },
  ]
}

export default useRisks
