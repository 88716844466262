import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import withAuth from '../components/auth'
import OrganizationMembers from '../components/OrganizationMembers'
import BillingSection from '../components/BillingSection'
import PlanSection from '../components/PlanSection'

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(2),
    },
    logo: {
      maxHeight: '46px',
    },
  }
})

const OrganizationPage = ({ auth: { org, user, refetch } }) => {
  const classes = useStyles()
  const isEnterprise = org?.billing?.plan === 'enterprise' ?? false
  const isOwner = user?._id === org?.ownerId ?? false

  const nonEnterpriseMemberContent = () => (
    <Paper className={classes.paper}>
      <Alert severity={'info'}>
        Upgrade to the enterprise plan to add organization members
      </Alert>
    </Paper>
  )

  return (
    <Box m={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Overview</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <Box display={'flex'} justifyContent='space-between'>
              <div>
                <Typography variant='caption'>Name</Typography>
                <Typography variant='body1'>{org?.name}</Typography>
              </div>
              {org?.branding?.logo && <img className={classes.logo} src={`data:image/png;base64, ${org?.branding?.logo}`} alt="org?.name" />}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Members</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          {isEnterprise ? (
            <OrganizationMembers
              user={user}
              organization={org}
            ></OrganizationMembers>
          ) : (
            nonEnterpriseMemberContent()
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Plan</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <PlanSection
            canEdit={isOwner}
            billingInfo={org?.billing}
            refetch={refetch}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant='h6'>Billing</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <BillingSection
            canEdit={isOwner}
            billingInfo={org?.billing}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withAuth(OrganizationPage)
