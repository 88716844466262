import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import WarningIcon from '@material-ui/icons/Warning'

import { makeStyles } from '@material-ui/styles'
import Avatar from './Avatar'

const useStyles = makeStyles((theme) => {
  return {
    warning: {
      color: theme.palette.warning.light,
      marginLeft: theme.spacing(1),
    },
  }
})

const UserMenu = (props) => {
  const { users, onSelect, showUnassigned, organizationId, ...otherProps } = props
  const classes = useStyles()

  const handleClick = (user) => {
    if (onSelect) {
      onSelect(user)
    }
  }
  return (
    <Menu {...otherProps}>
      {showUnassigned && (
        <MenuItem key={'usermemnu_unassigned'} onClick={() => handleClick()}>
          <ListItemIcon>
            <Avatar small />
          </ListItemIcon>
          <Typography variant='inherit'>{'Unassigned'}</Typography>
        </MenuItem>
      )}
      {users &&
        users.map((u) => (
          <MenuItem key={'usermemnu' + u._id} onClick={() => handleClick(u)}>
            <ListItemIcon>
              <Avatar user={u} small />
            </ListItemIcon>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Typography variant='inherit'>{u.name || u.email}</Typography>
              {organizationId && organizationId !== u.organizationId &&
                <Tooltip title='External user'>
                  <WarningIcon className={classes.warning} fontSize='small' />
                </Tooltip>
              }
            </Box>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default UserMenu
