import { useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'

import { ACTIVATE_RECOVERY_KEY, GENERATE_RECOVERY_KEY } from '../graphql'

import ProgressButton from './ProgressButton'
import PasswordInput from './PasswordInput'

const useStyles = makeStyles((theme) => {
  return {
    titleText: {
      flex: 1,
    },
    checkmark: {
      width: '4rem',
      height: '4rem',
    },
  }
})

const GenerateRecoveryKeyDialog = ({ open, onClose, subject }) => {
  const dlRef = useRef()

  const [activeStep, setActiveStep] = useState(0)
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState()
  const [keyId, setKeyId] = useState()
  const [keyUrl, setKeyUrl] = useState()
  const [keyDownloaded, setKeyDownloaded] = useState(false)
  const [completed, setCompleted] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    document.title = 'Generate recovery key'
  })

  const [generateRecoveryKey, { loading: generatingKey }] = useMutation(
    GENERATE_RECOVERY_KEY,
    {
      onCompleted: (data) => {
        const { keyId, privateKey, status } = data.generateRecoveryKey
        setStatus(status)
        if (status === 'ok') {
          setKeyId(keyId)
          const blob = new Blob([atob(privateKey)])
          setKeyUrl(URL.createObjectURL(blob))
          setActiveStep(1)
        }
      },
    }
  )

  const [activateRecoveryKey, { loading: activatingKey }] = useMutation(
    ACTIVATE_RECOVERY_KEY,
    {
      onCompleted: (data) => {
        const status = data.activateRecoveryKey
        setCompleted(status === 'ok')
      },
    }
  )

  useEffect(() => {
    if (open) {
      setActiveStep(0)
      setPassword('')
      setKeyId()
      setKeyUrl()
      setCompleted()
      setKeyDownloaded(false)
    }
  }, [open])

  const handleBack = (evt) => {
    setActiveStep(activeStep > 0 ? activeStep - 1 : 0)
  }

  const handleNext = (evt) => {
    if (completed) {
      onClose && onClose()
      return
    }
    switch (activeStep) {
      case 0:
        generateRecoveryKey({ variables: { password } })
        break
      case 1:
        URL.revokeObjectURL(keyUrl)
        setKeyUrl()
        setActiveStep(activeStep + 1)
        break
      default:
        console.error('Unexpected step')
        break
    }
  }

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value)
  }

  const handleDownloadClick = (evt) => {
    dlRef.current?.click()
    setKeyDownloaded(true)
  }

  const handleActivateKeyClick = (evt) => {
    activateRecoveryKey({ variables: { password, keyId } })
  }

  const STEPS = [
    {
      label: (
        <Typography variant='body1'>
          Enter your password and click next
        </Typography>
      ),
      components: (
        <PasswordInput
          disabled={generatingKey}
          error={status === 'invalidPassword' ? true : undefined}
          password={password}
          onChange={handlePasswordChange}
        />
      ),
      canComplete: () => password,
    },
    {
      label: (
        <Typography variant='body1'>
          Download the key and store it in a safe location
        </Typography>
      ),
      components: (
        <div>
          <Button
            variant='contained'
            color='primary'
            disabled={!keyUrl}
            onClick={handleDownloadClick}
          >
            Download
          </Button>
          <a
            style={{ display: 'none' }}
            download={'riskace.key'}
            href={keyUrl}
            ref={dlRef}
          >
            download
          </a>
        </div>
      ),
      canComplete: () => keyDownloaded,
    },
    {
      label: (
        <Typography variant='body1'>
          {completed
            ? 'Recovery key successfully activated!'
            : 'Activate the new recovery key'}
        </Typography>
      ),
      components: completed ? (
        <CheckCircleOutlineIcon
          color='secondary'
          className={classes.checkmark}
        />
      ) : (
        <ProgressButton
          disabled={!(password && keyId)}
          loading={activatingKey}
          variant='contained'
          color='primary'
          onClick={handleActivateKeyClick}
        >
          Activate key
        </ProgressButton>
      ),
      canComplete: () => completed,
    },
  ]

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' justifyContent='stretch' alignItems='center'>
          <Typography className={classes.titleText} variant='h6'>
            Replace recovery key
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Follow the steps below to replace the recovery key for your account.
          The recovery is required to reset your password in case you forget it.
        </DialogContentText>
        <Stepper activeStep={activeStep}>
          {STEPS.map((step, i) => (
            <Step
              key={`authstep${i}`}
              active={activeStep === i}
              completed={activeStep > i}
            >
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {STEPS[activeStep].label}
          <Box
            mt={2}
            height={'200px'}
            display='flex'
            flexDirection='column'
            alignItems='center'
            minWidth={{ xs: 200, sm: 400 }}
          >
            {STEPS[activeStep].components}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0 || completed} onClick={handleBack}>
          Back
        </Button>
        <ProgressButton
          disabled={!STEPS[activeStep].canComplete()}
          loading={generatingKey}
          variant='contained'
          color='primary'
          onClick={handleNext}
        >
          {activeStep === 2 ? 'Close' : 'Next'}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateRecoveryKeyDialog
