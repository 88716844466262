import { gql } from '@apollo/client'
import {
  SessionDetailsFragment,
  ParticipantDetailsFragment,
  RiskDetailsFragment,
  ActionDetailsFragment,
} from './SessionFragments'
import { CurrentUserFragment } from './CurrentUserFragment'
import { UserDetailsFragment } from './UserFragments'
import { OrganizationFragment } from './OrganizationFragment'

const CURRENT_USER = gql`
  {
    currentUser {
      ...currentUser
    }
  }
  ${CurrentUserFragment}
`

const GET_SESSIONS = gql`
  {
    sessions {
      _id
      owner {
        _id
        email
        name
      }
      name
      status
      updatedAt
      organizationName
    }
  }
`

const GET_SESSION = gql`
  query ($id: ID!) {
    session: session(id: $id) {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const GET_PARTICIPANTS = gql`
  query ($sessionId: ID!) {
    participants: sessionParticipants(sessionId: $sessionId) {
      ...participantDetails
    }
  }
  ${ParticipantDetailsFragment}
`

const CREATE_SESSION = gql`
  mutation createSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const DELETE_SESSION = gql`
  mutation deleteSession($id: ID!) {
    deleteSession(id: $id)
  }
`

const CLONE_SESSION = gql`
  mutation cloneSession($id: ID!, $options: SessionCloneOptions) {
    cloneSession(id: $id, options: $options) {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const UPDATE_SESSION = gql`
  mutation updateSession($sessionId: ID!, $input: UpdateSessionInput!) {
    updateSession(sessionId: $sessionId, input: $input) {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const GET_RISKS = gql`
  query ($sessionId: ID!) {
    risks: risks(sessionId: $sessionId) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const CLEANUP_RISKS = gql`
  mutation cleanupRisks($sessionId: ID!) {
    cleanupRisks(sessionId: $sessionId) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const ADD_RISK = gql`
  mutation addRisk($sessionId: ID!, $input: RiskInput!) {
    addRisk(sessionId: $sessionId, input: $input) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const ADD_RISKS = gql`
  mutation addRisks($sessionId: ID!, $input: [RiskInput!]!) {
    addRisks(sessionId: $sessionId, input: $input) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const UPDATE_RISK = gql`
  mutation updateRisk($sessionId: ID!, $riskId: ID!, $input: RiskInput!) {
    updateRisk(sessionId: $sessionId, riskId: $riskId, input: $input) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const DELETE_RISK = gql`
  mutation deleteRisk($sessionId: ID!, $riskId: ID!) {
    deleteRisk(sessionId: $sessionId, riskId: $riskId)
  }
`

const GET_ACTIONS = gql`
  query ($sessionId: ID!) {
    actions: actions(sessionId: $sessionId) {
      ...actionDetails
    }
  }
  ${ActionDetailsFragment}
`

const MY_ACTIONS = gql`
  query ($filter: ActionFilter) {
    myActions(filter: $filter) {
      sessionId
      sessionName
      _id
      title
      description
      riskId
      ownerId
      ownerName
      ownerEmail
      resolutionAt
      priority
      status
      updatedAt
    }
  }
`

const ADD_ACTION = gql`
  mutation addAction($sessionId: ID!, $input: ActionInput!) {
    addAction(sessionId: $sessionId, input: $input) {
      ...actionDetails
    }
  }
  ${ActionDetailsFragment}
`

const UPDATE_ACTION = gql`
  mutation updateAction($sessionId: ID!, $actionId: ID!, $input: ActionInput!) {
    updateAction(sessionId: $sessionId, actionId: $actionId, input: $input) {
      ...actionDetails
    }
  }
  ${ActionDetailsFragment}
`
const DELETE_ACTION = gql`
  mutation deleteAction($sessionId: ID!, $actionId: ID!) {
    deleteAction(sessionId: $sessionId, actionId: $actionId)
  }
`

const ADD_PARTICIPANT = gql`
  mutation addParticipant(
    $sessionId: ID!
    $input: ParticipantInput!
    $sendInvite: Boolean
  ) {
    addParticipant(
      sessionId: $sessionId
      input: $input
      sendInvite: $sendInvite
    ) {
      ...participantDetails
    }
  }
  ${ParticipantDetailsFragment}
`

const DELETE_PARTICIPANTS = gql`
  mutation deleteParticipants($sessionId: ID!, $userIds: [ID!]!) {
    deleteParticipants(sessionId: $sessionId, userIds: $userIds)
  }
`

const UPDATE_PARTICIPANT_STATUS = gql`
  mutation updateParticipantStatus(
    $sessionId: ID!
    $status: ParticipantStatus!
  ) {
    updateParticipantStatus(sessionId: $sessionId, status: $status) {
      ...participantDetails
    }
  }
  ${ParticipantDetailsFragment}
`

const INVITE_PARTICIPANTS = gql`
  mutation inviteParticipants($sessionId: ID!) {
    inviteParticipants(sessionId: $sessionId)
  }
`

const INVITE_PARTICIPANT = gql`
  mutation inviteParticipant($sessionId: ID!, $userId: ID!) {
    inviteParticipant(sessionId: $sessionId, userId: $userId)
  }
`

const SESSION_CHANGED = gql`
  subscription onSessionChanged($sessionId: ID!) {
    sessionChanged(sessionId: $sessionId) {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const SESSION_CREATED = gql`
  subscription sessionCreated {
    sessionCreated {
      ...sessionDetails
    }
  }
  ${SessionDetailsFragment}
`

const SESSION_DELETED = gql`
  subscription sessionDeleted {
    sessionDeleted
  }
`

const SESSION_PARTICIPANT_ADDED = gql`
  subscription sessionParticipantAdded($sessionId: ID!) {
    sessionParticipantAdded(sessionId: $sessionId) {
      ...participantDetails
    }
  }
  ${ParticipantDetailsFragment}
`

const SESSION_PARTICIPANTS_REMOVED = gql`
  subscription sessionParticipantsRemoved($sessionId: ID!) {
    sessionParticipantsRemoved(sessionId: $sessionId)
  }
`

const SESSION_LOG_ENTRIES_ADDED = gql`
  subscription onSessionLogEntriesAdded($sessionId: ID!) {
    sessionLogEntriesAdded(sessionId: $sessionId) {
      t
      text
    }
  }
`

const RISK_CHANGED = gql`
  subscription riskChanged($sessionId: ID!) {
    riskChanged(sessionId: $sessionId) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const RISK_CREATED = gql`
  subscription riskCreated($sessionId: ID!) {
    riskCreated(sessionId: $sessionId) {
      ...riskDetails
    }
  }
  ${RiskDetailsFragment}
`

const RISK_DELETED = gql`
  subscription riskDeleted($sessionId: ID!) {
    riskDeleted(sessionId: $sessionId)
  }
`

const RISKS_IMPORTED = gql`
  subscription risksImported($sessionId: ID!) {
    risksImported(sessionId: $sessionId) {
      risks {
        ...riskDetails
      }
      logEntries {
        t
        text
      }
    }
  }
  ${RiskDetailsFragment}
`

const ACTION_CHANGED = gql`
  subscription actionChanged($sessionId: ID!) {
    actionChanged(sessionId: $sessionId) {
      ...actionDetails
    }
  }
  ${ActionDetailsFragment}
`

const ACTION_CREATED = gql`
  subscription actionCreated($sessionId: ID!) {
    actionCreated(sessionId: $sessionId) {
      ...actionDetails
    }
  }
  ${ActionDetailsFragment}
`

const ACTION_DELETED = gql`
  subscription actionDeleted($sessionId: ID!) {
    actionDeleted(sessionId: $sessionId)
  }
`

const ACTION_ASSIGNED = gql`
  subscription actionAssigned {
    actionAssigned {
      sessionId
      sessionName
      _id
      title
      description
      riskId
      ownerId
      ownerName
      ownerEmail
      resolutionAt
      priority
      status
      updatedAt
    }
  }
`

const USER_CHANGED = gql`
  subscription onUserChanged {
    userChanged {
      ...userDetails
    }
  }
  ${UserDetailsFragment}
`

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      status
      user {
        ...userDetails
      }
    }
  }
  ${UserDetailsFragment}
`

const LOGOUT = gql`
  mutation logout {
    logout
  }
`

const REGISTER_USER = gql`
  mutation registerUser($recaptcha: String!, $input: RegisterUserInput!) {
    registerUser(recaptcha: $recaptcha, input: $input) {
      status
      user {
        ...userDetails
      }
    }
  }
  ${UserDetailsFragment}
`

const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`

const CHANGE_USERNAME = gql`
  mutation changeUserName($name: String!) {
    changeUserName(name: $name)
  }
`

const SEND_ACCOUNT_VERIFICATION = gql`
  mutation sendAccountVerification($email: String!, $recaptcha: String!) {
    sendAccountVerification(email: $email, recaptcha: $recaptcha)
  }
`

const VERIFY_ACCOUNT = gql`
  query ($token: String!) {
    verifyAccount: verifyAccount(token: $token)
  }
`

const ORGANIZATION = gql`
  query ($id: ID!) {
    organization: organization(id: $id) {
      ...organization
    }
  }
  ${OrganizationFragment}
`

const ORGANIZATION_MEMBERS = gql`
  query ($id: ID!) {
    organizationMembers: organizationMembers(id: $id) {
      createdAt
      createdBy {
        _id
        name
        email
      }
      updatedAt
      user {
        _id
        name
        email
      }
      role
      status
    }
  }
`

const TOKEN_USER = gql`
  query ($token: String!) {
    tokenUser: tokenUser(token: $token) {
      ...userDetails
    }
  }
  ${UserDetailsFragment}
`

const REQUEST_PW_RESET = gql`
  mutation requestPasswordReset($email: String!, $recaptcha: String!) {
    requestPasswordReset(email: $email, recaptcha: $recaptcha)
  }
`

const RESET_PW = gql`
  mutation resetPassword(
    $password: String!
    $privateKey: String!
    $token: String!
  ) {
    resetPassword(password: $password, token: $token, privateKey: $privateKey)
  }
`

const GET_SESSION_LOG = gql`
  query ($sessionId: ID!) {
    sessionLog(sessionId: $sessionId) {
      t
      text
    }
  }
`

const LOG_SESSION_EVENT = gql`
  mutation ($sessionId: ID!, $event: String!) {
    logSessionEvent(sessionId: $sessionId, event: $event)
  }
`

const SET_INVOICE_ADDRESS = gql`
  mutation setInvoiceAddress($input: InvoiceAddressInput!) {
    setInvoiceAddress(input: $input)
  }
`

const SET_PLAN = gql`
  mutation setPlan($plan: Plan!) {
    setPlan(plan: $plan)
  }
`

const GET_2FA_INFO = gql`
  query {
    getTwoFactorAuthKey {
      key
      uri
    }
  }
`

const VERIFY_2FA_CODE = gql`
  mutation verifyTwoFactorAuthCode($code: String!) {
    verifyTwoFactorAuthCode(code: $code)
  }
`

const SET_2FA = gql`
  mutation setTwoFactorAuth($input: TwoFactorAuthInput!) {
    setTwoFactorAuth(input: $input)
  }
`

const GENERATE_RECOVERY_KEY = gql`
  mutation generateRecoveryKey($password: String!) {
    generateRecoveryKey(password: $password) {
      privateKey
      keyId
      status
    }
  }
`

const ACTIVATE_RECOVERY_KEY = gql`
  mutation activateRecoveryKey($password: String!, $keyId: ID!) {
    activateRecoveryKey(password: $password, keyId: $keyId)
  }
`

const RESET_MEMBER_PASSWORD = gql`
  mutation resetMemberPassword($userId: ID!, $organizationId: ID!) {
    resetMemberPassword(userId: $userId, organizationId: $organizationId)
  }
`

export {
  GET_SESSIONS,
  GET_SESSION,
  GET_RISKS,
  CREATE_SESSION,
  DELETE_SESSION,
  UPDATE_SESSION,
  CLEANUP_RISKS,
  CLONE_SESSION,
  ADD_RISK,
  ADD_RISKS,
  DELETE_RISK,
  GET_ACTIONS,
  MY_ACTIONS,
  ADD_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  GET_SESSION_LOG,
  SESSION_LOG_ENTRIES_ADDED,
  LOG_SESSION_EVENT,
  ADD_PARTICIPANT,
  DELETE_PARTICIPANTS,
  UPDATE_PARTICIPANT_STATUS,
  INVITE_PARTICIPANTS,
  INVITE_PARTICIPANT,
  SESSION_CHANGED,
  SESSION_CREATED,
  SESSION_DELETED,
  SESSION_PARTICIPANT_ADDED,
  SESSION_PARTICIPANTS_REMOVED,
  UPDATE_RISK,
  CURRENT_USER,
  TOKEN_USER,
  LOGIN,
  LOGOUT,
  REGISTER_USER,
  CHANGE_PASSWORD,
  CHANGE_USERNAME,
  ORGANIZATION,
  GET_PARTICIPANTS,
  SEND_ACCOUNT_VERIFICATION,
  VERIFY_ACCOUNT,
  REQUEST_PW_RESET,
  RESET_PW,
  USER_CHANGED,
  SET_INVOICE_ADDRESS,
  SET_PLAN,
  GET_2FA_INFO,
  VERIFY_2FA_CODE,
  SET_2FA,
  GENERATE_RECOVERY_KEY,
  ACTIVATE_RECOVERY_KEY,
  RISK_CHANGED,
  RISK_CREATED,
  RISK_DELETED,
  RISKS_IMPORTED,
  ACTION_CHANGED,
  ACTION_CREATED,
  ACTION_DELETED,
  ACTION_ASSIGNED,
  ORGANIZATION_MEMBERS,
  RESET_MEMBER_PASSWORD
}
