import { useState } from 'react'
import { useMutation } from '@apollo/client'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

import { validate as validate2FACode } from '../twoFactorAuth'
import { VERIFY_2FA_CODE } from '../graphql'

import TwoFactorCodeInput from '../components/TwoFactorCodeInput'

const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: '180px',
      height: '80px',
    },
    button: {
      marginTop: theme.spacing(1.125),
      marginLeft: theme.spacing(2),
    },
  }
})

const TwoFactorCodeValidation = ({ authKey, disabled, onVerified }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState()
  const classes = useStyles()

  const [verifyTwoFactorAuthCode, { loading }] = useMutation(VERIFY_2FA_CODE, {
    onCompleted: (data) => {
      if (data.verifyTwoFactorAuthCode) {
        onVerified && onVerified()
      } else {
        setError('Invalid or expired code')
      }
    },
  })

  const handleCodeChange = (evt) => {
    setCode(evt.target.value)
    setError()
  }

  const handleClickVerify = () => {
    const cleanCode = code.replace(/ /g, '')
    if (authKey) {
      const isValid = validate2FACode(cleanCode, authKey)
      if (!isValid) {
        setError('Invalid or expired code')
      } else {
        onVerified && onVerified()
      }
    } else {
      verifyTwoFactorAuthCode({ variables: { code: cleanCode } })
    }
  }

  return (
    <Box display='flex' alignItems='flex-start'>
      <TwoFactorCodeInput
        error={error !== undefined}
        helperText={error}
        className={classes.input}
        disabled={disabled || loading}
        value={code}
        onChange={handleCodeChange}
        onDone={handleClickVerify}
        variant='outlined'
      />
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        disabled={disabled || code.length < 7 || loading || error !== undefined}
        onClick={handleClickVerify}
      >
        Validate
      </Button>
    </Box>
  )
}

export default TwoFactorCodeValidation
