import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => {
  return {
    table: {
      borderSpacing: '1px',
    },
    cell: ({ cellSize }) => ({
      borderRadius: '2px',
      width: `${cellSize}px`,
      height: `${cellSize}px`,
    }),
  }
})

const MatrixPreview = ({ matrix, size, ...props }) => {
  const maxDim = Math.max(matrix?.size?.x, matrix?.size?.y)
  const cellSize = size ? Math.floor(size / maxDim) - 2 : 30

  const classes = useStyles({ cellSize })

  return (
    <table className={classes.table} {...props}>
      <tbody>
        {matrix.cells
          .slice()
          .reverse()
          .map((row, y) => {
            return (
              <tr key={`mp-${y}`}>
                {row.map((cell, x) => (
                  <td
                    className={classes.cell}
                    key={`matrix-cell-${x}-${y}`}
                    data-x={x}
                    data-y={Math.abs(y - matrix.size.y + 1)}
                    style={{
                      backgroundColor: `${cell.color}`,
                      borderColor: `${cell.color}`,
                    }}
                  ></td>
                ))}
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default MatrixPreview
