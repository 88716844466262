import { useState } from 'react'
import ChipInput from 'material-ui-chip-input'
import { isValidEmail } from '../util'

const ParticipantsInput = ({ classes, ...otherProps }) => {
  const [showError, setShowError] = useState(false)

  const handleBeforeAdd = (value) => {
    if (isValidEmail(value)) {
      return true
    }
    value && setShowError(true)
    return false
  }

  const handleUpdateInput = (evt) => {
    const value = evt.target.value
    if ((showError && isValidEmail(value)) || !value) {
      setShowError(false)
    }
  }

  return (
    <ChipInput
      label={'Participants'}
      fullWidth
      fullWidthInput
      onBeforeAdd={handleBeforeAdd}
      onUpdateInput={handleUpdateInput}
      placeholder={'Email address'}
      error={showError}
      helperText={showError && 'Invalid email address'}
      newChipKeyCodes={[9, 13, 32, 188]}
      InputProps={{
        id: 'participant-input',
        type: 'email',
        ...otherProps.InputProps,
      }}
      {...otherProps}
    />
  )
}

export default ParticipantsInput
