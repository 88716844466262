import { useMutation, useQuery, useSubscription } from '@apollo/client'
import {
  ADD_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  GET_ACTIONS,
  ACTION_CHANGED,
  ACTION_CREATED,
  ACTION_DELETED,
} from '../graphql'

const useActions = (sessionId, matrix) => {
  const { data: { actions } = { actions: [] }, loading } = useQuery(
    GET_ACTIONS,
    {
      skip: !sessionId,
      variables: { sessionId },
    }
  )

  useSubscription(ACTION_CHANGED, {
    variables: { sessionId },
    skip: !sessionId || loading,
  })

  useSubscription(ACTION_CREATED, {
    variables: { sessionId },
    skip: !sessionId || loading,
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { actionCreated: action },
      },
    }) => {
      const data = client.readQuery({
        query: GET_ACTIONS,
        variables: { sessionId },
      })
      const exists = data.actions.find((a) => a._id === action._id)
      if (exists) {
        return
      }
      client.writeQuery({
        query: GET_ACTIONS,
        data: {
          actions: [...data.actions, action],
        },
        variables: { sessionId },
      })
    },
  })
  useSubscription(ACTION_DELETED, {
    variables: { sessionId },
    skip: !sessionId || loading,
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { actionDeleted },
      },
    }) => {
      const data = client.readQuery({
        query: GET_ACTIONS,
        variables: { sessionId },
      })
      client.writeQuery({
        query: GET_ACTIONS,
        variables: { sessionId },
        data: {
          actions: data.actions.filter((a) => a._id !== actionDeleted),
        },
      })
    },
  })

  const [addAction] = useMutation(ADD_ACTION, { ignoreResults: true })
  const [updateAction] = useMutation(UPDATE_ACTION, { ignoreResults: true })
  const [deleteAction] = useMutation(DELETE_ACTION, { ignoreResults: true })

  return [actions, { addAction, updateAction, deleteAction }]
}

export default useActions
