import { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import Lock from '@material-ui/icons/Lock'

import { CHANGE_PASSWORD } from '../graphql'

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      color: 'grey',
      marginRight: theme.spacing(1),
    },
    marginBottomSmall: {
      marginBottom: '0.25rem',
    },
    input: {
      width: '20rem',
      minHeight: '4.25rem',
    },
  }
})

const ChangePasswordDialog = (props) => {
  const { open, onClose } = props
  const classes = useStyles()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const confirmPasswordInput = useRef(null)

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      const result = {}
      if (data.changePassword) {
        result.status = 'success'
        result.message = 'Password successfully changed!'
      } else {
        result.status = 'error'
        result.message = 'There was an error changing your password'
      }
      handleClose(null, result)
    },
    ignoreResults: true,
  })

  const handleChangePassword = (event) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (confirmPassword) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match')
      } else if (confirmPasswordError) {
        setConfirmPasswordError('')
      }
    }
    setPasswordError(error)
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && confirmPasswordInput.current) {
      confirmPasswordInput.current.focus()
    }
  }

  const handleChangeConfirmPassword = (event) => {
    const newPassword = event.target.value
    setConfirmPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (newPassword) {
      if (newPassword !== password) {
        error = 'Passwords do not match'
      }
    }
    setConfirmPasswordError(error)
  }

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword)
  }

  const handleClose = (evt, result) => {
    if (onClose) {
      setPassword('')
      setConfirmPassword('')
      onClose(
        result !== 'backdropClick' && result !== 'escapeKeyDown' && result
          ? result
          : undefined
      )
    }
  }

  const canSubmit = () => {
    return (
      isValidPassword(password) &&
      isValidPassword(confirmPassword) &&
      password === confirmPassword
    )
  }

  const isValidPassword = (password) => {
    return password && password !== 'kuk'
  }

  const handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()
    changePassword({ variables: { password } })
  }

  const handleConfirmPasswordKeyPress = (e) => {
    if (e.key === 'Enter' && canSubmit()) {
      handleSubmit(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Change password</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          alignItems='center'
          className={classes.marginBottomSmall}
        >
          <Grid item>
            <Lock className={classes.icon} />
          </Grid>
          <Grid item>
            <FormControl
              required
              error={Boolean(passwordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='password'>New password</InputLabel>
              <Input
                id='password'
                type={showPassword ? 'text' : 'password'}
                autoFocus
                value={password}
                onChange={handleChangePassword}
                onKeyPress={handlePasswordKeyPress}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordError && (
                <FormHelperText>{passwordError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <Lock className={classes.icon} />
          </Grid>
          <Grid item>
            <FormControl
              required
              error={Boolean(confirmPasswordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='confirmpassword'>
                Confirm new password
              </InputLabel>
              <Input
                id='confirmpassword'
                inputRef={confirmPasswordInput}
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                onKeyPress={handleConfirmPasswordKeyPress}
              />
              {confirmPasswordError && (
                <FormHelperText>{confirmPasswordError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!canSubmit()}
          variant='contained'
          color='primary'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePasswordDialog
