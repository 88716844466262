import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import UserLabel from './UserLabel'

const useStyles = makeStyles((theme) => {
  return {
    label: {
      color: 'grey',
      fontSize: '0.75rem',
      paddingLeft: theme.spacing(1),
    },
  }
})

const UserSelect = ({ className, label, ...otherProps }) => {
  const classes = useStyles()
  return (
    <Box className={classes.className}>
      <Typography variant='body1' component='label' className={classes.label}>
        {label}
      </Typography>
      <UserLabel {...otherProps} />
    </Box>
  )
}

export default UserSelect
