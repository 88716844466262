import { useRef, useState } from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { useLocation, Navigate } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as Logo } from '../svg/logo.svg'
import ProgressButton from './ProgressButton'

import { LOGIN } from '../graphql'

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: 140,
      height: 'auto',
      marginBottom: '1rem',
    },
    paper: {
      padding: '2rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '12rem',
      justifyContent: 'flex-start',
    },
    input: {
      marginBottom: '1rem',
    },
    emailInput: {
      textTransform: 'lowercase',
    },
    button: {
      marginTop: '2rem',
      alignSelf: 'flex-end',
    },
  }
})

const Login = () => {
  const classes = useStyles()
  const location = useLocation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const passwordInput = useRef(null)

  const client = useApolloClient()
  const [login, { data, loading, error }] = useMutation(LOGIN)

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleChangeUsername = (e) => {
    setUsername(e.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleUsernameKeyPress = (e) => {
    if (e.key === 'Enter' && passwordInput.current) {
      passwordInput.current.focus()
    }
  }

  const handleClickLogin = (e) => {
    e.stopPropagation()
    e.preventDefault()
    login({
      variables: { username, password },
      update: (store, { data: { login } }) => {
        client.resetStore()
      },
    })
  }

  const canSubmit = () => {
    return !loading && username && password
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && canSubmit()) {
      handleClickLogin(e)
    }
  }

  if (error) {
    console.log('error', JSON.stringify(error))
  }

  if (data && data.login.user) {
    const referrer = location?.state?.referrer
    if (data.login.user.status === 'pendingVerification') {
      return (
        <Navigate
          to={{
            pathname: '/verifyAccount',
            state: {
              user: data.login.user,
            },
          }}
        />
      )
    } else if (data.login.status === 'ok') {
      return <Navigate to={referrer || '/riskassessments'} />
    } else if (data.login.status === 'mfaRequired') {
      return (
        <Navigate
          to={{
            pathname: '/login/2fa',
            state: {
              user: data.login.user,
              referrer,
            },
          }}
        />
      )
    }
  }

  const userNotFound = data && data.login.status === 'userNotFound'
  const invalidPassword = data && data.login.status === 'invalidPassword'

  return (
    <Paper className={classes.paper}>
      <Logo className={classes.logo} />
      <form className={classes.form}>
        <FormControl error={userNotFound} className={classes.input}>
          <InputLabel htmlFor='email'>Email</InputLabel>
          <Input
            id='email'
            type={'email'}
            autoFocus
            value={username}
            onChange={handleChangeUsername}
            onKeyPress={handleUsernameKeyPress}
            classes={{ input: classes.emailInput }}
          />
          {userNotFound && (
            <FormHelperText id='component-error-text'>
              User not found
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={invalidPassword} className={classes.input}>
          <InputLabel htmlFor='password'>Password</InputLabel>
          <Input
            id='password'
            inputRef={passwordInput}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            onKeyPress={handlePasswordKeyPress}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {invalidPassword && (
            <FormHelperText id='component-error-text'>
              Invalid password
            </FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <ProgressButton
            disabled={!canSubmit()}
            loading={loading}
            classes={{ root: classes.button }}
            variant='contained'
            color='primary'
            onClick={handleClickLogin}
          >
            LOGIN
          </ProgressButton>
        </FormControl>
      </form>
    </Paper>
  )
}

export default Login
