import { authenticator } from 'otplib'

authenticator.options = { window: 2 }

const generateSecret = () => {
  return authenticator.generateSecret()
}

const getUri = (account, issuer, secret) => {
  return authenticator.keyuri(account, issuer, secret)
}

const validate = (token, secret) => {
  return authenticator.check(token, secret)
}

export { generateSecret, getUri, validate }
