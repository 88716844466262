CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
  if (w < 2 * r) r = w / 2
  if (h < 2 * r) r = h / 2
  this.beginPath()
  this.moveTo(x + r, y)
  this.arcTo(x + w, y, x + w, y + h, r)
  this.arcTo(x + w, y + h, x, y + h, r)
  this.arcTo(x, y + h, x, y, r)
  this.arcTo(x, y, x + w, y, r)
  this.closePath()
  return this
}

const drawMatrix = (
  ctx,
  matrix,
  xPos,
  yPos,
  cellSize,
  indicatorSize,
  risks
) => {
  const width = matrix.size.x * cellSize + matrix.size.x - 1
  const height = matrix.size.y * cellSize + matrix.size.y - 1
  ctx.fillStyle = 'white'
  ctx.fillRect(xPos, 0, width, height)

  matrix.cells.forEach((row, y) => {
    row.forEach((cell, x) => {
      ctx.fillStyle = cell.color
      const cellX = x * cellSize + x
      const cellY = height - (y * cellSize + y) - cellSize
      ctx.roundRect(cellX + xPos, cellY + yPos, cellSize, cellSize, 2).fill()
    })
  })

  for (let y = 0; y < matrix.size.y; y++) {
    for (let x = 0; x < matrix.size.x; x++) {
      const cellX = x * cellSize + x
      const cellY = height - (y * cellSize + y) - cellSize
      for (const r of risks) {
        if (!r.position) {
          continue
        }
        const pos = r.position
        if (Math.trunc(pos.x) === x && Math.trunc(pos.y) === y) {
          const aX = (pos.x % 1) * cellSize
          const aY = cellSize - (pos.y % 1) * cellSize
          ctx.beginPath()
          ctx.arc(
            cellX + aX + xPos,
            cellY + aY + yPos,
            indicatorSize,
            0,
            2 * Math.PI,
            false
          )
          ctx.fillStyle = 'white'
          ctx.fill()
          ctx.lineWidth = 3
          ctx.strokeStyle = 'black'
          ctx.stroke()
          ctx.closePath()
          ctx.font = 'bold 24px serif'
          ctx.fillStyle = 'black'
          ctx.textAlign = 'center'
          ctx.fillText(
            `${r.identifier}`,
            cellX + aX + xPos,
            cellY + aY + 8 + yPos
          )
        }
      }
    }
  }
}

const drawRiskColor = (ctx, size, color) => {
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, size, size)

  ctx.fillStyle = color
  ctx.roundRect(0, 0, size, size, Math.trunc(size / 10)).fill()
}

const drawAxis = (ctx, x, y, w, h, color, text, vertical) => {
  const arrowSize = 15
  ctx.fillStyle = color
  ctx.lineWidth = 2
  ctx.font = '28px Courier'
  ctx.textAlign = 'left'
  var textHeight = ctx.measureText('M').width
  if (vertical) {
    ctx.beginPath()
    ctx.moveTo(x + w - arrowSize / 2, y + h)
    ctx.lineTo(x + w - arrowSize / 2, y)
    ctx.lineTo(x + w, y + arrowSize)
    ctx.stroke()
    ctx.beginPath()
    ctx.moveTo(x + w - arrowSize / 2, y)
    ctx.lineTo(x + w - arrowSize, y + arrowSize)
    ctx.stroke()
    ctx.save()
    ctx.translate(x + w - arrowSize * 2, y + h)
    ctx.rotate(-Math.PI / 2)
    ctx.fillText(text, 0, 0)
    ctx.restore()
  } else {
    ctx.beginPath()
    ctx.moveTo(x, y + arrowSize / 2)
    ctx.lineTo(x + w, y + arrowSize / 2)
    ctx.lineTo(x + w - arrowSize, y)
    ctx.stroke()
    ctx.beginPath()
    ctx.moveTo(x + w, y + arrowSize / 2)
    ctx.lineTo(x + w - arrowSize, y + arrowSize)
    ctx.stroke()
    ctx.fillText(text, x, y + arrowSize * 2 + textHeight)
  }
}

const drawNumbers = (ctx, matrix, xPos, yPos, cellSize, vertical) => {
  const width = vertical ? 40 : matrix.size.x * cellSize + matrix.size.x - 1
  const height = vertical ? matrix.size.y * cellSize + matrix.size.y - 1 : 40

  ctx.fillStyle = 'white'
  ctx.fillRect(xPos, yPos, width, height)
  ctx.fillStyle = 'black'
  ctx.font = '28px sans-serif'
  ctx.textAlign = 'center'
  var textHeight = ctx.measureText('M').width
  if (vertical) {
    for (let y = 0; y < matrix.size.y; y++) {
      ctx.fillText(
        `${matrix.size.y - y}`,
        xPos + width / 2,
        cellSize * y + 2 * y + yPos + cellSize / 2 + textHeight / 2
      )
    }
  } else {
    for (let x = 0; x < matrix.size.x; x++) {
      ctx.fillText(
        `${x + 1}`,
        cellSize * x + x + xPos + cellSize / 2,
        height / 2 + yPos
      )
    }
  }
}

export { drawMatrix, drawRiskColor, drawAxis, drawNumbers }
