import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import ReCAPTCHA from 'react-google-recaptcha'

import { isValidEmail } from '../util'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: '14rem',
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.primary.dark,
    },
    contentText: {
      marginBottom: '1rem',
    },
    dialogContent: {
      padding: 0,
    },
    input: {
      width: '100%',
      height: '5rem',
    },
    recaptcha: {
      marginTop: theme.spacing(1),
    },
  }
})

const ConfirmEmailDialog = (props) => {
  const [email, setEmail] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState()
  const [error, setError] = useState('')

  const { open, title, content, onClose } = props
  const classes = useStyles()

  const handleClose = (evt) => {
    setEmail('')
    setError('')
    if (onClose) {
      onClose()
    }
  }

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    if (!isValidEmail(newEmail)) {
      setError('Invalid email address')
    } else {
      setError('')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && canSubmit()) {
      handleSendClicked()
    }
  }

  const handleSendClicked = (e) => {
    setEmail('')
    setError('')
    if (onClose) {
      onClose(email, captchaResponse)
    }
  }

  const handleReCAPTCHAChanged = (response) => {
    setCaptchaResponse(response)
  }

  const canSubmit = () => {
    return error === '' && isValidEmail(email) && captchaResponse
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText className={classes.contentText}>
          {content}
        </DialogContentText>
        <FormControl error={error !== ''} className={classes.input}>
          <InputLabel htmlFor='Email'>Email address</InputLabel>
          <Input
            id='Email'
            type={'email'}
            value={email}
            autoFocus
            onChange={handleChangeEmail}
            onKeyPress={handleKeyPress}
            onFocus={(e) => {
              setError('')
            }}
          />
          {error && (
            <FormHelperText id='component-error-text'>{error}</FormHelperText>
          )}
        </FormControl>
        <ReCAPTCHA
          className={classes.recaptcha}
          sitekey={window._RA_RECAPTCHA_KEY}
          onChange={handleReCAPTCHAChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit()}
          className={classes.button}
          onClick={handleSendClicked}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmEmailDialog
