import { useEffect } from 'react'
import { useParams, useNavigationType, Navigate, Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      marginBottom: theme.spacing(1),
    },
    section: {
      marginTop: theme.spacing(2),
    },
    page: {
      '& > h4 ~ p, h6 ~ p': {
        marginTop: theme.spacing(1),
      },
    },
  }
})

const PoliciesPage = () => {
  const classes = useStyles()

  const navigationType = useNavigationType()
  const params = useParams()
  const policy = params.policy

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [navigationType])

  const cookiePolicy = () => (
    <div className={classes.page} style={{ padding: '32px' }}>
      <Typography variant='h4' className={classes.heading}>
        Cookies Policy
      </Typography>
      <Typography variant='body1'>
        riskace.com uses cookies. By continuing to use the website you
        approve our use of cookies. We use cookies to authenticate and to
        improve your experience when using our website.
      </Typography>
      <Typography variant='body1'>
        A cookie is a small text file that is saved on your computer or
        mobile device when you visit the site.
      </Typography>
      <Typography variant='body1'>
        Please see our privacy policy for more information about how we
        collect and store data.
      </Typography>
    </div>
  )

  const terms = () => (
    <div className={classes.page} style={{ padding: '32px' }}>
      <Typography variant='h4' className={classes.heading}>
        Terms and Conditions
      </Typography>
      <Typography variant='h6'>Introduction</Typography>
      <Typography variant='body1'>
        These Terms and Conditions outline the rules and regulations for
        the use of RiskAce's Website, located at riskace.com.
      </Typography>
      <Typography paragraph variant='body1'>
        By registering at this Website, you agreed to accept all terms and
        conditions defined in this document. You must not use this Website
        if you disagree with any of these Terms and Conditions.
      </Typography>
      <Typography variant='h6'>Intellectual Property Rights</Typography>
      <Typography paragraph variant='body1'>
        Unless otherwise stated, RiskAce and/or its licensors own the
        intellectual property rights for all material on riskace.com. All
        intellectual property rights are reserved. You may access
        riskace.com for your own use subjected to restrictions set in
        these Terms and Conditions.
      </Typography>
      <Typography variant='h6'>Restrictions</Typography>
      <Typography paragraph variant='body1'>
        You are specifically restricted from all of the following:
      </Typography>
      <Typography paragraph component='ul'>
        <li>
          Selling, renting or sub-licensing material from riskace.com
        </li>
        <li>
          Republishing material from riskace.com, with the exception of
          designated reports and data exports.
        </li>
        <li>
          Reproducing, duplicating or copying material from riskace.com,
          with the exception of designated reports and data exports.
        </li>
        <li>
          Redistributing content from riskace.com, with the exception of
          designated reports and data exports.
        </li>
        <li>
          Using this Website in any way that is or may be damaging to this
          Website.
        </li>
        <li>
          Using this Website contrary to applicable laws and regulations,
          or in any way that may cause harm to the Website, or to any
          person or business entity.
        </li>
      </Typography>
      <Typography variant='h6'>Your Privacy</Typography>
      <Typography paragraph variant='body1'>
        Please consult our&nbsp;
        <Link
          color='inherit'
          underline='always'
          component={RouterLink}
          to={routes.PRIVACY_POLICY}
        >
          Privacy Policy
        </Link>
      </Typography>
      <Typography variant='h6'>Cookies</Typography>
      <Typography paragraph variant='body1'>
        We use cookies. By accessing riskace.com, you agreed to use
        cookies in agreement with the RiskAce's Privacy Policy. Cookies
        are used by our website to enable the functionality of certain
        areas to make it easier for people visiting our website. Some of
        our affiliate partners may also use cookies.
      </Typography>
      <Typography variant='h6'>No warranties</Typography>
      <Typography paragraph variant='body1'>
        This Website is provided “as is”, with all faults, and RiskAce
        expresses no representations or warranties, of any kind related to
        this Website.
      </Typography>
      <Typography variant='h6'>Limitation of liability</Typography>
      <Typography paragraph variant='body1'>
        In no event shall RiskAce, nor any of its directors and employees,
        be held liable for anything arising out of, or in any way
        connected with your use of this Website.
      </Typography>
      <Typography variant='h6'>Indemnification</Typography>
      <Typography paragraph variant='body1'>
        You hereby indemnify, to the fullest, RiskAce from and against any
        and/or all liabilities, costs, demands, causes of action, damages
        and expenses arising in any way related to your breach of any of
        the provisions of these Terms.
      </Typography>
      <Typography variant='h6'>Severability</Typography>
      <Typography paragraph variant='body1'>
        If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting
        the remaining provisions herein.
      </Typography>
      <Typography variant='h6'>Variation of Terms</Typography>
      <Typography paragraph variant='body1'>
        RiskAce is permitted to revise these Terms at any time as it sees
        fit, and by using this Website you are expected to review these
        Terms on a regular basis.
      </Typography>
      <Typography variant='h6'>Governing Law and Jurisdiction</Typography>
      <Typography paragraph variant='body1'>
        These Terms will be governed by and interpreted in accordance with
        the laws of Sweden and be settled exclusively by Swedish courts,
        with the Stockholm District Court (Stockholms tingsrätt) as the
        court of first instance.
      </Typography>
    </div>
  )

  const privacy = () => (
    <div className={classes.page} style={{ padding: '32px' }}>
      <Typography variant='h4' className={classes.heading}>
        Privacy Policy
      </Typography>
      <Typography variant='body1'>
        Below is a comprehensive description of Compliance Tech AB’s
        (Compliance Tech) processing of personal information.
      </Typography>
      <Typography variant='body1'>
        Compliance Tech is based in Sweden, Europe, and your personal
        information is collected and processed in accordance with Swedish
        privacy laws and EU Directive 2016/679 (GDPR) on data protection.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        What personal data is processed and why?
      </Typography>
      <Typography variant='body1'>
        The personal information processed by Compliance Tech is obtained
        directly from you in connection with registering for the service
        and the use of Compliance Tech’s products or through other
        contacts with Compliance Tech.
      </Typography>
      <Typography variant='body1'>
        The personal data processed is information that is necessary to
        create a user account such as name, email address and password.
        Compliance Tech also processes online identifiers such as IP
        addresses, or the equivalent provided by website visitors.
      </Typography>
      <Typography variant='body1'>
        If you do not provide personal information that Compliance Tech
        requests and such personal information is necessary for the
        closure of an agreement with Compliance Tech, the service from
        Compliance Tech cannot be provided to you. In addition, the
        obligation to provide personal data may also exist as a result of
        statutory requirements.
      </Typography>
      <Typography variant='body1'>
        The purpose of the processing of your personal data is for
        Compliance Tech to be able to fulfil its obligations and exercise
        its rights under an agreement, and to take measures requested by
        you before or after an agreement has been closed and to fulfil
        requirements set by Compliance Tech’s activities according to law.
        Compliance Tech also processes personal data for marketing
        purposes, statistics, market and customer analysis and for product
        development. In addition, Compliance Tech carries out profiling,
        which involves analysing your personal data for marketing
        purposes.
      </Typography>
      <Typography variant='body1'>
        The legal basis for the processing of personal data as described
        above is that the processing is necessary to fulfil agreements in
        which you are a party or to take action upon your request before
        such an agreement is closed. In addition, the legal basis for the
        processing of personal data constitutes a balance of interests, in
        which Compliance Tech’s legitimate interests comprise, for
        example, marketing, analysis and product development. Additional
        applicable purposes and legal bases may be specified in applicable
        terms of use.
      </Typography>
      <Typography variant='body1'>
        Your personal data will not be stored for longer than is necessary
        for the purposes for which the personal data is processed. This
        means, for example, that personal data is stored as long as
        agreements, other commitments or legal claims regarding you and
        Compliance Tech apply. In addition, personal data can be stored
        for marketing purposes, statistics, market and customer analysis
        and for product development for an appropriate period regardless
        of contractual relationship. Where applicable, other storage
        periods will be stated in the applicable terms of use.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Is your personal information disclosed?
      </Typography>
      <Typography variant='body1'>
        Personal data may be disclosed to others such as companies with
        which Compliance Tech cooperates, within and outside the EU and
        EEA, and to authorities, if Compliance Tech is required to do so
        by law. If Compliance Tech discloses your information to
        recipients outside the EU and EEA, Compliance Tech will take
        appropriate measures to ensure that your rights and choices are
        exercised, such as through the conclusion of standardized data
        protection agreements. A copy of documents relating to such
        measures may be obtained upon request to Compliance Tech.
        Compliance Tech does not sell information about customers or other
        users to any third party.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        How is your personal information kept secure?
      </Typography>
      <Typography variant='body1'>
        You control at all times who can see your personal information
        when using Compliance Tech’s products.
      </Typography>
      <Typography variant='body1'>
        Personal information such as name and e-mail are not visible to
        other users of Compliance Tech’s products. Your email address is
        only visible to the users you have specifically shared the
        information with when using the product. All your personal
        information is encrypted both at rest in a database and in transit
        between your web client and the service.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Your rights as a customer
      </Typography>
      <Typography variant='body1'>
        If your personal information is inaccurate or incomplete, you have
        the right to request adjustment of it. You also have the right, in
        some cases, to request that personal data be deleted or that the
        use of personal data be restricted. You also have the right to
        certain information regarding ongoing processing of your personal
        data as well as access to such personal data. Upon request, you
        have the right to receive and the right to transfer some of your
        personal data to another data controller, so-called data
        portability. You have the right to object to the processing of
        personal data for direct marketing purposes, profiling and
        processing of personal data based on a balance of interests. The
        request as above must be made to Compliance Tech.
      </Typography>
      <Typography variant='body1'>
        If you believe that we have made a mistake in your personal data,
        you can make a complaint to The Swedish Authority for Privacy
        Protection (www.imy.se).
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Data controller
      </Typography>
      <Typography variant='body1'>
        Compliance Tech is the data controller of personal data provided
        by you. Compliance Tech contact details are:
      </Typography>
      <Typography variant='body1'>
        &emsp;Compliance Tech AB
        <br />
        &emsp;Fasanvägen 31b
        <br />
        &emsp;SE-192 55 Sollentuna
        <br />
        &emsp;Sweden
        <br />
        &emsp;info@riskace.com
      </Typography>
      <Typography variant='body1'>
        If you have any questions or concerns regarding Compliance Tech
        use of your information that are not answered in this Policy,
        please feel free to email info@riskace.com.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Changes to the Privacy Policy
      </Typography>
      <Typography variant='body1'>
        Compliance Tech may from time to time and in its own discretion
        make changes to this Privacy Policy. The date of last modification
        is stated at the start of this document. It is your responsibility
        to check the current version of the User & Privacy Policy from
        time to time for updates.
      </Typography>
      <Typography variant='body1'>
        If and when Compliance Tech revises the User & Privacy Policy,
        Compliance Tech will inform you through the Service. You will have
        no obligation to continue using the Service following any such
        notification, but if you continue to use the Service after said
        notification it will constitute your acceptance of the changes.
      </Typography>
    </div>
  )

  const security = () => (
    <div style={{ padding: '32px' }}>
      <Typography variant='h4' className={classes.heading}>
        Data Security Statement
      </Typography>
      <Typography variant='body1'>
        RiskAce is dedicated to protecting all Customer data at all means,
        implementing high-end data protection mechanisms to ensure your
        data is at all times secure and available. This statement
        describes how your data is processed, stored and handled.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Data Processing
      </Typography>
      <Typography variant='body1'>
        Your data is at all times protected with industry standard
        encryption. All processing of data is handled with an encrypted
        connection between a web or mobile client and our web servers. We
        always support the latest encryption standards, defaulting to TLS
        1.2. No unencrypted calls can not be made to the web server,
        ensuring no eavesdropping of your data processing is possible.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        User Authentication
      </Typography>
      <Typography variant='body1'>
        You are authenticated by a username and password combination. A
        private password is created during registration and only visible
        to you. When registering, you also receive a password reset key.
        This key is needed to reset your password if you happen to forget
        it.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Storage Security
      </Typography>
      <Typography variant='body1'>
        All data is stored encrypted to your password, meaning that
        support staff at RiskAce, nor anyone else not having access to
        your password will be able to see any of your data. Should you
        lose access to both your password and your reset key, a documented
        manual key reset process will be initiated involving two
        administrators jointly assembling the master recovery key and
        resetting your user encryption keys.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Availability
      </Typography>
      <Typography variant='body1'>
        The service and your data is hosted at Google Cloud within EU.
        Service uptime is 99.99%.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Software Development Practices
      </Typography>
      <Typography variant='body1'>
        Our engineers follow best practices and industry-standard coding
        guidelines to ensure secure program code. The source code is
        reviewed according to OWASP code review methodology.
      </Typography>
      <Typography variant='body1' className={classes.section}>
        The application is regularly penetration tested for coding
        mistakes or design flaws.
      </Typography>
      <Typography variant='body1' className={classes.section}>
        All changes in production environment are handled according to the
        change management process, ensuring minimal downtime for our
        customers and ensuring the quality of our service.
      </Typography>
      <Typography variant='h6' className={classes.section}>
        Handling of Security Incidents
      </Typography>
      <Typography variant='body1'>
        Despite best efforts, no method of transmission over the Internet
        and no method of electronic storage is perfectly secure. We cannot
        guarantee absolute security, although we do everything in our
        power to minimize the risk for security related incidents.
      </Typography>
      <Typography variant='body1' className={classes.section}>
        If an incident occurs, it is handled according to our incident
        handling process, ensuring adequate and timely remedy of the
        issue, as well as communication with our customers affected by the
        incident.
      </Typography>
    </div>
  )

  switch (policy) {
    case 'cookies':
      return cookiePolicy()
    case 'terms':
      return terms()
    case 'privacy':
      return privacy()
    case 'security':
      return security()
    default:
      return <Navigate to={routes.MAIN} />
  }
}

export default PoliciesPage
