import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: theme.shape.borderRadius,
    },
    icon: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
    input: {
      paddingLeft: theme.spacing(1),
      fontSize: theme.typography.body2.fontSize,
    },
  }
})

const SearchInput = ({ classes, placeholder, InputProps, onChange }) => {
  const [value, setValue] = useState('')

  const _setValue = (newValue) => {
    if (value !== newValue) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  const handleChange = (e) => {
    _setValue(e.target.value)
  }

  const handleClear = (e) => {
    _setValue('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      _setValue('')
    }
  }

  const _classes = useStyles()

  let rootClass = _classes.root
  if (classes && classes.root) {
    rootClass += ` ${classes.root}`
  }

  return (
    <Box className={rootClass} display='flex' alignItems='center'>
      <TextField
        placeholder={placeholder || 'Filter...'}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
        InputProps={{
          classes: { input: _classes.input },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position='end'>
              <Box>
                <IconButton size='small' onClick={handleClear}>
                  {value ? <CloseIcon className={_classes.icon} /> : <SearchIcon className={_classes.icon} />}
                </IconButton>
              </Box>
            </InputAdornment>
          ),
          ...InputProps
        }}
      />
    </Box>
  )
}

export { SearchInput }
