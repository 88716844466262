import { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CloseIcon from '@material-ui/icons/Close'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import InputField from './InputField'
import UserSelect from './UserSelect'
import UserMenu from './UserMenu'
import RiskMenu from './RiskMenu'

import { formatTimestamp } from '../util'

const useStyles = makeStyles((theme) => {
  return {
    buttonGrid: {
      alignSelf: 'flex-start',
    },
    descriptionInput: {
      marginTop: theme.typography.pxToRem(3.5),
    },
    dateLabel: {
      color: theme.palette.text.disabled,
    },
    actionContainer: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
    },
    assignToMe: {
      fontSize: '0.875rem',
      color: theme.palette.secondary.dark,
      marginLeft: theme.spacing(1),
    },
  }
})

const RiskDialog = (props) => {
  const {
    open,
    onClose,
    createMode,
    risk,
    user,
    users = [],
    currentOwner,
    canEdit,
    canChangeOwner,
    canClone,
    canDelete,
    onDelete,
    onClone,
    onAddAction,
    onSubmit,
  } = props
  const classes = useStyles()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showRiskMenu, setShowRiskMenu] = useState(false)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [ownerId, setOwnerId] = useState(undefined)

  const ownerRef = useRef(null)
  const moreRef = useRef(null)

  useEffect(() => {
    if (open) {
      setTitle(risk?.title ?? '')
      setDescription(risk?.description ?? '')
      setOwnerId(risk?.ownerId ?? null)
    }
  }, [open, risk])

  const getUser = () => {
    const _users = currentOwner ? [...users, currentOwner] : users
    return _users.find((u) => u._id === ownerId)
  }

  const handleClose = (evt, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    onClose && onClose()
  }

  const handleNameChange = (e) => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleOwnerClick = (value) => {
    setShowUserMenu(true)
  }

  const handleUserMenuClose = () => {
    setShowUserMenu(false)
  }

  const handleUserMenuSelect = (user) => {
    setOwnerId(user?._id ?? null)
    setShowUserMenu(false)
  }

  const handleAssignToMeClick = () => {
    setOwnerId(user?._id ?? null)
  }

  const handleMoreClick = () => {
    setShowRiskMenu(true)
  }

  const handleRiskMenuClose = () => {
    setShowRiskMenu(false)
  }

  const handleRiskClone = () => {
    setShowRiskMenu(false)
    onClone && onClone(risk)
  }

  const handleRiskDelete = () => {
    setShowRiskMenu(false)
    onDelete && onDelete(risk)
  }

  const handleRiskAddAction = () => {
    setShowRiskMenu(false)
    onAddAction && onAddAction(risk)
  }

  const handleSubmit = () => {
    const result = {}
    if (title !== risk.title || createMode) {
      result.title = title
    }
    if (description !== risk.description || createMode) {
      result.description = description
    }
    if (ownerId !== risk.ownerId || createMode) {
      result.ownerId = ownerId
    }
    Object.keys(result).length > 0 && onSubmit && onSubmit(result)
  }

  const canSubmit = () => {
    if (createMode) {
      return title !== ''
    }
    return (
      risk &&
      ((title && title !== risk.title) ||
        (description !== risk.description &&
          !(description === '' && risk.description === null)) ||
        risk.ownerId !== ownerId)
    )
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <Box p={1} position='relative'>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box pt={2}>
                <InputField
                  value={title}
                  onChange={handleNameChange}
                  fullWidth
                  multiline
                  maxRows={2}
                  disabled={!(canEdit || createMode)}
                  placeholder='Name...'
                  label='Name'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    autoFocus: !!createMode,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputField
                className={classes.descriptionInput}
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                multiline
                maxRows={8}
                disabled={!(canEdit || createMode)}
                placeholder={
                  canEdit === true ? 'Add a description...' : undefined
                }
                label='Description'
                minRows={10}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid ref={ownerRef} item xs={12} sm={4}>
              <UserSelect
                label={'Owner'}
                small
                disabled={!(canChangeOwner || createMode)}
                user={getUser()}
                onClick={handleOwnerClick}
              />
              <Link
                className={classes.assignToMe}
                component='button'
                disabled={risk?.ownerId === user?._id}
                onClick={handleAssignToMeClick}
              >
                Assign to me
              </Link>
              {!createMode && risk && (
                <Box mt={1} ml={0.75} display='flex' flexDirection='column'>
                  <Typography variant='caption' className={classes.dateLabel}>
                    {`Created ${formatTimestamp(risk.createdAt)}`}
                  </Typography>
                  <Typography variant='caption' className={classes.dateLabel}>
                    {`Updated ${formatTimestamp(risk.updatedAt)}`}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <DialogActions className={classes.actionContainer}>
          <Button
            disabled={!canSubmit()}
            onClick={handleSubmit}
            color='default'
          >
            {createMode ? 'Create' : 'Save'}
          </Button>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
        </DialogActions>
        <Box
          display='flex'
          justifyContent='flex-end'
          className={classes.btnContainer}
          position='absolute'
          top={'5px'}
          right={'5px'}
        >
          {!createMode && (
            <IconButton
              ref={moreRef}
              disabled={!(canEdit || canDelete || canClone)}
              onClick={handleMoreClick}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
      <UserMenu
        open={showUserMenu}
        showUnassigned={ownerId}
        users={users?.filter((u) => u._id !== ownerId) ?? []}
        anchorEl={ownerRef?.current}
        onClose={handleUserMenuClose}
        onSelect={handleUserMenuSelect}
      />
      <RiskMenu
        open={showRiskMenu}
        anchorEl={moreRef?.current}
        actions={['clone', 'delete']}
        canClone={canClone}
        canDelete={canDelete}
        canAddAction={false}
        onClose={handleRiskMenuClose}
        onClone={handleRiskClone}
        onDelete={handleRiskDelete}
        onAddAction={handleRiskAddAction}
      />
    </>
  )
}

export default RiskDialog
