import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import MaterialAvatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'

import Person from '@material-ui/icons/Person'
import PersonAdd from '@material-ui/icons/PersonAdd'

import { getInitials } from '../util'

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: '32px',
      height: '32px',
      fontSize: '0.875em',
      fontWeight: 500,
      cursor: 'pointer',
      '&.small': {
        width: '28px',
        height: '28px',
        fontSize: '0.75em',
      },
      '&.disabled': {
        cursor: 'default',
      },
      overflow: 'visible',
    },
    add: {
      width: '20px',
      height: '20px',
      '&.small': {
        width: '18px',
        height: '18px',
      },
    },
    selected: {
      border: `2px solid ${theme.palette.primary.dark}`,
    },
    online: {
      width: '10px',
      height: '10px',
      backgroundColor: 'green',
      borderRadius: '50%',
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }
})

const Avatar = ({
  className,
  user,
  add,
  defaultTooltip,
  small,
  selected,
  disabled,
  showStatus,
  onClick,
}) => {
  const classes = useStyles()
  const [toggle, setToggle] = useState()

  useEffect(() => {
    if (!showStatus) {
      return
    }
    const id = setInterval(() => {
      setToggle(!toggle)
    }, 5000)
    return () => clearInterval(id)
  }, [showStatus, user, toggle])

  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e, user)
    }
  }

  let cName = classes.avatar
  if (className) {
    cName += ` ${className}`
  }
  if (selected) {
    cName += ` ${classes.selected}`
  }
  if (small) {
    cName += ` small`
  }
  if (disabled) {
    cName += ` disabled`
  }

  const getTooltipTitle = () => {
    if (!user) {
      return defaultTooltip || 'Add user'
    }
    if (user.name) {
      return (
        <span>
          {user.name}
          <br />
          {user.email}
        </span>
      )
    }
    return user.email
  }

  const isActive = () => {
    if (!user) {
      return false
    }
    return (
      user.lastActiveAt && Date.now() - new Date(user.lastActiveAt) < 180000
    )
  }

  const getAvatar = () => {
    let avatarCName = classes.add
    if (small) {
      avatarCName += ' small'
    }
    return user ? (
      getInitials(user)
    ) : add ? (
      <PersonAdd className={avatarCName} />
    ) : (
      <Person className={avatarCName} />
    )
  }
  return (
    <Tooltip title={getTooltipTitle()}>
      <MaterialAvatar className={cName} onClick={handleClick}>
        {getAvatar()}
        {showStatus && isActive() && <div className={classes.online} />}
      </MaterialAvatar>
    </Tooltip>
  )
}

export default Avatar
