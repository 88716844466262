import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import MatrixPreview from './MatrixPreview'

const useStyles = makeStyles((theme) => ({
  matrixContainer: {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '3px',
    cursor: 'pointer',
  },
  selected: {
    border: `3px solid ${theme.palette.secondary.light}`,
    borderRadius: '3px',
  },
}))

const MatrixCard = ({ matrix, large, selected, onClick }) => {
  const classes = useStyles()

  return (
    <Box
      className={`${classes.matrixContainer}${
        selected ? ' ' + classes.selected : ''
      }`}
      height={large ? 164 : 132}
      width={large ? 164 : 132}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      padding={1}
      onClick={onClick}
    >
      <MatrixPreview size={large ? 132 : 100} matrix={matrix} />
      <Typography variant='caption' align='center'>
        {matrix?.name}
      </Typography>
    </Box>
  )
}

export default MatrixCard
