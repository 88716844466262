import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import CountrySelect from './CountrySelect'
import { isProvinceRequired } from '../countries'

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      width: '100%',
      minHeight: '4.3rem',
    },
  }
})

const InvoiceAddressDetails = ({
  firstName,
  lastName,
  companyName,
  streetAddress1,
  streetAddress2,
  country,
  city,
  province,
  postalCode,
  onChangeFirstName,
  onChangeLastName,
  onChangeCompanyName,
  onChangeStreetAddress1,
  onChangeStreetAddress2,
  onChangeCountry,
  onChangeCity,
  onChangeProvince,
  onChangePostalCode,
}) => {
  const classes = useStyles()
  const [firstNameTouched, setFirstNameTouched] = useState(false)
  const [lastNameTouched, setLastNameTouched] = useState(false)
  const [companyNameTouched, setCompanyNameTouched] = useState(false)
  const [streetAddress1Touched, setStreetAddress1Touched] = useState(false)
  const [countryTouched, setCountryTouched] = useState(false)
  const [cityTouched, setCityTouched] = useState(false)
  const [provinceTouched, setProvinceTouched] = useState(false)
  const [postalCodeTouched, setPostalCodeTouched] = useState(false)

  const handleFirstNameChanged = (evt) => {
    setFirstNameTouched(true)
    onChangeFirstName && onChangeFirstName(evt)
  }

  const handleLastNameChanged = (evt) => {
    setLastNameTouched(true)
    onChangeLastName && onChangeLastName(evt)
  }

  const handleCompanyNameChanged = (evt) => {
    setCompanyNameTouched(true)
    onChangeCompanyName && onChangeCompanyName(evt)
  }

  const handleStreetAddress1Changed = (evt) => {
    setStreetAddress1Touched(true)
    onChangeStreetAddress1 && onChangeStreetAddress1(evt)
  }

  const handleCountryChanged = (evt, value) => {
    setCountryTouched(true)
    onChangeCountry && onChangeCountry(evt, value)
  }

  const handleCityChanged = (evt) => {
    setCityTouched(true)
    onChangeCity && onChangeCity(evt)
  }

  const handleProvinceChanged = (evt) => {
    setProvinceTouched(true)
    onChangeProvince && onChangeProvince(evt)
  }

  const handlePostalCodeChanged = (evt) => {
    setPostalCodeTouched(true)
    onChangePostalCode && onChangePostalCode(evt)
  }

  const provinceRequired = isProvinceRequired(country)

  return (
    <Grid container spacing={1}>
      <Grid xs={6} item>
        <Box mr={1}>
          <TextField
            id='invoiceFirstName'
            className={classes.formControl}
            required
            autoFocus
            size='small'
            label='First Name'
            value={firstName}
            onChange={handleFirstNameChanged}
            error={firstNameTouched && firstName === ''}
            helperText={
              firstNameTouched && firstName === ''
                ? 'First name is required'
                : ''
            }
          />
        </Box>
      </Grid>
      <Grid xs={6} item>
        <Box ml={1}>
          <TextField
            id='invoiceLastName'
            className={classes.formControl}
            required
            size='small'
            label='Last Name'
            value={lastName}
            onChange={handleLastNameChanged}
            error={lastNameTouched && lastName === ''}
            helperText={
              lastNameTouched && lastName === '' ? 'Last name is required' : ''
            }
          />{' '}
        </Box>
      </Grid>
      <Grid xs={12} item>
        <TextField
          id='invoiceCompanyName'
          className={classes.formControl}
          required
          size='small'
          label='Company Name'
          value={companyName}
          onChange={handleCompanyNameChanged}
          error={companyNameTouched && companyName === ''}
          helperText={
            companyNameTouched && companyName === ''
              ? 'Company name is required'
              : ''
          }
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          id='invoiceStreetAddress1'
          className={classes.formControl}
          required
          size='small'
          label='Street Address'
          value={streetAddress1}
          onChange={handleStreetAddress1Changed}
          error={streetAddress1Touched && streetAddress1 === ''}
          helperText={
            streetAddress1Touched && streetAddress1 === ''
              ? 'Street address is required'
              : ''
          }
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          id='invoiceStreetAddress2'
          className={classes.formControl}
          size='small'
          label='Street Address 2'
          value={streetAddress2}
          onChange={onChangeStreetAddress2}
        />
      </Grid>
      <Grid xs={6} item>
        <CountrySelect
          id='invoiceCountry'
          className={classes.formControl}
          required
          size='small'
          label='Country'
          value={country}
          onChange={handleCountryChanged}
          error={countryTouched && !country}
          helperText={countryTouched && !country ? 'Country is required' : ''}
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          id='invoiceCity'
          className={classes.formControl}
          required
          size='small'
          label='City'
          value={city}
          onChange={handleCityChanged}
          error={cityTouched && city === ''}
          helperText={cityTouched && city === '' ? 'City is required' : ''}
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          id='invoiceProvince'
          className={classes.formControl}
          required={provinceRequired}
          size='small'
          label='State/Province'
          value={province}
          onChange={handleProvinceChanged}
          error={provinceRequired && provinceTouched && province === ''}
          helperText={
            provinceRequired && provinceTouched && province === ''
              ? 'State or province is required'
              : ''
          }
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          id='invoicePostalCode'
          className={classes.formControl}
          required
          size='small'
          label='Postal Code'
          value={postalCode}
          onChange={handlePostalCodeChanged}
          error={postalCodeTouched && postalCode === ''}
          helperText={
            postalCodeTouched && postalCode === ''
              ? 'Postal code is required'
              : ''
          }
        />
      </Grid>
    </Grid>
  )
}

export default InvoiceAddressDetails
