import { gql } from '@apollo/client'
import { CurrentUserFragment } from './CurrentUserFragment'
import { OrganizationFragment } from './OrganizationFragment'

const AUTH = gql`
  query {
    currentUser {
      ...currentUser
    }
    organization {
      ...organization
    }
    invitations
  }
  ${CurrentUserFragment}
  ${OrganizationFragment}
`


export { AUTH }
