import { gql } from '@apollo/client'

const MatrixDetailsFragment = gql`
  fragment matrixDetails on Matrix {
    _id
    name
    size {
      x
      y
    }
    cells {
      color
      value
    }
    vAxisLabel
    hAxisLabel
    vLabels
    hLabels
    actionThreshold
    plan
  }
`

export { MatrixDetailsFragment }
