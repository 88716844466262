import { useParams } from 'react-router-dom'

import withAuth from '../components/auth'
import SessionWithData from '../components/SessionWithData'
import CreateSession from '../components/CreateSession'
import AttendSession from '../components/AttendSession'

const SessionPage = (props) => {
  const params = useParams()
  const id = params.id
  const action = params.action
  if (id === 'new') {
    return <CreateSession {...props} />
  } else if (id && action) {
    return (
      <AttendSession
        sessionId={id}
        action={action}
        auth={props.auth}
      />
    )
  }
  return <SessionWithData sessionId={id} {...props} />
}

export default withAuth(SessionPage)
