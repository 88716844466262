import { makeStyles } from '@material-ui/styles'
import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'

import Avatar from './Avatar'
import { isValidEmail } from '../util'

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  option: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto',
    justifyItems: 'start',
    alignItems: 'center',
  }
}))

const UserSearchInput = ({ users, className, onSelect, autoFocus }) => {
  const classes = useStyles()

  const [value, setValue] = useState(null)

  useEffect(() => {
    onSelect?.(value)
  }, [value, onSelect])

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && value?.email !== e.target.value) {
      setValue({ email: e.target.value })
    }
  }

  const filter = createFilterOptions({
    stringify: option => option.name + option.email,
  });

  return <Autocomplete
    className={className}
    fullWidth
    size='small'
    value={value}
    onKeyDown={handleKeyDown}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        setValue(null);
      } else if (newValue?.inputValue) {
        // Create a new value from the user input
        setValue({
          email: newValue.inputValue,
        });
      } else {
        setValue(newValue);
      }
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          name: <span>Invite {params.inputValue}</span>,
        });
      }

      return filtered;
    }}
    selectOnFocus
    autoHighlight
    handleHomeEndKeys
    id="add-participant"
    options={users}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option || '';
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.email ?? ''
    }}
    renderOption={(option) => <>
      <div className={classes.option}>
        <Avatar user={option._id && option} add={true} className={classes.avatar}></Avatar>
        <Typography variant='body1'>{option.name}{option.email ? ' (' + option.email + ')' : ''}</Typography>
        {!option._id && !isValidEmail(option.inputValue) && <>
          <div></div>
          <Typography variant='caption' color='error'>Invalid email</Typography>
        </>}
      </div>
    </>
    }
    freeSolo
    renderInput={(params) => (
      <TextField autoFocus {...params} label='Enter a user name or email' variant='outlined' />
    )}
  />
}

export default UserSearchInput
