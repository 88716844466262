import { makeStyles } from '@material-ui/styles'

import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => {
  return {
    list: {
      margin: 0,
      display: 'inline-block',
      paddingLeft: '0.5rem',
      listStyleType: 'none',
      '&>li': {
        fontSize: theme.typography.caption.fontSize,
        textIndent: '-0.5rem',
        '&::before': {
          content: '"- "',
          textIndent: '-0.5rem',
        },
      },
    },
  }
})

const RiskImportResult = ({ messages }) => {
  const classes = useStyles()

  return (
    <Box>
      <ul className={classes.list}>
        {messages &&
          messages.map((m, i) => <li key={`import-result-${i}`}>{m.text}</li>)}
      </ul>
    </Box>
  )
}

export default RiskImportResult
