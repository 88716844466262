import { useState, useRef, useEffect } from 'react'
import InputField from './InputField'

const CommittableInputField = ({ value, onChange, onFocus, ...otherProps }) => {
  const [_value, setValue] = useState(value)
  const inputRef = useRef()

  useEffect(() => {
    if (value !== _value) {
      setValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleFocus = (e) => {
    inputRef.current && inputRef.current.select()
    onFocus && onFocus(e)
  }

  const handleBlur = (e) => {
    if (value !== _value && onChange) {
      onChange(_value)
    }
  }

  const handleKeyDown = (e) => {
    e.stopPropagation()
    if (!inputRef.current) {
      return
    }
    if (e.key === 'Enter' && !otherProps.multiline) {
      inputRef.current.blur()
    } else if (e.key === 'Escape') {
      setValue(value)
      inputRef.current.blur()
    }
  }

  return (
    <InputField
      value={_value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      inputRef={inputRef}
      {...otherProps}
    />
  )
}

export default CommittableInputField
