import csv from 'csv-parse'
import toCsv from 'csv-stringify/lib/sync'

const COLUMN_TITLE = 'Title'
const COLUMN_DESCRIPTION = 'Description'
const COLUMN_X = 'X'
const COLUMN_Y = 'Y'
const COLUMN_VALUE = 'Value'
const DELIMITER = ';'

const transformRisks = (risks) =>
  [[COLUMN_TITLE, COLUMN_DESCRIPTION, COLUMN_X, COLUMN_Y, COLUMN_VALUE]].concat(
    risks?.map((r) => [
      r.title,
      r.description,
      r.position?.x ? (r.position?.x + 1).toFixed(2) : undefined,
      r.position?.y ? (r.position?.y + 1).toFixed(2) : undefined,
      r.value,
    ])
  )

const generateCsv = (risks) =>
  toCsv(transformRisks(risks), { delimiter: DELIMITER })

const getColumnIndexes = (arr) => {
  let result = {}
  for (let i = 0; i < arr.length; i++) {
    const c = arr[i]?.trim()?.toLowerCase()
    if (!c) {
      continue
    }
    switch (c) {
      case COLUMN_TITLE.toLowerCase():
        result[COLUMN_TITLE] = i
        break
      case COLUMN_DESCRIPTION.toLowerCase():
        result[COLUMN_DESCRIPTION] = i
        break
      case COLUMN_X.toLowerCase():
        result[COLUMN_X] = i
        break
      case COLUMN_Y.toLowerCase():
        result[COLUMN_Y] = i
        break
      default:
        break
    }
  }
  return result
}

const createRisk = (arr, line, xMax, yMax, columns, intPosMap) => {
  const risk = {}
  const name = arr[columns[COLUMN_TITLE]]
  let messages = []
  if (name) {
    risk.title = name
  } else {
    risk.title = ''
  }
  let xInvalid = false
  let yInvalid = false
  const description = arr[columns[COLUMN_DESCRIPTION]]
  if (description) {
    risk.description = description
  }
  const x = arr[columns[COLUMN_X]]
  if (x) {
    let xf = parseFloat(x)
    if (!isNaN(xf)) {
      xf = xf - 1
      if (!risk.position) {
        risk.position = {}
      }
      if (xf < 0) {
        risk.position.x = Math.max(xf, 0)
      } else {
        risk.position.x = Math.min(xf, xMax - 0.01)
      }
      if (xf > xMax || xf < 0) {
        messages.push({
          status: 'warning',
          text: `Risk on line ${line} has an x position outside of the matrix '${x}'`,
        })
      }
    } else {
      xInvalid = true
      messages.push({
        status: 'warning',
        text: `Risk on line ${line} has an invalid x position '${x}'`,
      })
    }
  }
  const y = arr[columns[COLUMN_Y]]
  if (y) {
    let yf = parseFloat(y)
    if (!isNaN(yf)) {
      yf = yf - 1
      if (!risk.position) {
        risk.position = {}
      }
      if (yf < 0) {
        risk.position.y = Math.max(yf, 0)
      } else {
        risk.position.y = Math.min(yf, yMax - 0.01)
      }
      if (yf > yMax || yf < 0) {
        messages.push({
          status: 'warning',
          text: `Risk on line ${line} has a y position outside of the matrix '${y}'`,
        })
      }
    } else {
      yInvalid = true
      messages.push({
        status: 'warning',
        text: `Risk on line ${line} has an invalid y position '${y}'`,
      })
    }
  }
  if (risk.position) {
    if (risk.position.x !== undefined && risk.position.y === undefined) {
      risk.position.y = 0
      if (!yInvalid) {
        messages.push({
          status: 'warning',
          text: `Risk on line ${line} has an x position, but no y position`,
        })
      }
    }
    if (risk.position.y !== undefined && risk.position.x === undefined) {
      risk.position.x = 0
      if (!xInvalid) {
        messages.push({
          status: 'warning',
          text: `Risk on line ${line} has a y position, but no x position`,
        })
      }
    }
    if (
      Number.isSafeInteger(risk.position.x) &&
      Number.isSafeInteger(risk.position.y)
    ) {
      const key = `${risk.position.x},${risk.position.y}`
      let risks = intPosMap[key]
      if (!risks) {
        risks = []
        intPosMap[key] = risks
      }
      risks.push(risk)
    }
  }
  return { risk, status: messages.length === 0 ? 'ok' : 'warning', messages }
}

const readCsv = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.addEventListener('load', function (e) {
      resolve(e.target.result)
    })
    reader.readAsText(file)
  })
}

const parseContent = (content, xMax, yMax) => {
  return new Promise((resolve, reject) => {
    csv(
      content,
      {
        comment: '#',
        delimiter: DELIMITER,
        trim: true,
        skip_empty_lines: true,
        bom: true,
      },
      function (err, output) {
        if (err) {
          reject({
            status: 'error',
            messages: [
              {
                status: 'error',
                text: err.message,
              },
            ],
            summary: 'Error parsing CSV file',
          })
          return
        }
        let parseStatus = 'ok'
        let parseMessages = []
        let summary
        const indexes = getColumnIndexes(output[0])
        if (indexes[COLUMN_TITLE] === undefined) {
          reject({
            status: 'error',
            messages: [
              {
                status: 'error',
                text: `Required column 'title' is missing`,
              },
            ],
            summary: 'Invalid CSV file',
          })
          return
        }
        const risks = []
        const intPosMap = {}
        for (let i = 1; i < output.length; i++) {
          const { risk, status, messages } = createRisk(
            output[i],
            i + 1,
            xMax,
            yMax,
            indexes,
            intPosMap
          )
          risks.push(risk)
          if (status === 'warning' && parseStatus === 'ok') {
            parseStatus = 'warning'
          }
          if (messages && messages.length > 0) {
            parseMessages = parseMessages.concat(messages)
          }
        }
        if (parseStatus === 'warning') {
          summary = `Found ${parseMessages.length} potential problems`
        }
        // Place risks with integer values in a grid fashion
        for (let v of Object.values(intPosMap)) {
          if (v && v.length > 0) {
            const dim = Math.ceil(Math.sqrt(v.length))
            const delta = 1 / dim
            let dimY = dim
            let deltaY = delta
            // If possible elliminate last empty row
            if (v.length / dim <= dim - 1) {
              dimY = dim - 1
              deltaY = 1 / dimY
            }
            v.forEach((r, i) => {
              r.position.x += (i % dim) * delta + delta / 2
              r.position.y += 1 - deltaY / 2 - Math.floor(i / dim) * deltaY
            })
          }
        }
        resolve({
          risks,
          status: parseStatus,
          messages: parseMessages,
          summary,
        })
      }
    )
  })
}

const parseCsv = async (file, xMax, yMax) => {
  const content = await readCsv(file)
  return parseContent(content, xMax, yMax)
}

export { parseCsv, generateCsv }
