import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles, withStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { ADD_RISKS, GET_RISKS } from '../graphql'
import { parseCsv } from '../utils/csvUtils'

import ProgressButton from './ProgressButton'
import FileUpload from './FileUpload'
import RiskImportResult from './RiskImportResult'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    titleText: {
      flex: 1,
    },
    checkmark: {
      width: '4rem',
      height: '4rem',
    },
    progress: {
      width: '4.5rem',
      height: '4.5rem',
    },
    resultGrid: {
      overflowY: 'scroll',
      maxHeight: '150px',
    },
    alert: {
      '& > div:last-child': {
        textAlign: 'left',
      },
    },
    dlgContentText: {
      color: theme.palette.text.secondary,
    },
    tooltipTitle: {
      fontWeight: 500,
    },
    helpIcon: {
      marginLeft: '0.5rem',
      marginBottom: '0.2rem',
    },
  }
})

const HelpTooltip = withStyles(({ palette }) => ({
  tooltip: {
    color: palette.grey[600],
    backgroundColor: palette.primary.light,
  },
  arrow: {
    color: palette.primary.light,
  },
}))(Tooltip)

const ImportRisksDialog = ({ open, onClose, sessionId, matrixSize }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [file, setFile] = useState()
  const [parseResult, setParseResult] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      let oldTitle = document.title
      document.title = 'Import risks'
      return () => {
        document.title = oldTitle
      }
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setActiveStep(0)
      setFile()
      setCompleted(false)
      setParseResult()
    }
  }, [open])

  const [addRisks] = useMutation(ADD_RISKS, {
    ignoreResults: true,
    onCompleted: () => {
      setCompleted(true)
    },
  })

  const _addRisks = (risks) => {
    addRisks({
      variables: { sessionId, input: risks },
      update: (client, { data: { addRisks } }) => {
        const data = client.readQuery({
          query: GET_RISKS,
          variables: { sessionId },
        })
        client.writeQuery({
          query: GET_RISKS,
          variables: { sessionId },
          data: {
            risks: [...data.risks, ...addRisks],
          },
        })
      },
    })
  }

  const handleFilesSelected = (files) => {
    if (files?.length > 0) {
      setFile(files[0])
      parseCsv(files[0], matrixSize.x, matrixSize.y)
        .then((res) => {
          setParseResult(res)
          if (res.status === 'ok') {
            _addRisks(res.risks)
            setActiveStep(activeStep + 2)
          } else {
            setActiveStep(activeStep + 1)
          }
        })
        .catch((res) => {
          setParseResult(res)
          setActiveStep(activeStep + 1)
        })
    }
  }

  const handleBack = (evt) => {
    setActiveStep(activeStep > 0 ? activeStep - 1 : 0)
  }

  const handleNext = (evt) => {
    STEPS[activeStep]?.onNext()
  }

  const STEPS = [
    {
      components: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center'>
              <Typography className={classes.dlgContentText}>
                Select a CSV file to import
              </Typography>
              <HelpTooltip
                title={
                  <Box p={0.5}>
                    <Typography
                      variant='body1'
                      className={classes.tooltipTitle}
                      gutterBottom
                    >
                      Import file format
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant='caption'>
                          The file must be a CSV file using a single semicolon{' '}
                          <strong>;</strong> as the delimiter. Values with
                          special characters need to be enclosed with double
                          quotes. A double quote " needs to be represented as
                          two double quotes "".
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='caption'
                          className={classes.tooltipTitle}
                        >
                          Required column:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption'>Title</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption'>
                          Supported columns:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display='flex' flexDirection='column'>
                          <Typography variant='caption'>Description</Typography>
                          <Typography variant='caption'>X</Typography>
                          <Typography variant='caption'>Y</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                }
                arrow
              >
                <HelpOutlineIcon
                  color='secondary'
                  className={classes.helpIcon}
                />
              </HelpTooltip>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              label={file?.name}
              onFilesSelected={handleFilesSelected}
            ></FileUpload>
          </Grid>
        </Grid>
      ),
      canComplete: () => file,
      onNext: () => {
        setActiveStep(activeStep + 1)
      },
    },
    {
      components: (
        <Grid container spacing={2}>
          <Grid item xs={12} align='center'>
            <Alert
              severity={parseResult?.status === 'error' ? 'error' : 'warning'}
              className={classes.alert}
            >
              {parseResult?.summary}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption'>Details</Typography>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12} className={classes.resultGrid}>
            <RiskImportResult {...parseResult} />
          </Grid>
        </Grid>
      ),
      canComplete: () => parseResult?.status !== 'error',
      onNext: () => {
        _addRisks(parseResult.risks)
        setActiveStep(activeStep + 1)
      },
    },
    {
      dlgContentText: !completed && 'Importing risks...',
      components: completed ? (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Alert severity='success'>Risks successfully imported!</Alert>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      ),
      canComplete: () => completed,
      onNext: () => {
        onClose && onClose()
      },
    },
  ]

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' justifyContent='stretch' alignItems='center'>
          <Typography className={classes.titleText} variant='h6'>
            Import risks
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {STEPS[activeStep].dlgContentText && (
          <DialogContentText>
            {STEPS[activeStep].dlgContentText}
          </DialogContentText>
        )}
        <Box display='flex' flexDirection='column' alignItems='center'>
          {STEPS[activeStep].label}
          <Box
            height={200}
            display='flex'
            flexDirection='column'
            alignItems='center'
            minWidth={{ xs: 200, sm: 400 }}
          >
            {STEPS[activeStep].components}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0 || completed} onClick={handleBack}>
          Back
        </Button>
        <ProgressButton
          disabled={!STEPS[activeStep].canComplete()}
          variant='contained'
          color='primary'
          onClick={handleNext}
        >
          {activeStep === 2 ? 'Close' : activeStep === 1 ? 'Import' : 'Next'}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  )
}

export default ImportRisksDialog
