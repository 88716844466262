import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import Grid from '@material-ui/core/Grid'

import { PLANS } from '../plans'
import PlanCard from './PlanCard'

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(2),
    },
    planGrid: {
      marginTop: theme.spacing(2),
    },
  }
})

const UpgradePlanDialog = (props) => {
  const { open, onClose, currentPlan, hasInvoiceAddress } = props
  const classes = useStyles()
  const [plan, setPlan] = useState(
    currentPlan === PLANS.TRIAL ? PLANS.LITE : undefined
  )
  const [hasConsent, setHasConsent] = useState(false)

  useEffect(() => {
    if (open) {
      setPlan(currentPlan === PLANS.TRIAL ? PLANS.LITE : undefined)
    }
  }, [currentPlan, open])

  const handleChangePlan = (evt, value) => {
    setPlan(value)
  }

  const handleCheck = () => {
    setHasConsent(!hasConsent)
  }

  const handleClose = () => {
    onClose && onClose()
    setHasConsent(false)
  }

  const handleSubmit = () => {
    onClose && onClose(plan)
    setPlan()
    setHasConsent(false)
  }

  const canSubmit = () =>
    hasConsent === true &&
    hasInvoiceAddress &&
    plan &&
    plan !== currentPlan &&
    currentPlan !== PLANS.PRO

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box p={2}>
          <Typography variant='h6' className={classes.title}>
            Upgrade Plan
          </Typography>
          {!hasInvoiceAddress && (
            <Alert severity='error'>
              You must provide an invoice address to select a paid plan
            </Alert>
          )}
          {(currentPlan === PLANS.LITE || currentPlan === PLANS.PRO) && (
            <Alert severity='info'>
              Please contact us to upgrade to enterprise
            </Alert>
          )}
          <Box pt={2}>
            <PlanCard
              plan={PLANS.LITE}
              selected={plan === PLANS.LITE}
              disabled={currentPlan === PLANS.LITE || currentPlan === PLANS.PRO}
              onClick={handleChangePlan}
            />
          </Box>
          <Grid item xs={12}>
            <Box display='flex'>
              <Checkbox
                checked={hasConsent}
                disabled={currentPlan === PLANS.PRO}
                onChange={handleCheck}
              />
              <Box mt={3}>
                <Typography variant='body1'>
                  By upgrading to a paid plan I understand and agree that I will
                  be billed the monthly fee through an invoice sent to my
                  invoice address
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button
            disabled={!canSubmit()}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpgradePlanDialog
