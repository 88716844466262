import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { SessionTable } from '../components/SessionTable'
import { SessionsToolbar } from '../components/SessionsToolbar'
import ConfirmationDialog from '../components/ConfirmationDialog'
import SessionCloneDialog from '../components/SessionCloneDialog'
import withAuth from '../components/auth'
import withSessions from '../components/SessionData'
import { DELETE_SESSION, CLONE_SESSION } from '../graphql'
import { isGuest, canClone } from '../util'

const DEFAULT_DATA = { show: false, execute: false }
const DEFAULT_SNACK = { show: false, text: '' }

const ERROR_DELETE = 'There was an error deleting the risk assessment'
const ERROR_CLONE = 'There was an error cloning the risk assessment'

const SessionsPage = ({ auth: { user, org }, sessions, loading }) => {
  const [filter, setFilter] = useState({ showClosed: false, owner: false })
  const [menuData, setMenuData] = useState(DEFAULT_DATA)
  const [errorSnack, setErrorSnack] = useState(DEFAULT_SNACK)
  const [showGuestSnack, setShowGuestSnack] = useState(false)
  const [showCloneDialog, setShowCloneDialog] = useState(false)

  const [deleteSession] = useMutation(DELETE_SESSION, {
    onCompleted: (data) => {
      if (data && !data.deleteSession) {
        setErrorSnack({ show: true, text: ERROR_DELETE })
      }
    },
    onError: (error) => {
      if (error) {
        setErrorSnack({ show: true, text: ERROR_DELETE })
      }
    },
  })

  const [cloneSession] = useMutation(CLONE_SESSION, {
    onCompleted: (data) => {
      if (data && !data.cloneSession) {
        setErrorSnack({ show: true, text: ERROR_CLONE })
      }
    },
    onError: (error) => {
      if (error) {
        setErrorSnack({ show: true, text: ERROR_CLONE })
      }
    },
  })

  useEffect(() => {
    document.title = 'Risk assessments'
  })

  useEffect(() => {
    if (org) {
      setShowGuestSnack(isGuest(org?.billing))
    }
  }, [org])

  useEffect(() => {
    if (menuData.execute) {
      const sessionId = menuData.session?._id
      if (menuData.action === 'delete') {
        deleteSession({
          variables: { id: sessionId },
        })
      } else if (menuData.action === 'clone') {
        cloneSession({
          variables: {
            id: sessionId,
            options: { participants: true, risks: true, actions: true },
          },
        })
      }
      setMenuData(DEFAULT_DATA)
    }
  }, [menuData, deleteSession, cloneSession])

  const getFilteredSessions = () =>
    sessions.filter((s) => {
      const searchString = filter.search && filter.search.toLowerCase()
      const searchMatch = searchString
        ? s.name.toLowerCase().includes(searchString)
        : true
      const statusMatch = s.status !== 'closed' || filter.showClosed
      const ownerMatch = !filter.owner || s.owner?._id === user._id
      return searchMatch && statusMatch && ownerMatch
    })

  const handleSearchFilterChanged = (value) => {
    setFilter({ ...filter, search: value })
  }

  const handleStatusFilterChanged = (value) => {
    setFilter({ ...filter, showClosed: value })
  }

  const handleOwnerFilterChanged = (value) => {
    setFilter({ ...filter, owner: value })
  }

  const handleSessionMenuClick = (evt, session) => {
    setMenuData({ session, anchor: evt.target, show: true })
  }

  const handleSessionMenuClose = () => {
    setMenuData({ show: false })
  }

  const handleConfirmDialogClose = () => {
    setMenuData({ ...menuData, confirmationDialog: {} })
  }

  const handleConfirm = () => {
    setMenuData({ ...menuData, execute: true, confirmationDialog: {} })
  }

  const handleClickClone = () => {
    setMenuData({
      ...menuData,
      show: false,
      action: 'clone',
    })
    setShowCloneDialog(true)
  }

  const handleClickDelete = () => {
    setMenuData({
      ...menuData,
      show: false,
      action: 'delete',
      confirmationDialog: {
        show: true,
        title: 'Delete risk assessment',
        content: (
          <>
            Permanently delete risk assessment <br />
            <strong>{menuData.session?.name}</strong> ?
          </>
        ),
        confirmButtonText: 'DELETE',
        data: menuData.session,
      },
    })
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setErrorSnack({ ...errorSnack, show: false })
  }

  const handleCloseGuestSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowGuestSnack(false)
  }

  const handleCloseCloneDialog = (e, reason, result) => {
    setShowCloneDialog(false)
    setMenuData({ show: false })
    if (result) {
      const { includeUsers: participants, includeActionPlan: actions } = result
      cloneSession({
        variables: {
          id: menuData?.session?._id,
          options: { participants, risks: true, actions },
        },
      })
    }
  }

  return (
    <Container>
      <Box display='flex' flexDirection='column' paddingTop={1}>
        <Snackbar
          open={showGuestSnack}
          autoHideDuration={6000}
          onClose={handleCloseGuestSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseGuestSnackbar} severity='info'>
            You are using RiskAce as a guest user.{' '}
            <Link to={{ pathname: '/profile' }}>Upgrade to a paid plan</Link> to
            create risk assessments.
          </Alert>
        </Snackbar>
        <SessionsToolbar
          filter={filter}
          user={user}
          org={org}
          onSearchFilterChange={handleSearchFilterChanged}
          onStatusFilterChange={handleStatusFilterChanged}
          onOwnerFilterChange={handleOwnerFilterChanged}
        />
        <SessionTable
          user={user}
          sessions={getFilteredSessions()}
          loading={loading}
          showClosed={filter.showClosed}
          onSessionMenuClick={handleSessionMenuClick}
        />
        <Menu
          open={menuData.show}
          anchorEl={menuData.anchor}
          onClose={handleSessionMenuClose}
        >
          <MenuItem
            disabled={isGuest(org?.billing) || !canClone(org, menuData.session)}
            onClick={handleClickClone}
          >
            Clone
          </MenuItem>
          <MenuItem
            disabled={user._id !== menuData.session?.owner?._id ?? true}
            onClick={handleClickDelete}
          >
            Delete
          </MenuItem>
        </Menu>
        <ConfirmationDialog
          open={menuData.confirmationDialog?.show ?? false}
          title={menuData.confirmationDialog?.title}
          content={menuData.confirmationDialog?.content}
          confirmButtonText={menuData.confirmationDialog?.confirmButtonText}
          handleClose={handleConfirmDialogClose}
          handleConfirm={handleConfirm}
          data={menuData.confirmationDialog?.data}
        />
        <SessionCloneDialog
          sessionId={menuData?.session?._id}
          open={showCloneDialog}
          onClose={handleCloseCloneDialog}
        />
        <Snackbar
          open={errorSnack.show}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity='error'>
            {errorSnack?.text ?? 'There was an unexpected error'}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  )
}

export default withAuth(withSessions(SessionsPage))
