import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'

import { generateSecret as generate2FASecret } from '../twoFactorAuth'

import { SET_2FA } from '../graphql'
import TwoFactorAuthKey from './TwoFactorAuthKey'
import TwoFactorCodeValidation from './TwoFactorCodeValidation'
import PasswordValidation from '../components/PasswordValidation'

const useStyles = makeStyles((theme) => {
  return {
    titleText: {
      flex: 1,
    },
    checkmark: {
      width: '4rem',
      height: '4rem',
    },
  }
})

const EnableTwoFactorAuthDialog = ({ open, onClose, subject }) => {
  const [authKey, setAuthKey] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [password, setPassword] = useState('')
  const [completed, setCompleted] = useState(false)

  const [setTwoFactorAuth] = useMutation(SET_2FA, {
    onCompleted: () => {
      setCompleted(true)
    },
  })
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Two-factor authentication'
  })

  useEffect(() => {
    if (open) {
      setAuthKey(generate2FASecret())
      setActiveStep(0)
      setPassword('')
      setCompleted()
    }
  }, [open])

  const handleBack = (evt) => {
    setActiveStep(activeStep > 0 ? activeStep - 1 : 0)
  }

  const handleNext = (evt) => {
    if (completed) {
      onClose && onClose()
    } else if (activeStep < 2) {
      setActiveStep(activeStep + 1)
    }
  }

  const handlePasswordVerified = (value) => {
    setPassword(value)
  }

  const handleVerifyCode = () => {
    setTwoFactorAuth({
      variables: {
        input: {
          enabled: true,
          password,
          key: authKey,
        },
      },
    })
  }

  const STEPS = [
    {
      label: (
        <Typography variant='body1'>
          {password
            ? 'Password successfully validated!'
            : 'Enter your password'}
        </Typography>
      ),
      components: password ? (
        <CheckCircleOutlineIcon
          color='secondary'
          className={classes.checkmark}
        />
      ) : (
        <PasswordValidation
          username={subject}
          onComplete={handlePasswordVerified}
        />
      ),
      canComplete: () => password,
    },
    {
      label: (
        <Typography variant='body1'>
          Open your authenticator app and scan the QR code
        </Typography>
      ),
      components: <TwoFactorAuthKey value={authKey} subject={subject || ''} />,
      canComplete: () => true,
    },
    {
      label: (
        <Typography variant='body1'>
          {completed
            ? 'Two-factor authentication successfully enabled!'
            : 'Enter the verification code from your authenticator'}
        </Typography>
      ),
      components: completed ? (
        <CheckCircleOutlineIcon
          color='secondary'
          className={classes.checkmark}
        />
      ) : (
        <TwoFactorCodeValidation
          authKey={authKey}
          onVerified={handleVerifyCode}
        />
      ),
      canComplete: () => completed,
    },
  ]

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' justifyContent='stretch' alignItems='center'>
          <Typography className={classes.titleText} variant='h6'>
            Two-Factor Authentication Setup
          </Typography>
          <IconButton size='small' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Follow the steps below to setup two-factor authentication and add an
          extra layer of security to your account. Please ensure you have
          downloaded an authenticator app to your smartphone before proceeding.
        </DialogContentText>
        <Stepper activeStep={activeStep}>
          {STEPS.map((step, i) => (
            <Step
              key={`authstep${i}`}
              active={activeStep === i}
              completed={activeStep > i}
            >
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {STEPS[activeStep].label}
          <Box
            mt={2}
            height={'200px'}
            display='flex'
            flexDirection='column'
            alignItems='center'
            minWidth={{ xs: 200, sm: 400 }}
          >
            {STEPS[activeStep].components}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0 || completed} onClick={handleBack}>
          Back
        </Button>
        <Button
          disabled={!STEPS[activeStep].canComplete()}
          variant='contained'
          color='primary'
          onClick={handleNext}
        >
          {activeStep === 2 ? 'Close' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnableTwoFactorAuthDialog
