import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import PageHeader from '../components/PageHeader'
import Footer from '../components/Footer'

const useStyles = makeStyles((theme) => {
  return {
    main: {
      backgroundColor: theme.palette.primary.light,
      minHeight: '100vh',
    },
    heading: {
      fontWeight: 500,
      color: '#333333',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
      },
    },
    text: {
      fontSize: '1.1rem',
      color: '#333333',
    },
    bold: {
      fontWeight: 500,
    },
    aboutGrid: {
      padding: theme.spacing(4, 0, 4, 0),
    },
  }
})

const AboutPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <PageHeader dark />
      <Container maxWidth={'lg'} className={classes.topMargin}>
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          alignItems='flex-start'
          mt={4}
        >
          <Typography
            variant='h2'
            paragraph
            classes={{ root: classes.heading }}
          >
            About us
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
          >
            Risk management shall be easy and fun.
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
          >
            The concept behind RiskAce was born in 2018 in discussion between
            risk management specialists. There was a need for a tool to solve
            these main issues:
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
            component='ul'
          >
            <li classes={classes.text}>
              Too much time is spent on documentation and reporting instead of
              discussing and managing risks
            </li>
            <li classes={classes.text}>
              Since teams are more distributed than ever before it is hard to
              gather people for risk workshops
            </li>
            <li classes={classes.text}>
              Traditional risk workshops lack a proper audit trail
            </li>
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
          >
            The journey towards a collaborative, easy and cost-effective tool
            began. With RiskAce, workshops can take place, no matter where the
            team is located as everyone can participate as long as they have a
            decent internet connection. The beauty is also that everyone is able
            to prepare in advance. Image the power of the discussion when you
            can move from “what risks do we have” to “how does this risk affect
            us” already in the beginning of the workshop.
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
          >
            The prototypes for RiskAce were the endless excel sheets used to
            document many workshops in the past and as IT skills were brought to
            the project, the excel sheets turned into a generic risk analysis
            tool.
          </Typography>
          <Typography
            variant='body1'
            paragraph
            classes={{ root: classes.text }}
          >
            Behind RiskAce is the company Compliance Tech AB. Founded in 2018 in
            Stockholm by leading risk management specialists within the
            financial and management consultancy industry.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth={'lg'}>
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          alignItems='flex-start'
          mt={4}
        >
          <Typography variant='h4' classes={{ root: classes.heading }}>
            The key people behind RiskAce
          </Typography>
          <Grid container spacing={4} className={classes.aboutGrid}>
            <Grid xs={12} sm={4} item>
              <Box minHeight={'4rem'}>
                <Typography
                  variant='body1'
                  classes={{ root: `${classes.text} ${classes.bold}` }}
                >
                  Christina Erikson
                </Typography>
                <Typography variant='body2' paragraph>
                  Chief Executive Officer
                </Typography>
              </Box>
              <Typography variant='body1' classes={{ root: classes.text }}>
                Risk management specialist with a background as an auditor,
                specialised in IT and internal control audits. Has a drive for
                smart, pragmatic solutions to enable focus on the main task.
                Christina is a lecturer in risk management with a focus on the
                finance industry.
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Box minHeight={'4rem'}>
                <Typography
                  variant='body1'
                  classes={{ root: `${classes.text} ${classes.bold}` }}
                >
                  Eric Samuelsson
                </Typography>
                <Typography variant='body2' paragraph>
                  Chief Technology Officer
                </Typography>
              </Box>
              <Typography variant='body1' classes={{ root: classes.text }}>
                An experienced tech leader that has designed many systems in
                several different industries. Strives to combine usability with
                the latest technologies and security standards.
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Box minHeight={'4rem'}>
                <Typography
                  variant='body1'
                  paragraph
                  classes={{ root: `${classes.text} ${classes.bold}` }}
                >
                  Advisory board
                </Typography>
              </Box>
              <Typography variant='body1' classes={{ root: classes.text }}>
                Our highly valued advisory board is the main source for new
                ideas and development of RiskAce. The members are from a variety
                of industries such as finance, public service and management
                consultancy.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  )
}

export default AboutPage
