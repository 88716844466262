import { useState, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import { scroller } from 'react-scroll'

import routes from '../routes'
import {
  UPDATE_SESSION,
  ADD_PARTICIPANT,
  DELETE_PARTICIPANTS,
} from '../graphql'

import useActions from './useActions'
import useRisks from './useRisks'
import SessionToolbar from './SessionToolbar'
import Matrix from './Matrix'
import RiskList from './RiskList'
import SessionUserList from './SessionUserList'
import { isForbiddenError, isAdministrator } from '../util'
import AddInvitationDialog from './AddInvitationDialog'
import ActionPlan from './ActionPlan'
import CommittableInputField from './CommittableInputField'
import SessionLog from './SessionLog'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    mainContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
      },
    },
    titleArea: {
      wordBreak: 'break-word',
    },
    title: {
      marginBottom: '0.75rem',
    },
    titleInput: {
      '& * textarea': theme.typography.h6,
    },
    description: {
      fontStyle: 'italic',
      margin: 0,
    },
    matrixContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    riskHint: {
      position: 'absolute',
      top: '50%',
      left: '52%',
      transform: 'translate(-50%, -50%)',
      width: '200px',
    },
    button: {
      marginTop: '2rem',
    },
    actionPlanContainer: {
      gridArea: 'actionplan',
    },
    participantActions: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    participantAction: {
      margin: theme.spacing(0.5, 0),
    },
    logLabel: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      cursor: 'pointer',
    },
  }
})

const renderAlert = (text) => (
  <Box margin={2}>
    <Alert severity='error'>{text}</Alert>
  </Box>
)

const Session = (props) => {
  const { session, participants, loading, error, auth } = props

  const [riskFilter, setRiskFilter] = useState({})
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [selectedRiskId, setSelectedRiskId] = useState()
  const [showLog, setShowLog] = useState(false)
  const [searchParams] = useSearchParams();

  const actionId = searchParams.get('action')

  useEffect(() => {
    if (actionId) {
      setTimeout(() => {
        scroller.scrollTo(actionId, {
          duration: 500,
          smooth: true,
        })
      }, 100)
    }
  }, [actionId])

  useEffect(() => {
    if (session) {
      document.title = session.name
    } else {
      document.title = 'Risk Assessment'
    }
  }, [session])

  useEffect(() => {
    if (participants && riskFilter.users) {
      setRiskFilter({
        ...riskFilter,
        users: riskFilter.users.filter((ru) =>
          participants.find((p) => p.user._id === ru.id)
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants])

  const classes = useStyles()
  const [updateSession] = useMutation(UPDATE_SESSION, { ignoreResults: true })
  const [deleteParticipants] = useMutation(DELETE_PARTICIPANTS, {
    ignoreResults: true,
  })
  const [addParticipant] = useMutation(ADD_PARTICIPANT, { ignoreResults: true })

  const [
    risks,
    loadingRisks,
    { addRisk, updateRisk, deleteRisk, cleanupRisks },
  ] = useRisks(session?._id, session?.matrix)

  const [actions, { addAction, updateAction, deleteAction }] = useActions(
    session?._id
  )

  const handleClickAvatar = (e, user) => {
    const isFiltered = riskFilter.users && riskFilter.users.includes(user._id)
    if (isFiltered) {
      setRiskFilter({
        ...riskFilter,
        users: riskFilter.users.filter((u) => u !== user._id),
      })
    } else {
      const users = riskFilter.users
        ? [...riskFilter.users, user._id]
        : [user._id]
      setRiskFilter({ ...riskFilter, users })
    }
  }

  const handleAddUser = () => {
    setShowAddUserDialog(true)
  }

  const getVisibleRisks = (risks, filter) => {
    if (risks) {
      if (!(filter && filter.users && filter.users.length > 0)) {
        return risks
      }
      return risks.filter((r) => filter.users.includes(r.ownerId))
    }
  }

  const handleDrop = (e) => {
    if (e.target.tagName === 'TABLE') {
      return
    } else if (e.target.tagName === 'TD') {
      const { x, y } = e.target.dataset
      if (x && y) {
        return
      }
    }
    const id = e.dataTransfer.getData('text')
    if (!id?.endsWith('-pos')) {
      return
    }

    const element = id && document.getElementById(id)
    const riskId = element && element.dataset.riskid
    if (riskId) {
      const risk = risks.find((r) => r._id === riskId)
      if (!risk || !risk.position) {
        return
      }
      e.stopPropagation()
      updateRisk({
        variables: {
          sessionId: session._id,
          riskId,
          input: { position: null },
        },
        optimisticResponse: {
          updateRisk: {
            _id: riskId,
            __typename: 'Risk',
            position: null,
          },
        },
      })
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }

  if (loading)
    return (
      <div className={classes.root}>
        <div className={classes.mainContainer}>
          <SessionToolbar
            auth={auth}
            session={session}
            users={participants?.map((p) => p.user) ?? []}
            loading={loading}
          />
          <Container classes={{ root: classes.content }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Skeleton variant='text' width={250} height={32} />
                <Skeleton variant='text' width={250} height={32} />
              </Grid>
              <Grid item container xs={12} sm={12} md={6} spacing={1}>
                <Grid item>
                  <SessionUserList
                    users={[]}
                    selected={[]}
                    hideAdd={false}
                    loading={loading}
                  />
                </Grid>
                <Grid item className={classes.participantActions}></Grid>
              </Grid>
              <Grid
                item
                classes={{ item: classes.matrixContainer }}
                xs={12}
                sm={12}
                md={6}
              >
                <Skeleton variant='rect' width={300} height={300} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Skeleton variant='rect' width={300} height={300} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    )
  if (error) {
    if (isForbiddenError(error)) {
      return renderAlert('The risk assessment is not accessible.')
    }
    return renderAlert('Oops something went wrong. Please try again.')
  }
  if (!session) {
    return renderAlert('The risk assessment is not accessible.')
  }
  const isAdmin = isAdministrator(session, auth.user)
  const isInvited =
    session.participants &&
    session.participants.find(
      (p) =>
        p.userId === auth.user._id &&
        (p.status === 'added' || p.status === 'invited')
    )
  if (!isAdmin && isInvited) {
    return <Navigate to={`${routes.RISK_ASSESSMENTS}/${session._id}/join`} />
  }

  const handleClickRisk = (risk) => {
    if (selectedRiskId === risk._id) {
      setSelectedRiskId()
    } else {
      setSelectedRiskId(risk._id)
    }
  }

  const handleClickAssessment = (riskId) => {
    const risk = risks.find((r) => r._id === riskId)
    if (risk && selectedRiskId !== risk._id) {
      setSelectedRiskId(risk._id)
    }
  }

  const handleChangeTitle = (value) => {
    updateSession({
      variables: {
        sessionId: session._id,
        input: {
          name: value,
        },
      },
    })
  }

  const handleChangeDescription = (value) => {
    updateSession({
      variables: {
        sessionId: session._id,
        input: {
          description: value,
        },
      },
    })
  }

  const handleClearFilter = () => {
    setRiskFilter({ ...riskFilter, users: [] })
  }

  const handleRemoveSelected = () => {
    deleteParticipants({
      variables: {
        sessionId: session._id,
        userIds: riskFilter.users,
      },
    })
    setRiskFilter({})
  }

  const handleToggleLog = () => {
    setShowLog(!showLog)
  }

  const handleMatrixDrop = (x, y, riskId) => {
    if (!riskId) {
      return
    }
    const risk = risks.find((r) => r._id === riskId)
    if (!risk) {
      return
    }
    updateRisk({
      variables: {
        sessionId: session._id,
        riskId,
        input: { position: { x, y } },
      },
      optimisticResponse: {
        updateRisk: {
          ...risk,
          __typename: 'Risk',
          position: { x, y },
        },
      },
    })
  }

  const handleMatrixClick = (x, y) => {
    const risk = selectedRiskId && risks.find((r) => r._id === selectedRiskId)
    if (!risk) {
      return
    }
    updateRisk({
      variables: {
        sessionId: session._id,
        riskId: risk._id,
        input: { position: { x, y } },
      },
      optimisticResponse: {
        updateRisk: {
          _id: risk._id,
          __typename: 'Risk',
          position: { x, y },
        },
      },
    })
  }

  const handleAddParticipant = (user) => {
    if (user) {
      addParticipant({
        variables: {
          sessionId: session._id,
          input: { userId: user?._id, email: user?.email },
          sendInvite: true,
        },
      })
    }
    setShowAddUserDialog(false)
  }

  return (
    <div
      className={classes.root}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className={classes.mainContainer}>
        <SessionToolbar
          auth={auth}
          session={session}
          risks={risks}
          users={participants?.map((p) => p.user) ?? []}
          loading={loading}
          cleanupRisks={cleanupRisks}
        />
        <Container classes={{ root: classes.content }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <CommittableInputField
                className={classes.titleInput}
                value={session.name || ''}
                onChange={handleChangeTitle}
                fullWidth
                multiline
                maxRows={2}
                disabled={session.status === 'closed'}
              />
              <CommittableInputField
                value={session.description || ''}
                onChange={handleChangeDescription}
                fullWidth
                multiline
                maxRows={5}
                placeholder='Add a description...'
                disabled={session.status === 'closed'}
              />
            </Grid>
            <Grid item container xs={12} sm={12} md={6} spacing={1}>
              <Grid item>
                <SessionUserList
                  users={participants?.map((p) => p.user) ?? []}
                  selected={riskFilter.users}
                  onClick={handleClickAvatar}
                  onAdd={handleAddUser}
                  showStatus
                  hideAdd={!isAdmin}
                />
              </Grid>
              <Grid item className={classes.participantActions}>
                {riskFilter && riskFilter.users && riskFilter.users.length > 0 && (
                  <Tooltip TransitionComponent={Zoom} title='Clear filter'>
                    <div>
                      <IconButton
                        className={classes.participantAction}
                        size='small'
                        aria-label='Clear filter'
                        onClick={handleClearFilter}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
                {
                  riskFilter?.users?.length > 0 && (
                    <Tooltip TransitionComponent={Zoom} title='Remove selected'>
                      <div>
                        <IconButton
                          disabled={
                            riskFilter &&
                            riskFilter.users &&
                            riskFilter.users.includes(session.owner?._id)
                          }
                          className={classes.participantAction}
                          size='small'
                          aria-label='Remove selected'
                          onClick={handleRemoveSelected}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
            <Grid
              item
              classes={{ item: classes.matrixContainer }}
              xs={12}
              sm={12}
              md={6}
            >
              <Matrix
                disabled={session.status === 'closed'}
                xs={300}
                sm={466}
                matrix={session.matrix}
                risks={getVisibleRisks(risks, riskFilter)}
                user={auth.user}
                sessionOwnerId={session.owner?._id}
                onDrop={handleMatrixDrop}
                onClick={handleMatrixClick}
                onClickAssessment={handleClickAssessment}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <RiskList
                disabled={session.status === 'closed'}
                loading={loadingRisks}
                risks={getVisibleRisks(risks, riskFilter)}
                addRisk={addRisk}
                updateRisk={updateRisk}
                deleteRisk={deleteRisk}
                selectedRiskId={selectedRiskId}
                actions={actions}
                matrix={session?.matrix}
                user={auth.user}
                users={participants?.map((p) => p.user) ?? []}
                sessionId={session._id}
                actionThreshold={session.matrix.actionThreshold}
                onClickRisk={handleClickRisk}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.title}>
                Action Plan
              </Typography>
              <ActionPlan
                disabled={session.status === 'closed'}
                actions={actions}
                risks={risks}
                sessionId={session._id}
                sessionUsers={participants?.map((p) => p.user) ?? []}
                user={auth.user}
                addAction={addAction}
                updateAction={updateAction}
                deleteAction={deleteAction}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.logLabel} onClick={handleToggleLog}>
                <Typography variant='h6'>Log</Typography>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={showLog ? 'Hide log' : 'Show log'}
                >
                  <IconButton size='small' onClick={handleToggleLog}>
                    {showLog ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Tooltip>
              </div>
              {showLog && <SessionLog sessionId={session._id} />}
            </Grid>
          </Grid>
        </Container>
      </div>
      <AddInvitationDialog
        open={showAddUserDialog}
        participants={participants}
        auth={auth}
        onClose={handleAddParticipant}
      />
    </div>
  )
}

export default Session
