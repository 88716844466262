import { useRef, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const PasswordInput = ({
  password,
  showLabel,
  inputVariant,
  error,
  onChange,
  onKeyPress,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const passwordInput = useRef(null)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <FormControl error={error !== undefined} className={className}>
      {showLabel && <InputLabel htmlFor='password'>Password</InputLabel>}
      <OutlinedInput
        id='password'
        inputRef={passwordInput}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onChange}
        onKeyPress={onKeyPress}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='Toggle password visibility'
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText id='component-error-text'>
          Invalid password
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default PasswordInput
