import { useState } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'

import { PLANS } from '../plans'
import { SET_PLAN } from '../graphql'
import UpgradePlanDialog from './UpgradePlanDialog'

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: 'flex',
    },
    upgradeBtn: {
      marginLeft: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(1),
    },
  }
})

const PlanSection = ({ billingInfo, canEdit, loading, refetch }) => {
  const classes = useStyles()

  const [setPlan] = useMutation(SET_PLAN, {
    onCompleted: (data) => {
      refetch && refetch({ fetchPolicy: 'network-only' })
    },
  })

  const [showDialog, setShowDialog] = useState(false)

  const handleClickUpgrade = () => {
    setShowDialog(true)
  }

  const handleCloseUpgradeDialog = (plan) => {
    setShowDialog(false)
    plan && setPlan({ variables: { plan } })
  }

  const isTrial =
    !billingInfo || !billingInfo.plan || billingInfo.plan === PLANS.TRIAL

  const expiresAt =
    billingInfo && billingInfo.expiresAt && moment(billingInfo.expiresAt)

  const expired = expiresAt && expiresAt.isBefore(moment())

  const getExpirationText = (expiresAt) => {
    const days = expiresAt.diff(moment(), 'days')
    switch (days) {
      case 0:
        return `Your trial expires in less than a day`
      case 1:
        return `Your trial expires in ${days} day`
      default:
        return `Your trial expires in ${days} days`
    }
  }

  const renderAlert = () => {
    if (loading) {
      return undefined
    }
    if (expired) {
      return (
        <Alert className={classes.alert} severity='error'>
          Your {isTrial ? 'trial' : 'plan'} has expired
        </Alert>
      )
    }
    if (billingInfo?.plan === PLANS.TRIAL && expiresAt) {
      return (
        <Alert className={classes.alert} severity='warning'>
          {getExpirationText(expiresAt)}
        </Alert>
      )
    }
    return undefined
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Box width='100%' m={2} display='flex' flexDirection='column'>
          <Typography variant='caption'>Plan</Typography>
          <Box display='flex' alignItems='flex-start'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1'>
                {billingInfo?.plan?.toUpperCase()}
              </Typography>
              {billingInfo?.plan === PLANS.TRIAL && expiresAt && (
                <Typography variant='caption'>{`Expires: ${moment(
                  expiresAt
                ).format('L')}`}</Typography>
              )}
            </Box>
            {canEdit && <Button
              className={classes.upgradeBtn}
              disabled={billingInfo?.plan === PLANS.ENTERPRISE}
              variant='contained'
              color='primary'
              onClick={handleClickUpgrade}
            >
              Upgrade
            </Button>}
          </Box>
          {!loading && renderAlert()}
        </Box>
      </Paper>
      {billingInfo && (
        <UpgradePlanDialog
          open={showDialog}
          currentPlan={billingInfo?.plan}
          hasInvoiceAddress={billingInfo?.invoiceAddress}
          onClose={handleCloseUpgradeDialog}
        />
      )}
    </>
  )
}

export default PlanSection
