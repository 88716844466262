import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_ACTIONS,
  GET_RISKS,
  GET_SESSION,
  GET_PARTICIPANTS,
  GET_SESSION_LOG,
  LOG_SESSION_EVENT,
  ORGANIZATION,
} from '../graphql'
import generatePdf from '../pdf'
import { addColorAndValueToRisk, downloadReport, sortRisks } from '../util'

import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: '7rem',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: theme.palette.primary.dark,
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }
})

const SessionReportDialog = ({ sessionId, organizationId, open, onClose }) => {
  const classes = useStyles()

  const {
    data: { session } = {},
    error: e1,
    loading: l1,
  } = useQuery(GET_SESSION, {
    variables: { id: sessionId },
    skip: !open || !sessionId,
    fetchPolicy: 'network-only',
  })

  const {
    data: { sessionLog } = {},
    error: e2,
    loading: l2,
  } = useQuery(GET_SESSION_LOG, {
    variables: { sessionId },
    skip: !open || !sessionId,
    fetchPolicy: 'network-only',
  })
  const {
    data: { risks } = {},
    error: e3,
    loading: l3,
  } = useQuery(GET_RISKS, {
    variables: { sessionId: sessionId },
    skip: !open || !sessionId,
    fetchPolicy: 'network-only',
  })
  const {
    data: { actions } = {},
    error: e4,
    loading: l4,
  } = useQuery(GET_ACTIONS, {
    variables: { sessionId: sessionId },
    skip: !open || !sessionId,
    fetchPolicy: 'network-only',
  })

  const {
    data: { participants } = {},
    error: e5,
    loading: l5,
  } = useQuery(GET_PARTICIPANTS, {
    variables: { sessionId },
    skip: !open || !sessionId,
    fetchPolicy: 'network-only',
  })

  const {
    data: { organization } = {},
    error: e6,
    loading: l6,
  } = useQuery(ORGANIZATION, {
    variables: { id: organizationId },
    skip: !open || !organizationId,
    fetchPolicy: 'network-only',
  })

  let transformedRisks = []
  if (session?.matrix && risks?.length > 0) {
    transformedRisks = risks.map((r) => {
      const newRisk = { ...r }
      addColorAndValueToRisk(newRisk, session.matrix)
      return newRisk
    })
    sortRisks(transformedRisks, session.matrix)
  }

  const [logSessionEvent] = useMutation(LOG_SESSION_EVENT, {
    ignoreResults: true,
  })

  const [includeActionPlan, setIncludeActionPlan] = useState(true)
  const [includeLog, setIncludeLog] = useState(true)

  const handleGenerate = (evt, reason) => {
    if (
      !reason &&
      !l1 &&
      !l2 &&
      !l3 &&
      !l4 &&
      !e1 &&
      !e2 &&
      !e3 &&
      !e4 &&
      !e5 &&
      !l5 &&
      !e6 &&
      !l6
    ) {
      const orgLogo = organization?.branding?.logo
      let logo
      if (orgLogo) {
        logo = `data:image/png;base64,${orgLogo}`
      } else {
        logo = document.getElementById('header-logo').outerHTML
      }

      generatePdf(
        session,
        transformedRisks,
        actions,
        participants,
        sessionLog,
        logo,
        {
          download: downloadReport(),
          includeActionPlan,
          includeLog,
        }
      )
      logSessionEvent({
        variables: { sessionId: session._id, event: 'DOWNLOAD_REPORT' },
      })
    }

    if (onClose) {
      onClose()
    }
  }

  const toggleActionPlan = (evt) => {
    setIncludeActionPlan(!includeActionPlan)
  }

  const toggleLog = (evt) => {
    setIncludeLog(!includeLog)
  }

  const canGenerate = () => {
    return !l1 && !l2 && !e1 && !e2
  }

  return (
    <Dialog
      open={open}
      onClose={handleGenerate}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Generate report</DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <FormControlLabel
          className={classes.label}
          control={
            <Checkbox checked={includeActionPlan} onChange={toggleActionPlan} />
          }
          label='Include action plan'
        />
        <FormControlLabel
          className={classes.label}
          control={<Checkbox checked={includeLog} onChange={toggleLog} />}
          label='Include event log'
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.button}
          disabled={!canGenerate()}
          onClick={handleGenerate}
        >
          {downloadReport() ? 'Download' : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SessionReportDialog
