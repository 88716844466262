import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => {
  return {
    input: {
      fontSize: theme.typography.h5.fontSize,
      textAlign: 'center',
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
    },
  }
})

const TwoFactorCodeInput = ({ onDone, ...props }) => {
  const classes = useStyles()

  const handleKeyPress = (evt) => {
    const charCode = evt.which ? evt.which : evt.keyCode
    const isNumber = !(charCode > 31 && (charCode < 48 || charCode > 57))
    if (
      !isNumber ||
      (props.value.length >= 7 &&
        evt.target.selectionStart === evt.target.selectionEnd)
    ) {
      evt.preventDefault()
    }
    if (charCode === 13 && props.value.length >= 7) {
      onDone && onDone()
    }
  }

  const handleChange = (evt) => {
    if (evt.target.value.length === 3 && props.value.length === 2) {
      evt.target.value += ' '
    }
    props.onChange(evt)
  }

  const handleFocus = (evt) => {
    evt.target.select()
  }

  return (
    <TextField
      {...props}
      InputProps={{
        autoFocus: true,
        classes: { input: classes.input },
        onFocus: handleFocus,
      }}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
    />
  )
}

export default TwoFactorCodeInput
