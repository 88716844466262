import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontWeight: ({ bold }) => (bold ? '500' : 'inherit'),
      '-ms-overflow-style': '-ms-autohiding-scrollbar',
      '&:hover': {
        backgroundColor: ({ disabled }) =>
          disabled ? 'inherit' : 'rgba(0, 0, 0, 0.06)',
        borderRadius: '3px',
        '& * svg': {
          display: ({ disabled }) => (disabled ? 'none' : 'inherit'),
        },
      },
      '& * svg': {
        display: 'none',
      },
      paddingLeft: ({ select }) => (select ? 0 : theme.spacing(1)),
    },
    underline: {
      '&:before': {
        border: 'none',
      },
      '&:hover:not($disabled):before': {
        border: 'none',
      },
    },
    disabled: {
      color: 'black',
    },
    inputLabel: {
      paddingLeft: ({ select }) => theme.spacing(select ? 1.6 : 1.2),
    },
    select: {
      paddingLeft: theme.spacing(1.2),
    },
  }
})

const InputField = ({
  disabled,
  bold,
  InputRootClass,
  InputLabelProps,
  InputProps,
  stickyLabel,
  ...otherProps
}) => {
  const classes = useStyles({ bold, disabled, select: otherProps.select })

  const inputProps = {
    classes: {
      root: `${classes.root}${InputRootClass ? ' ' + InputRootClass : ''}`,
      underline: classes.underline,
      disabled: classes.disabled,
    },
    disabled: disabled,
    disableUnderline: disabled,
    ...InputProps,
  }
  return (
    <TextField
      InputProps={inputProps}
      InputLabelProps={{
        ...InputLabelProps,
        classes: { root: classes.inputLabel },
      }}
      SelectProps={
        otherProps.select && {
          classes: {
            root: classes.select,
            disabled: classes.disabled,
          },
          disabled: disabled,
          disableUnderline: disabled,
        }
      }
      {...otherProps}
    />
  )
}

export default InputField
