const PLANS = {
  TRIAL: 'trial',
  LITE: 'lite',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
}

const isEligible = (plan, resourcePlan) => {
  if (!resourcePlan) {
    return true
  }
  switch (plan) {
    case PLANS.TRIAL:
      return resourcePlan === PLANS.TRIAL
    case PLANS.LITE:
      return resourcePlan === PLANS.TRIAL || resourcePlan === PLANS.LITE
    case PLANS.PRO:
      return (
        resourcePlan === PLANS.TRIAL ||
        resourcePlan === PLANS.LITE ||
        resourcePlan === PLANS.PRO
      )
    case PLANS.ENTERPRISE:
      return true
    default:
      return false
  }
}

export { PLANS, isEligible }
