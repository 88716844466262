import { gql } from '@apollo/client'
import { MatrixDetailsFragment } from './MatrixFragments'

const OrganizationFragment = gql`
  fragment organization on Organization {
    _id
    name
    domain
    ownerId
    matrices {
      ...matrixDetails
    }
    billing {
      plan
      expiresAt
      invoiceAddress {
        firstName
        lastName
        companyName
        city
        streetAddress1
        streetAddress2
        province
        country
        postalCode
      }
    }      
    branding {
      logo
    }
  }
  ${MatrixDetailsFragment}
`

export { OrganizationFragment }
