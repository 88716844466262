import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { grey } from '@material-ui/core/colors';

import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    fullwidth: {
      width: '100vw',
      backgroundColor: '#F4F4F4',
    },
    container: {
      height: 64,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      color: grey[500]
    },
  }
})

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.fullwidth}>
      <Container maxWidth={'lg'} className={classes.container}>
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          sx={{
            gap: 16,
          }}
        >
          <Typography variant='caption'>RiskAce © 2022</Typography>
          <Typography variant='caption'>
            <Link
              color='inherit'
              component={RouterLink}
              to={routes.PRIVACY_POLICY}
            >
              Privacy
            </Link>
          </Typography>
          <Typography variant='caption'>
            <Link color='inherit' component={RouterLink} to={routes.TERMS}>
              Terms
            </Link>
          </Typography>
          <Typography variant='caption'>
            <Link
              color='inherit'
              component={RouterLink}
              to={routes.SECURITY_POLICY}
            >
              Security
            </Link>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Link
            href={'https://www.facebook.com/riskaceonline'}
          >
            <FacebookIcon className={classes.icon} />
          </Link>
          <Link
            href={'https://www.instagram.com/riskace_online/'}
          >
            <InstagramIcon className={classes.icon} />
          </Link>
          <Link
            href={'https://www.linkedin.com/company/riskace/'}
          >
            <LinkedInIcon className={classes.icon} />
          </Link>
        </Box>
      </Container>
    </div >
  )
}

export default Footer
