import { useRef, useEffect } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'

import { GET_SESSION_LOG, SESSION_LOG_ENTRIES_ADDED } from '../graphql'
import { formatTimestamp } from '../util'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '300px',
      overflowY: 'scroll',
      padding: theme.spacing(1),
    },
    entry: {
      display: 'flex',
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(12),
    },
    t: {
      width: 150,
    },
  }
})

const SessionLog = ({ sessionId, skip }) => {
  const { data: { sessionLog } = {}, loading } = useQuery(GET_SESSION_LOG, {
    variables: { sessionId },
    skip,
  })
  const classes = useStyles()

  const paperRef = useRef(null)
  useEffect(() => {
    paperRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  useSubscription(SESSION_LOG_ENTRIES_ADDED, {
    variables: { sessionId },
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { sessionLogEntriesAdded },
      },
    }) => {
      const data = client.readQuery({
        query: GET_SESSION_LOG,
        variables: { sessionId },
      })
      client.writeQuery({
        query: GET_SESSION_LOG,
        variables: { sessionId },
        data: {
          sessionLog: [...data.sessionLog, ...sessionLogEntriesAdded],
        },
      })
    },
    skip: skip || loading,
  })
  return (
    <Paper className={classes.root} ref={paperRef}>
      {sessionLog &&
        sessionLog.map((e, i) => (
          <div key={`log${i}`} className={classes.entry}>
            <span className={classes.t}>{formatTimestamp(e.t, true)}</span>
            <span>{e.text}</span>
          </div>
        ))}
    </Paper>
  )
}

export default SessionLog
