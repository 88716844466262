import { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import MailOutline from '@material-ui/icons/MailOutline'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Lock from '@material-ui/icons/Lock'
import ReCAPTCHA from 'react-google-recaptcha'
import Link from '@material-ui/core/Link'

import ProgressButton from './ProgressButton'

import { REGISTER_USER, LOGIN } from '../graphql'
import { isValidEmail } from '../util'
import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      boxSizing: 'border-box',
    },
    content: {
      padding: theme.spacing(4, 6),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(2),
      },
    },
    profileInfo: {
      marginBottom: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '12rem',
      justifyContent: 'flex-start',
    },
    icon: {
      color: 'grey',
      paddingRight: theme.spacing(2),
    },
    marginBottom: {
      marginBottom: theme.spacing(1),
    },
    marginBottomDouble: {
      marginBottom: theme.spacing(2),
    },
    input: {
      width: '100%',
      maxWidth: '20rem',
      minHeight: '4.25rem',
    },
    button: {
      marginTop: '2rem',
      alignSelf: 'flex-end',
    },
    recaptcha: {
      marginTop: theme.spacing(1),
    },
    hidden: {
      display: 'none',
    },
    row: {
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',
    },
  }
})

const SignupForm = (props) => {
  const [email, setEmail] = useState((props.user && props.user.email) || '')
  const [emailError, setEmailError] = useState('')
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState()
  const [termsAgreed, setTermsAgreed] = useState(false)

  const nameInput = useRef(null)
  const passwordInput = useRef(null)
  const confirmPasswordInput = useRef(null)
  const captchaRef = useRef(null)

  const location = useLocation()
  const navigate = useNavigate()
  const referrer = location?.state?.referrer

  const [registerUser, { data, loading }] = useMutation(REGISTER_USER, {
    onCompleted: (data) => {
      const userStatus = data.registerUser?.user?.status
      if (userStatus === 'verified') {
        login()
      } else if (data.registerUser.status === 'ok') {
        navigate(routes.VERIFY_ACCOUNT, { state: { user: data.registerUser.user } })
      }
    }
  })

  const [login] = useMutation(LOGIN, {
    variables: {
      username: email,
      password,
    },
    onCompleted: (data) => {
      navigate(referrer || routes.RISK_ASSESSMENTS)
    }
  })

  const handleChangeEmail = (event) => {
    const newEmail = event.target.value
    setEmail(newEmail)
    setEmailError(isValidEmail(newEmail) ? '' : 'Invalid email address')
  }

  const handleEmailKeyPress = (e) => {
    if (e.key === 'Enter' && nameInput.current) {
      nameInput.current.focus()
    }
  }

  const handleChangeName = (event) => {
    const newName = event.target.value
    setName(newName)
    let error = ''
    if (!newName) {
      error = 'Display name is required'
    }
    setNameError(error)
  }

  const handleNameKeyPress = (e) => {
    if (e.key === 'Enter' && passwordInput.current) {
      passwordInput.current.focus()
    }
  }

  const handleChangePassword = (event) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (confirmPassword) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match')
      } else if (confirmPasswordError) {
        setConfirmPasswordError('')
      }
    }
    setPasswordError(error)
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && confirmPasswordInput.current) {
      confirmPasswordInput.current.focus()
    }
  }

  const handleChangeConfirmPassword = (event) => {
    const newPassword = event.target.value
    setConfirmPassword(newPassword)
    let error = ''
    if (!isValidPassword(newPassword)) {
      error = 'Password does not meet the requirements'
    } else if (newPassword) {
      if (newPassword !== password) {
        error = 'Passwords do not match'
      }
    }
    setConfirmPasswordError(error)
  }

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword)
  }

  const isValidPassword = (password) => {
    return password && password !== 'kuk'
  }

  const handleReCAPTCHAChanged = (response) => {
    setCaptchaResponse(response)
  }

  const { user, token } = props
  const classes = useStyles()

  const canSubmit = () => {
    return (
      isValidEmail(email) &&
      name &&
      isValidPassword(password) &&
      isValidPassword(confirmPassword) &&
      password === confirmPassword &&
      !loading &&
      captchaResponse &&
      termsAgreed
    )
  }

  const handleSubmit = (event) => {
    registerUser({
      variables: {
        input: { email, name, password, token, userId: user && user._id },
        recaptcha: captchaResponse,
      },
    })
    if (captchaRef.current) {
      setCaptchaResponse()
      captchaRef.current.reset()
    }
  }

  const handleConfirmPasswordKeyPress = (e) => {
    if (e.key === 'Enter' && canSubmit()) {
      handleSubmit(e)
    }
  }

  const handleCheckTerms = () => {
    setTermsAgreed(!termsAgreed)
  }

  const existingUser = Boolean(props.user && props.user.email)
  const getEmailError = () => {
    if (props.token && !props.user) {
      return 'The token is invalid or expired'
    }
    if (existingUser && props.user.status !== 'created') {
      return 'The email address is already in use'
    }
    if (data && data.registerUser) {
      if (data.registerUser.status === 'alreadyRegistered') {
        return 'The email address is already in use'
      } else if (data.registerUser.status === 'notFound') {
        return 'The user was not found'
      } else if (data.registerUser.status === 'invalidToken') {
        return 'The token is invalid or expired'
      }
      return undefined
    }
    return emailError
  }

  return (
    <Paper className={`${classes.root}`}>
      <div className={classes.content}>
        <form className={classes.form}>
          <div className={`${classes.row} ${classes.marginBottom}`}>
            <MailOutline className={classes.icon} />
            <FormControl
              required
              error={Boolean(getEmailError())}
              className={classes.input}
            >
              <InputLabel htmlFor='email'>Email</InputLabel>
              <Input
                id='email'
                type={'email'}
                disabled={existingUser}
                autoFocus={!existingUser}
                value={email}
                onChange={handleChangeEmail}
                onKeyPress={handleEmailKeyPress}
              />
              <FormHelperText>
                {getEmailError() || 'This will be your username'}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={`${classes.row} ${classes.marginBottomDouble}`}>
            <AccountCircle className={classes.icon} />
            <FormControl
              required
              error={Boolean(nameError)}
              className={classes.input}
            >
              <InputLabel htmlFor='name'>Display name</InputLabel>
              <Input
                id='name'
                type={'text'}
                value={name}
                autoFocus={existingUser}
                inputRef={nameInput}
                onChange={handleChangeName}
                onKeyPress={handleNameKeyPress}
              />
              {nameError && (
                <FormHelperText>Display name is required</FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={classes.row}>
            <Lock className={classes.icon} />
            <FormControl
              required
              error={Boolean(passwordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='password'>Password</InputLabel>
              <Input
                id='password'
                type={showPassword ? 'text' : 'password'}
                value={password}
                inputRef={passwordInput}
                onChange={handleChangePassword}
                onKeyPress={handlePasswordKeyPress}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordError && (
                <FormHelperText>{passwordError}</FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={classes.row}>
            <Lock className={classes.icon} />
            <FormControl
              required
              error={Boolean(confirmPasswordError)}
              className={classes.input}
            >
              <InputLabel htmlFor='confirmpassword'>
                Confirm Password
              </InputLabel>
              <Input
                id='confirmpassword'
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                inputRef={confirmPasswordInput}
                onChange={handleChangeConfirmPassword}
                onKeyPress={handleConfirmPasswordKeyPress}
              />
              {confirmPasswordError && (
                <FormHelperText>{confirmPasswordError}</FormHelperText>
              )}
            </FormControl>
          </div>
          <Box display='flex' alignItems='center'>
            <Checkbox
              size='small'
              checked={termsAgreed}
              onChange={handleCheckTerms}
            />
            <Box>
              <Typography variant='body1'>
                I agree to the{' '}
                <Link
                  color='secondary'
                  component={RouterLink}
                  target='_blank'
                  rel='noopener'
                  to={routes.TERMS}
                >
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
          </Box>
          <ReCAPTCHA
            className={`${classes.recaptcha}${confirmPassword ? '' : ' ' + classes.hidden
              }`}
            ref={captchaRef}
            sitekey={window._RA_RECAPTCHA_KEY}
            onChange={handleReCAPTCHAChanged}
          />
          <FormControl>
            <ProgressButton
              disabled={!canSubmit()}
              loading={loading}
              classes={{ root: classes.button }}
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              type='button'
            >
              SUBMIT
            </ProgressButton>
          </FormControl>
        </form>
      </div>
    </Paper>
  )
}

export default SignupForm
