import { useEffect, useState } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import Search from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'

import Avatar from '../components/Avatar'
import { SearchInput } from '../components/SessionsToolbar/SearchInput'
import useDebounce from '../utils/useDebounce'

const SEARCH_USER = gql`
  query ($term: String!) {
    searchOrgUser: searchOrgUser(term: $term) {
      _id
      name
      email
    }
  }
`

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(2),
    },
    search: {
      minWidth: '400px',
    },
    name: {
      marginLeft: theme.spacing(1),
    },
    item: {
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
      '&:last-child': {
        marginBottom: '36px',
        '&:hover': {
          zIndex: 1,
        },
      },
      '&.selected': {
        boxShadow: 'inset 0px 0px 0px 2px #b2dfdb',
      },
    },
    check: {
      position: 'absolute',
      right: '30px',
    },
    email: {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    searchIcon: {
      width: '150px',
      height: '150px',
      opacity: '40%',
      color: theme.palette.primary.light,
    },
    loadingProgress: {
      size: '64px',
      position: 'absolute',
      top: 'calc(50% - 32px)',
      left: 'calc(50% - 32px)',
    },
    result: {
      position: 'relative',
      '&:after': {
        position: 'absolute',
        bottom: theme.spacing(1),
        height: '100%',
        width: '98%',
        content: "'';",
        background:
          'linear-gradient(to top, rgba(255,255,255, 1) 10%, rgba(255,255,255, 0) 30%)',
        pointerEvents: 'none',
      },
    },
    resultContainer: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
      height: '200px',
      maxHeight: '200px',
      overflowY: 'scroll',
    },
  }
})

const InviteUserDialog = (props) => {
  const { open, onClose, owner } = props
  const classes = useStyles()

  const [term, setTerm] = useState('')
  const [debouncedTerm, setDebouncedTerm] = useDebounce(term, 300)

  const [queryTerm, setQueryTerm] = useState('')
  const [user, setUser] = useState()
  const [role, setRole] = useState('member')

  useEffect(() => {
    if (debouncedTerm?.length > 2) {
      setQueryTerm(debouncedTerm)
    }
  }, [debouncedTerm])

  const [searchOrgUser, { loading, data, called }] = useLazyQuery(SEARCH_USER, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (open && queryTerm) {
      searchOrgUser(
        {
          variables: { term: queryTerm },
        }
      )
    }
  }, [queryTerm, open, searchOrgUser])

  useEffect(() => {
    if (!open) {
      setDebouncedTerm('')
      setUser()
      setQueryTerm('')
      setRole('member')
    }
  }, [open, setDebouncedTerm])

  const handleSearchFilterChange = (value) => {
    setUser()
    setRole('member')
    setTerm(value)
  }

  const handleClickUser = (user) => {
    setUser(user)
  }

  const handleUserKeyPress = (user, e) => {
    if (e.key === 'Enter' || e.charCode === 32) {
      setUser(user)
    }
  }

  const handleChangeRole = (e) => {
    setRole(e.target.value)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleSave = () => {
    onClose && onClose(user, role)
  }

  const canInvite = () => {
    return user
  }

  const noResults = () => {
    return !called || debouncedTerm?.length < 3 || data?.searchOrgUser?.length === 0
  }

  const resultCount = () => {
    if (noResults()) {
      return 0
    }
    return data?.searchOrgUser?.length ?? 0
  }

  const getResultContentInner = () => {
    if (noResults()) {
      return (
        <Box
          position='relative'
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100%'
        >
          {loading && <CircularProgress className={classes.loadingProgress} />}
          <Search className={classes.searchIcon}></Search>
        </Box>
      )
    }
    return (
      <>
        {data?.searchOrgUser?.map((u) => (
          <Box
            tabIndex={0}
            className={`${classes.item}${u._id === user?._id ? ' selected' : ''
              }`}
            key={`searchOrgUser${u._id}`}
            boxSizing='border-box'
            borderRadius={'5px'}
            padding={0.5}
            marginY={0.25}
            display='flex'
            alignItems='center'
            width='100%'
            onClick={() => handleClickUser(u)}
            onKeyPress={(e) => handleUserKeyPress(u, e)}
          >
            <Avatar user={u} small></Avatar>
            <Typography className={classes.name}>{u.name}</Typography>
            <Typography className={classes.email}>{u.email}</Typography>
            {u._id === user?._id && (
              <CheckCircleOutlineIcon
                className={classes.check}
                color='secondary'
              />
            )}
          </Box>
        ))}
      </>
    )
  }

  const getResultContent = () => {
    return (
      <Paper className={classes.resultContainer}>
        {getResultContentInner()}
      </Paper>
    )
  }

  return (
    <>
      <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose}>
        <Box p={2}>
          <Typography variant='h6' className={classes.title}>
            Add user to organization
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <SearchInput
                classes={{ root: classes.search }}
                placeholder={'Enter user name or email (3 characters minimum)'}
                InputProps={{ autoFocus: true }}
                onChange={handleSearchFilterChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Search result:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align='right'>{`${resultCount()} matching users`}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.result}>
              {getResultContent()}
            </Grid>
            <Grid item xs={12}>
              <FormControl disabled={!(owner && user)} component="fieldset">
                <FormLabel component="legend">Role</FormLabel>
                <RadioGroup row aria-label="role" name="role" value={role} onChange={handleChangeRole}>
                  <FormControlLabel value="member" control={<Radio />} label="Member" />
                  <FormControlLabel value="admin" control={<Radio />} label="Administrator" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button
            disabled={!canInvite()}
            variant='contained'
            color='primary'
            onClick={handleSave}
          >
            Send invite
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InviteUserDialog
