import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'

import { REQUEST_PW_RESET } from '../graphql'

import BasicHeader from '../components/BasicHeader'
import Login from '../components/Login'
import ConfirmEmailDialog from '../components/ConfirmEmailDialog'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    links: {
      width: '100%',
      display: 'flex',
      fontSize: '14px',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    link: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    alert: {
      margin: '1rem',
    },
  }
})

const LoginPage = (props) => {
  const location = useLocation()
  const [showDialog, setShowDialog] = useState(false)
  const [resetRequested, setResetRequested] = useState(false)
  const [requestPwReset, { data: resetPwData }] = useMutation(REQUEST_PW_RESET)

  useEffect(() => {
    document.title = 'Login'
  })

  const handleClickForgotPassword = (e) => {
    setShowDialog(true)
  }

  const handeDialogClosed = (email, recaptcha) => {
    setShowDialog(false)
    if (email && recaptcha) {
      setResetRequested(true)
      requestPwReset({ variables: { email, recaptcha } })
    }
  }

  const classes = useStyles()
  const referrer = location?.state?.referrer
  return (
    <div className={classes.root}>
      <BasicHeader />
      {resetRequested && resetPwData?.requestPasswordReset && (
        <Alert className={classes.alert} severity='info'>
          An email with a link to reset your password was sent to your email
          address
        </Alert>
      )}
      <div className={classes.content}>
        <Login />
        <div className={classes.links}>
          <Link
            className={classes.link}
            to={{ pathname: '/signup', state: { referrer } }}
          >
            Sign up
          </Link>
          <Link
            className={classes.link}
            to='#'
            onClick={handleClickForgotPassword}
          >
            Forgot password?
          </Link>
        </div>
        <ConfirmEmailDialog
          open={showDialog}
          title='Request password reset'
          content='Enter the email address you used when registering'
          onClose={handeDialogClosed}
        />
      </div>
    </div>
  )
}

export default LoginPage
