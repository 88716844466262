import { gql } from '@apollo/client'
import { MatrixDetailsFragment } from './MatrixFragments'
import { UserDetailsFragment } from './UserFragments'

const ParticipantDetailsFragment = gql`
  fragment participantDetails on Participant {
    status
    user {
      ...userDetails
    }
  }
  ${UserDetailsFragment}
`

const RiskDetailsFragment = gql`
  fragment riskDetails on Risk {
    _id
    ownerId
    title
    description
    createdBy
    createdAt
    updatedAt
    position {
      x
      y
    }
    color
    value
    identifier
  }
`

const ActionDetailsFragment = gql`
  fragment actionDetails on Action {
    _id
    title
    description
    riskId
    ownerId
    resolutionAt
    priority
    status
    updatedAt
  }
`

const SessionDetailsFragment = gql`
  fragment sessionDetails on Session {
    _id
    name
    description
    owner {
      ...userDetails
    }
    organizationId
    organizationName
    status
    scheduledStartAt
    scheduledEndAt
    createdAt
    updatedAt
    closedAt
    matrix {
      ...matrixDetails
    }
  }
  ${UserDetailsFragment}
  ${MatrixDetailsFragment}
`

export {
  SessionDetailsFragment,
  ParticipantDetailsFragment,
  RiskDetailsFragment,
  ActionDetailsFragment,
}
