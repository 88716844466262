import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import './App.css'

import CookieNotice from './components/CookieNotice'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import VerifyAccountPage from './pages/VerifyAccountPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import PoliciesPage from './pages/PoliciesPage'
import AuthenticatedPages from './pages/AuthenticatedPages'
import AboutPage from './pages/AboutPage'
import ErrorBoundary from './components/ErrorBoundary'
import TwoFactorValidationPage from './pages/TwoFactorValidationPage'
import ArticlePage from './pages/ArticlePage'

const IndexPage = lazy(() => import('./pages/IndexPage'))

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#b2dfdb',
    },
    secondary: {
      main: '#9ccc65',
    },
  },
})

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ErrorBoundary>
      <Router>
        <CookieNotice />
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path='/' exact element={<IndexPage />} />
            <Route
              path='/login/2fa'
              exact
              element={<TwoFactorValidationPage />}
            />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path='/verifyAccount' element={<VerifyAccountPage />} />
            <Route path='/resetPassword' element={<ResetPasswordPage />} />
            <Route path='/policies/:policy' element={<PoliciesPage />} />
            <Route path='/about' element={<AboutPage />} />

            <Route path='/project-risks' element={<ArticlePage content='project-risks' />} />
            <Route path='/gdpr' element={<ArticlePage content='gdpr' />} />
            <Route path='/operational-risks' element={<ArticlePage content='operational-risks' />} />
            <Route path='/information-security' element={<ArticlePage content='information-security' />} />
            <Route path='/climate-risks' element={<ArticlePage content='climate-risks' />} />
            <Route path='/outsourcing' element={<ArticlePage content='outsourcing' />} />

            <Route path='*' element={<AuthenticatedPages />} />
          </Routes>
        </Suspense>
      </Router>
    </ErrorBoundary>
  </MuiThemeProvider>
)

export default App
