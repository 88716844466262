import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const RiskMenu = ({
  actions = ['edit', 'clone', 'delete', 'addAction'],
  canEdit,
  canClone,
  canDelete,
  canAddAction,
  onEdit,
  onClone,
  onDelete,
  onAddAction,
  ...props
}) => {
  const handleEdit = (evt) => {
    onEdit && onEdit(evt)
  }

  const handleClone = (evt) => {
    onClone && onClone(evt)
  }

  const handleDelete = (evt) => {
    onDelete && onDelete(evt)
  }

  const handleAddAction = (evt) => {
    onAddAction && onAddAction(evt)
  }

  return (
    <Menu {...props}>
      {actions.includes('edit') && (
        <MenuItem disabled={!canEdit} onClick={handleEdit}>
          Edit risk
        </MenuItem>
      )}
      {actions.includes('clone') && (
        <MenuItem disabled={!canClone} onClick={handleClone}>
          Clone risk
        </MenuItem>
      )}
      {actions.includes('delete') && (
        <MenuItem disabled={!canDelete} onClick={handleDelete}>
          Delete risk
        </MenuItem>
      )}
      {actions.includes('addAction') && (
        <MenuItem disabled={!canAddAction} onClick={handleAddAction}>
          Add action
        </MenuItem>
      )}
    </Menu>
  )
}

export default RiskMenu
