import { useQuery, useMutation } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { isForbiddenError } from '../util'
import {
  GET_PARTICIPANTS,
  GET_SESSION,
  UPDATE_PARTICIPANT_STATUS,
} from '../graphql'
import routes from '../routes'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-word',
    },
    paper: {
      margin: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    sessionDetails: {
      '& p': {
        margin: 0,
      },
      '& span': {
        fontWeight: 'bold',
      },
    },
  }
})

const handleOnClick = (evt, mutation, sessionId, action) => {
  mutation({
    variables: {
      sessionId,
      status: action,
    },
    update: (cache, { data: { updateParticipantStatus: participant } }) => {
      cache.modify({
        id: `Session:${sessionId}`,
        fields: {
          participants(cachedParticipants) {
            return cachedParticipants?.map((p) =>
              p.userId === participant.userId
                ? {
                  ...p,
                  status: participant.status,
                }
                : p
            )
          },
        },
      })
    },
  })
}

const renderAlert = (text, severity = 'error') => (
  <Box margin={2}>
    <Alert severity={severity}>{text}</Alert>
  </Box>
)

const AttendSession = (props) => {
  const {
    sessionId,
    auth: { user },
  } = props
  const classes = useStyles()

  const { data, loading, error } = useQuery(GET_SESSION, {
    variables: { id: sessionId },
  })
  const {
    data: { participants } = {},
    l2,
    e2,
  } = useQuery(GET_PARTICIPANTS, {
    variables: { sessionId },
  })

  const [updateParticipantStatus, { data: updateData }] = useMutation(
    UPDATE_PARTICIPANT_STATUS
  )

  if (loading || l2) return <div>Loading...</div>
  if (error || e2) {
    if (isForbiddenError(error)) {
      return renderAlert('The risk assessment is not accessible.')
    }
    return renderAlert('Oops something went wrong. Please try again.')
  }
  const session = data.session
  if (!session) {
    return renderAlert('Oops something went wrong. Please try again.')
  }
  if (session.owner?._id === user._id) {
    return <Navigate to={`${routes.RISK_ASSESSMENTS}/${sessionId}`} />
  }
  const participant = participants?.find((p) => p.user._id === user._id)
  if (!participant) {
    return renderAlert('The risk assessment could not be found.')
  }
  if (participant.status === 'joined') {
    return <Navigate to={`${routes.RISK_ASSESSMENTS}/${sessionId}`} />
  }

  const status = updateData && updateData.updateParticipantStatus.status
  if (status === 'joined') {
    return <Navigate to={`${routes.RISK_ASSESSMENTS}/${sessionId}`} />
  } else if (status === 'declined') {
    return renderAlert(
      'You have declined to attend this risk assessment, and the decision has been recorded.',
      'info'
    )
  }

  return (
    <Dialog
      className={classes.root}
      classes={{
        paper: classes.paper,
      }}
      open
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        Risk assessment invitation
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <p>You have been invited to join a risk assessment</p>
        <Grid container spacing={2} className={classes.sessionDetails}>
          <Grid item xs={12} sm={3}>
            <p>Name:</p>
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              <span>{session.name}</span>
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <p>Description:</p>
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              <i>{session.description}</i>
            </p>
          </Grid>
        </Grid>
        <p>Use the buttons below to accept or decline the invitation</p>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={(e) => {
            handleOnClick(e, updateParticipantStatus, sessionId, 'joined')
          }}
        >
          Join
        </Button>
        <Button
          className={classes.button}
          onClick={(e) => {
            handleOnClick(e, updateParticipantStatus, sessionId, 'declined')
          }}
        >
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AttendSession
