import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import WarningIcon from '@material-ui/icons/Warning'

import Avatar from './Avatar'
import RiskMenu from './RiskMenu'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(1, 0, 1, 1),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      '&.selected': {
        boxShadow: 'inset 0px 0px 0px 2px #b2dfdb',
      },
      '&:hover': {
        // same as rgba(0, 0, 0, 0.04) but doesn't mess up drag image
        backgroundColor: '#F5F5F5',
      },
    },
    listIndicator: {
      height: '0.75rem',
      width: '0.75rem',
      minHeight: '0.75rem',
      minWidth: '0.75rem',
      borderRadius: '2px',
      boxShadow: '1px 1px 2px darkgrey',
      opacity: '0.8',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    headerMain: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      flex: 1,
      marginLeft: theme.spacing(1),
    },
    skeleton: {
      flex: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    moreIcon: {
      padding: theme.spacing(1),
    },
    warning: {
      color: 'orange',
      marginRight: theme.spacing(1),
    },
  }
})

const Risk = (props) => {
  const {
    risk,
    owner,
    loading,
    needsAction,
    selected,
    includeEdit,
    canDrag,
    canClone,
    canDelete,
    canAddAction,
    onEdit,
    onClone,
    onDelete,
    onClick,
    onAddAction,
    onActionWarningClick,
  } = props
  const classes = useStyles()

  const [menuAnchor, setMenuAnchor] = useState()

  const handleClick = (e) => {
    if (onClick) {
      onClick(e, risk)
    }
  }

  const handleDrag = (e) => {
    window.getSelection().removeAllRanges()
    if (canDrag) {
      e.dataTransfer.setData('text', e.target.id)
      e.dataTransfer.dropEffect = 'move'
    }
  }

  const handleClone = (evt) => {
    evt.stopPropagation()
    setMenuAnchor()
    onClone && onClone(risk)
  }

  const handleEdit = (evt) => {
    evt.stopPropagation()
    setMenuAnchor()
    onEdit && onEdit(risk)
  }

  const handleDelete = (evt) => {
    evt.stopPropagation()
    setMenuAnchor()
    onDelete && onDelete(risk)
  }

  const handleAddAction = (evt) => {
    evt.stopPropagation()
    setMenuAnchor()
    onAddAction && onAddAction(risk)
  }

  const handleClickMoreMenu = (evt) => {
    evt.stopPropagation()
    setMenuAnchor(evt.currentTarget)
  }

  const handleCloseMoreMenu = (evt) => {
    setMenuAnchor()
  }

  const handleActionWarningClick = (evt) => {
    evt.stopPropagation()
    if (onActionWarningClick) {
      onActionWarningClick(risk)
    }
  }

  const showMenu = Boolean(menuAnchor)
  const riskActions = ['clone', 'delete', 'addAction']
  includeEdit && riskActions.push('edit')

  return (
    <Paper
      className={`${classes.root}${selected ? ' selected' : ''}`}
      onClick={handleClick}
      draggable={canDrag}
      onDragStart={handleDrag}
      id={risk?._id}
      data-riskid={risk?._id}
    >
      <div className={classes.header}>
        <div className={classes.headerMain}>
          <div
            className={classes.listIndicator}
            style={{
              backgroundColor:
                risk?.position && risk.color ? risk.color : 'transparent',
            }}
          />
          {loading && (
            <Skeleton variant='text' className={classes.skeleton}>
              <Typography className={classes.title}>
                Dummy text just to size the skeleton Dummy text just to size
              </Typography>
            </Skeleton>
          )}
          {!loading && (
            <Typography
              className={classes.title}
            >{`${risk?.identifier}. ${risk?.title}`}</Typography>
          )}
          {needsAction && (
            <Tooltip title='Click to add action'>
              <div>
                <WarningIcon
                  className={classes.warning}
                  onClick={handleActionWarningClick}
                />
              </div>
            </Tooltip>
          )}
          <Avatar user={owner} small />
          <IconButton
            classes={{ root: classes.moreIcon }}
            disabled={!risk || loading}
            onClick={handleClickMoreMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <RiskMenu
            open={showMenu}
            anchorEl={menuAnchor}
            actions={riskActions}
            onClose={handleCloseMoreMenu}
            canEdit={true}
            canClone={canClone}
            canAddAction={canAddAction}
            canDelete={canDelete}
            onEdit={handleEdit}
            onClone={handleClone}
            onDelete={handleDelete}
            onAddAction={handleAddAction}
          />
        </div>
      </div>
    </Paper>
  )
}

export default Risk
