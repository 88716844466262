import { Component } from 'react'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box margin={2}>
          <Alert severity='error'>
            Oops something went wrong. Please try again.
          </Alert>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
