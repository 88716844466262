import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import EnableTwoFactorAuthDialog from './EnableTwoFactorAuthDialog'
import DisableTwoFactorAuthDialog from './DisableTwoFactorAuthDialog'

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: 'flex',
      maxWidth: '600px',
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
    },
  }
})

const MFASection = ({ enabled, subject, refetch }) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
    refetch && refetch({ fetchPolicy: 'network-only' })
  }

  const handleTwoFactorAuthChanged = (enabled) => {}
  return (
    <>
      <Paper className={classes.paper}>
        <Box m={2} display='flex' flexDirection='column' flex='1'>
          <Box display='flex' alignItems='center'>
            <Typography variant='body1'>
              {enabled ? 'Enabled' : 'Disabled'}
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={handleClick}
            >
              EDIT
            </Button>
          </Box>
          {!enabled && (
            <Alert className={classes.alert} severity='info'>
              For security reasons we recommend you to enable two-factor
              authentication with Google Authenticator or compatible app.
            </Alert>
          )}
        </Box>
      </Paper>
      <EnableTwoFactorAuthDialog
        open={!enabled && showDialog}
        onClose={handleClose}
        subject={subject}
        onEnabled={handleTwoFactorAuthChanged}
      />
      <DisableTwoFactorAuthDialog
        open={Boolean(enabled && showDialog)}
        onClose={handleClose}
        onDisabled={handleTwoFactorAuthChanged}
        username={subject}
      ></DisableTwoFactorAuthDialog>
    </>
  )
}

export default MFASection
