import { gql } from '@apollo/client'

const CurrentUserFragment = gql`
  fragment currentUser on User {
    _id
    name
    email
    organizationId
    organizationRole
    status
    lastActiveAt
    testUser
    mfa {
      enabled
    }
  }
`

export { CurrentUserFragment }
