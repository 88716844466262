import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => {
  return {
    paper: ({ disabled, clickable }) => {
      let result = {
        position: 'relative',
        height: '100%',
        minWidth: '150px',
      }
      if (clickable) {
        if (!disabled) {
          result['&:hover'] = {
            boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.light}`,
          }
        }
        result.cursor = disabled ? 'not-allowed' : 'pointer'
      }
      return result
    },
    planBox: {
      minHeight: 96,
      height: 96,
      '& div span': {
        fontSize: theme.typography.h2.fontSize,
      },
      '& * sup': {
        fontSize: theme.typography.h4.fontSize,
      },
      '& h6': {
        fontSize: theme.typography.h4.fontSize,
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
    },
    price: {
      fontWeight: 500,
    },
    planFeatures: {
      height: 115,
      '& > ul': {
        paddingInlineStart: '1rem',
        paddingLeft: '1rem',
      },
      '& * li': {
        listStyle: 'square',
        whiteSpace: 'nowrap',
      },
    },
    selected: {
      boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.light}`,
    },
    disabled: {
      opacity: '50%',
    },
  }
})

const PlanCard = ({ plan, disabled, selected, onClick }) => {
  const classes = useStyles({ disabled, clickable: onClick })

  const handleClick = (evt) => {
    !disabled && onClick && onClick(evt, plan)
  }

  const PLANS = {
    lite: {
      label: 'Lite',
      price: (
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          justifyContent='space-around'
          alignItems='center'
          className={classes.planBox}
        >
          <Box display='flex' alignItems='flex-start'>
            <span>
              <sup>$</sup>19
            </span>
          </Box>
          <span>Per month</span>
        </Box>
      ),
      features: [
        'One user',
        'Access to selected default matrixes',
        '5 assessments per month',
        'Free 30 day trial',
      ],
    },
    enterprise: {
      label: 'Enterprise',
      price: (
        <Box display='flex' alignItems='center' className={classes.planBox}>
          <Button
            color='secondary'
            onClick={(e) => {
              window.location.href = `mailto:info\u0040riskace.com`
            }}
          >
            CONTACT US
          </Button>
        </Box>
      ),
      features: [
        'Unlimited assessments',
        'Custom risk models',
        'Custom reports',
        'On-prem / private deployment',
      ],
    },
  }
  const planInfo = PLANS[plan]
  return (
    <Paper
      className={`${classes.paper} ${selected && classes.selected}`}
      onClick={handleClick}
    >
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='space-around'
        alignItems='center'
        padding={2}
        className={`${disabled && classes.disabled}`}
      >
        <Typography variant='h6'>{planInfo.label}</Typography>
        {planInfo.price}
        <Box className={classes.planFeatures}>
          <ul>
            {planInfo.features?.map((f, i) => (
              <Typography key={`${plan}${i}`} component='li' variant='body2'>
                {f}
              </Typography>
            ))}
          </ul>
        </Box>
      </Box>
    </Paper>
  )
}

export default PlanCard
