import { useEffect } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AUTH } from '../graphql/Auth'
import { TOKEN_USER, LOGOUT } from '../graphql'
import routes from '../routes'

const joinSessionRe = /^\/riskassessments\/.+\/join/

function withAuth(WrappedComponent) {
  return (props) => {

    const location = useLocation()
    const pathname = location?.pathname
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    const { data, loading, error, refetch } = useQuery(AUTH)
    const {
      data: tokenUserData,
      loading: loadingTokenUser,
      error: loadingTokenError,
    } = useQuery(TOKEN_USER, { variables: { token }, skip: !token })

    const [logout] = useMutation(LOGOUT, {
      onCompleted: (data) => {
        if (data.logout) {
          navigate(routes.LOGIN)
        }
      }
    })

    const client = useApolloClient()

    useEffect(() => {
      if (
        data &&
        token &&
        data.currentUser &&
        tokenUserData &&
        !loadingTokenUser
      ) {
        if (
          !tokenUserData.tokenUser ||
          data.currentUser._id !== tokenUserData.tokenUser._id
        ) {
          logoutWrapper()
        }
      }
    })

    const logoutWrapper = (opts) => {
      return new Promise((resolve, reject) => {
        logout(opts)
          .then((result) => {
            client.resetStore()
            resolve(result)
          })
          .catch((e) => reject(e))
      })
    }

    const renderNavigate = (to) => (
      <Navigate
        to={{
          pathname: to,
          search: `?${searchParams}`,
        }}
        state={{
          referrer: pathname,
        }}
      />
    )

    if (loading || (token && loadingTokenUser))
      return (
        <Box
          display='flex'
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
          width={'100vw'}
        >
          <CircularProgress />
        </Box>
      )
    if (error || loadingTokenError || (data && !data.currentUser)) {
      if (pathname && joinSessionRe.test(pathname) && token) {
        return renderNavigate(routes.SIGNUP)
      }
      return renderNavigate(routes.LOGIN)
    }

    return (
      <WrappedComponent
        auth={{
          user: data.currentUser,
          logout: logoutWrapper,
          refetch,
          org: data.organization,
          invitations: data.invitations,
        }}
        {...props}
      />
    )
  }
}

export default withAuth
