import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Delete from '@material-ui/icons/Delete'
import AddToPhotos from '@material-ui/icons/AddToPhotos'
import Schedule from '@material-ui/icons/Schedule'
import Stop from '@material-ui/icons/Stop'
import Replay from '@material-ui/icons/Replay'
import CloudDownload from '@material-ui/icons/CloudDownload'
import MoreVert from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'

import {
  DELETE_SESSION,
  GET_SESSIONS,
  CLONE_SESSION,
  UPDATE_SESSION,
} from '../graphql'
import { generateCsv } from '../utils/csvUtils'
import { isGuest, canClone, isAdministrator } from '../util'
import routes from '../routes'
import ScheduleSessionDialog from './ScheduleSessionDialog'
import UserMenu from './UserMenu'
import ImportRisksDialog from './ImportRisksDialog'
import ConfirmationDialog from './ConfirmationDialog'
import SessionReportDialog from './SessionReportDialog'
import SessionCloneDialog from './SessionCloneDialog'

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      borderBottom: 'solid 1px lightgrey',
      width: '100%',
      boxSizing: 'border-box',
    },
    toolbarDivider: {
      margin: theme.spacing(0, 2),
      height: theme.spacing(4),
      width: '1px',
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
  }
})

const ITEM_HEIGHT = 48

const SessionToolbar = ({
  auth,
  session,
  risks,
  users,
  loading,
  cleanupRisks,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const exportRisksRef = useRef()

  const [showScheduleDialog, setShowScheduleDialog] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogProps, setDialogProps] = useState({})
  const [menuAnchor, setMenuAnchor] = useState()
  const [showImportRisks, setShowImportRisks] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState()
  const [showReportDialog, setShowReportDialog] = useState(false)
  const [showCloneDialog, setShowCloneDialog] = useState(false)
  const [exportData, setExportData] = useState()

  const [cloneSession] = useMutation(CLONE_SESSION, { ignoreResults: true })
  const [deleteSession] = useMutation(DELETE_SESSION, {
    ignoreResults: true,
    onCompleted: (data) => {
      navigate(-1)
    },
  })
  const [updateSession] = useMutation(UPDATE_SESSION, { ignoreResults: true })

  useEffect(() => {
    if (exportData) {
      exportRisksRef.current?.click()
    }
  }, [exportData])

  const handleClickBack = () => {
    navigate(-1)
  }

  const handleClickMoreMenu = (evt) => {
    setMenuAnchor(evt.currentTarget)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const handleCloseMoreMenu = (evt) => {
    setMenuAnchor()
  }

  const handleCloseReportDialog = () => {
    setShowReportDialog(false)
  }

  const handleChangeOwner = (e) => {
    setMenuAnchor()
    setShowUserMenu({ anchor: e.currentTarget })
  }

  const handleCloseCloneDialog = (e, reason, result) => {
    setShowCloneDialog(false)
    if (result) {
      const { includeUsers: participants, includeActionPlan: actions } = result
      cloneSession({
        variables: {
          id: session._id,
          options: { participants, risks: true, actions },
        },
        update: (cache, { data: { cloneSession } }) => {
          if (
            cache.data.data.ROOT_QUERY &&
            cache.data.data.ROOT_QUERY.sessions
          ) {
            const data = cache.readQuery({ query: GET_SESSIONS })
            cache.writeQuery({
              query: GET_SESSIONS,
              data: {
                sessions: [...data.sessions, cloneSession],
              },
            })
          }
          if (cloneSession?._id) {
            navigate(`${routes.RISK_ASSESSMENTS}/${cloneSession?._id}`)
          }
        },
      })
    }
  }

  const handleCleanupRisks = (e) => {
    setMenuAnchor()
    cleanupRisks({ variables: { sessionId: session._id } })
  }

  const handleCloseUserMenu = () => {
    setShowUserMenu()
  }

  const handleImportRisks = (e) => {
    setMenuAnchor()
    setShowImportRisks(true)
  }

  const handleExportRisks = (e) => {
    setMenuAnchor()
    setExportData({
      csvData: URL.createObjectURL(
        new Blob(['\ufeff' + generateCsv(risks)], { type: 'text/csv', })
      ),
      fileName: `${session.name} ${moment(new Date()).format(
        'YYYYMMDD-HHMM'
      )}.csv`,
    })
  }

  const handleCloseImportRisksDialog = (e) => {
    setShowImportRisks(false)
  }

  const handleCloseScheduleSessionDialog = (
    startDate,
    endDate,
    sendInvitations
  ) => {
    setShowScheduleDialog(false)
    if (startDate && endDate) {
      updateSession({
        variables: {
          sessionId: session._id,
          input: {
            scheduledStartAt: startDate,
            scheduledEndAt: endDate,
            sendCalendarInvites: sendInvitations,
          },
        },
      })
    }
  }

  const isOwner = session?.owner?._id === auth.user._id
  const isAdmin = isAdministrator(session, auth.user)
  const isGuestUser = isGuest(auth?.org?.billing)
  const potentialOwners = users?.filter?.(u => u._id !== session?.owner?._id) ?? []

  const handleGeneratePdf = () => {
    setShowReportDialog(true)
  }

  const handleClone = () => {
    setShowCloneDialog(true)
  }

  const handleDelete = (evt) => {
    setDialogProps({
      title: 'Delete risk assessment',
      content: 'Permanently delete risk assessment?',
      confirmButtonText: 'DELETE',
      data: session,
      handleConfirm: (data) => {
        setShowDialog(false)
        deleteSession({
          variables: { id: session._id },
          update: (cache, { data: { deleteSession } }) => {
            if (
              cache.data.data.ROOT_QUERY &&
              cache.data.data.ROOT_QUERY.sessions
            ) {
              const data = cache.readQuery({ query: GET_SESSIONS })
              cache.writeQuery({
                query: GET_SESSIONS,
                data: {
                  sessions: data.sessions.filter((s) => s._id !== session._id),
                },
              })
            }
          },
        })
      },
    })
    setShowDialog(true)
  }

  const changeSessionOwner = (newOwner) => {
    updateSession({
      variables: {
        sessionId: session._id,
        input: {
          ownerId: newOwner._id,
        },
      },
    })
  }

  const handleUserMenuSelect = (user) => {
    setShowUserMenu()
    if (!user) {
      return
    }
    if (user.organizationId === session.organizationId) {
      changeSessionOwner(user)
      return
    }
    setDialogProps({
      title: 'Change owner',
      content: `Change owner to ${user.email} outside of your organization?`,
      confirmButtonText: 'CONFIRM',
      data: session,
      handleConfirm: (data) => {
        setShowDialog(false)
        changeSessionOwner(user)
      },
    })
    setShowDialog(true)
  }

  const menuOpen = Boolean(menuAnchor)
  let tooltipText = 'Close'
  let newStatus
  let Icon = Stop
  switch (session?.status) {
    case 'open':
      tooltipText = 'Close'
      newStatus = 'closed'
      Icon = Stop
      break
    case 'closed':
      tooltipText = 'Reopen'
      newStatus = 'open'
      Icon = Replay
      break
    default:
  }

  const handleStatusChange = () => {
    updateSession({
      variables: {
        sessionId: session._id,
        input: {
          status: newStatus,
        },
      },
    })
  }

  const handleClickScheduleMeeting = () => {
    setShowScheduleDialog(true)
  }

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Tooltip TransitionComponent={Zoom} title='Back'>
          <IconButton
            size='small'
            aria-label='Risk assessments'
            onClick={handleClickBack}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <Divider className={classes.toolbarDivider} />
        <Tooltip TransitionComponent={Zoom} title={tooltipText}>
          <div>
            <IconButton
              disabled={!isOwner || loading}
              onClick={handleStatusChange}
            >
              <Icon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Schedule meeting'>
          <div>
            <IconButton
              disabled={loading || !isOwner || session.status !== 'open'}
              aria-label='Schedule'
              onClick={handleClickScheduleMeeting}
            >
              <Schedule />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Generate report'>
          <div>
            <IconButton
              disabled={loading}
              aria-label='Generate report'
              onClick={handleGeneratePdf}
            >
              <CloudDownload />
            </IconButton>
          </div>
        </Tooltip>
        <ScheduleSessionDialog
          open={showScheduleDialog}
          onClose={handleCloseScheduleSessionDialog}
        />
        <Divider className={classes.toolbarDivider} />
        <Tooltip TransitionComponent={Zoom} title='Clone'>
          <div>
            <IconButton
              disabled={
                loading || isGuestUser || !canClone(auth?.org, session)
              }
              aria-label='Clone'
              onClick={handleClone}
            >
              <AddToPhotos />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip TransitionComponent={Zoom} title='Delete'>
          <div>
            <IconButton
              disabled={loading || !isOwner}
              aria-label='Delete'
              onClick={handleDelete}
            >
              <Delete />
            </IconButton>
          </div>
        </Tooltip>
        <IconButton
          aria-label='More'
          disabled={loading || session.status === 'closed'}
          onClick={handleClickMoreMenu}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id='more-menu'
          anchorEl={menuAnchor}
          open={menuOpen}
          onClose={handleCloseMoreMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 130,
            },
          }}
        >
          <MenuItem disabled={loading || !risks} onClick={handleCleanupRisks}>
            Clean up risks
          </MenuItem>
          <MenuItem
            disabled={
              loading ||
              !isAdmin ||
              session.status === 'closed' ||
              potentialOwners.length === 0
            }
            onClick={handleChangeOwner}
          >
            Change owner
          </MenuItem>
          <MenuItem
            key='ir'
            disabled={loading || session.status === 'closed'}
            onClick={handleImportRisks}
          >
            Import risks...
          </MenuItem>
          <MenuItem
            key='er'
            disabled={loading || !risks?.length}
            onClick={handleExportRisks}
          >
            Export risks
          </MenuItem>
        </Menu>
      </Toolbar>
      {!loading && (
        <>
          <a
            style={{ display: 'none' }}
            download={exportData?.fileName}
            href={exportData?.csvData}
            ref={exportRisksRef}
          >
            download risks
          </a>
          <UserMenu
            open={showUserMenu !== undefined}
            users={potentialOwners}
            organizationId={session.organizationId}
            anchorEl={showUserMenu && showUserMenu.anchor}
            onClose={handleCloseUserMenu}
            onSelect={handleUserMenuSelect}
          />
          <ImportRisksDialog
            open={showImportRisks}
            onClose={handleCloseImportRisksDialog}
            sessionId={session._id}
            matrixSize={session.matrix.size}
          />
          <ConfirmationDialog
            open={showDialog}
            title={dialogProps.title}
            content={dialogProps.content}
            confirmButtonText={dialogProps.confirmButtonText}
            handleClose={handleClose}
            handleConfirm={dialogProps.handleConfirm}
            data={dialogProps.data}
          />
          <SessionReportDialog
            organizationId={auth?.user?.organizationId}
            sessionId={session._id}
            open={showReportDialog}
            onClose={handleCloseReportDialog}
          />
          <SessionCloneDialog
            sessionId={session._id}
            open={showCloneDialog}
            onClose={handleCloseCloneDialog}
          />
        </>
      )}
    </>
  )
}

export default SessionToolbar
