import moment from 'moment'
import { PLANS, isEligible } from './plans'

const getCellPosition = (pos) => {
  const left = (pos.x % 1) * 100
  const top = 100 - (pos.y % 1) * 100
  return { top: `${top}%`, left: `${left}%` }
}

const isForbiddenError = (error) => {
  if (error && error.graphQLErrors) {
    return error.graphQLErrors.some((element) => {
      return element.extensions && element.extensions.code === 'FORBIDDEN'
    })
  }
  return false
}

const formatTimestamp = (t, includeSeconds) => {
  return moment(new Date(t)).format(includeSeconds ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD HH:mm')
}

const formatDate = (t) => {
  return moment(new Date(t)).format('YYYY/MM/DD')
}


const getDateString = (date) => {
  let format
  const now = moment()
  if (date < now) {
    return moment(date).fromNow()
  } else if (date < now.endOf('day')) {
    format = 'HH:mm'
  } else if (date < now.endOf('year')) {
    format = 'MMM D'
  } else {
    format = 'll'
  }
  return moment(date).format(format)
}

const emailRE =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

const isValidEmail = (email) => {
  return email && emailRE.test(email)
}

const getInitials = (user) => {
  if (!user) {
    return ''
  }
  let words
  if (user.name) {
    words = user.name.toUpperCase().split(' ')
  } else if (user.email) {
    words = user.email.toUpperCase().split('@')[0].split('.')
  }
  if (!words) {
    return ''
  }
  let result = ''
  let i = 0
  for (let w of words) {
    if (w) {
      result += w.charAt(0)
      if (i++ > 1) {
        break
      }
    }
  }
  return result
}

const graphQLUrl = new URL(`${window._RA_API_URL}/graphql`)
const wsUrl = new URL(graphQLUrl)
if (graphQLUrl.protocol === 'https:') {
  wsUrl.protocol = 'wss:'
} else {
  wsUrl.protocol = 'ws:'
}

const getGraphQLUrl = () => {
  return graphQLUrl
}

const getWebsocketUrl = () => {
  return wsUrl
}

const downloadReport = () => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isFirefox = typeof InstallTrigger !== 'undefined'
  return isIE || isEdge || isFirefox
}

const isGuest = (billingInfo) => {
  if (!billingInfo) return true
  if (billingInfo.plan && billingInfo.plan !== PLANS.TRIAL) {
    return false
  }
  const expiresAt = billingInfo.expiresAt && moment(billingInfo.expiresAt)
  if (!expiresAt) return false
  return expiresAt?.isBefore(moment()) ?? true
}

const canClone = (org, session) => {
  return isEligible(org?.billing?.plan, session?.matrix?.plan)
}

const addColorAndValueToRisk = (risk, matrix) => {
  if (!risk.position) {
    return
  }
  const x = Math.trunc(risk.position.x)
  const y = Math.trunc(risk.position.y)
  risk.color = matrix.cells[y][x].color
  risk.value =
    matrix.cells[y][x].value !== undefined ? matrix.cells[y][x].value : x * y
}

const sortRisks = (risks, matrix) => {
  if (!risks) return
  risks.sort((a, b) => {
    if (!b.position && a.position) {
      return -1
    }
    if (b.position && !a.position) {
      return 1
    }
    if (!a.position && !b.position) {
      return 0
    }
    const valueA =
      matrix.cells[Math.trunc(a.position.y)][Math.trunc(a.position.x)].value
    const valueB =
      matrix.cells[Math.trunc(b.position.y)][Math.trunc(b.position.x)].value
    if (valueA > valueB) {
      return -1
    }
    if (valueA < valueB) {
      return 1
    }
    const productB = (b.position.x % 1) * (b.position.y % 1)
    const productA = (a.position.x % 1) * (a.position.y % 1)
    if (productA > productB) {
      return -1
    }
    if (productA < productB) {
      return 1
    }
    return 0
  })
}

const numberTo2Decimals = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

const isAdministrator = (session, user) => {
  if (!session || !user) {
    return false
  }
  if (session.owner?._id === user._id) {
    return true
  }
  if (session.organizationId === user.organizationId && ['owner', 'admin'].includes(user.organizationRole)) {
    return true
  }
  return false
}

export {
  getGraphQLUrl,
  getWebsocketUrl,
  isForbiddenError,
  formatTimestamp,
  formatDate,
  getDateString,
  getCellPosition,
  isValidEmail,
  getInitials,
  downloadReport,
  isGuest,
  canClone,
  addColorAndValueToRisk,
  sortRisks,
  numberTo2Decimals,
  isAdministrator,
}
