import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import MoreVert from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'

import { formatDate } from '../util'
import Avatar from './Avatar'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    }
  },
  main: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    '&.owner': {
      backgroundColor: theme.palette.secondary.light,
      color: 'white',
    },
    '&.declined': {
      backgroundColor: theme.palette.error.light,
      color: 'white',
    },
    '&.pending': {
      backgroundColor: theme.palette.warning.light,
      color: 'white',
    },
    '&.archived': {
      backgroundColor: theme.palette.error.light,
      color: 'white',
    }
  },
  secondary: {
    minWidth: 120,
    color: theme.palette.primary.dark,
  },
}))

const getText = (member) => {
  switch (member.status) {
    case 'pending':
      return `Invited: ${formatDate(member.createdAt)}`
    case 'declined':
      return `Declined: ${formatDate(member.updatedAt)}`
    case 'archived':
      return `Archived: ${formatDate(member.updatedAt)}`
    default:
      return `Added: ${formatDate(member.createdAt)}`
  }
}

const getChip = (member, owner, className) => {
  let label
  let _className
  switch (member?.status) {
    case 'active':
      if (owner) {
        label = 'Owner'
        _className = `${className} owner`
      } else if (member.role !== 'admin') {
        return undefined
      } else {
        label = 'Administrator'
        _className = `${className} admin`
      }
      break
    case 'pending':
      label = 'Pending'
      _className = `${className} pending`
      break
    case 'declined':
      label = 'Declined'
      _className = `${className} declined`
      break
    case 'archived':
      label = 'Archived'
      _className = `${className} archived`
      break
    default:
      return undefined
  }
  return <Chip className={_className} size='small' color='primary' label={label} />
}

const OrganizationMember = ({ member, owner, showMenu, onMenuClick, ...props }) => {
  const classes = useStyles()

  const handleMenuClick = (evt) => {
    onMenuClick?.(evt)
  }
  return <Box
    className={classes.root}
    display='flex'
    alignItems='center'
    padding={1}
    borderRadius={'5px'}
    {...props}
  >
    <Box display='flex' alignItems='center' flex='1'>
      <Avatar user={member?.user} small></Avatar>
      <Typography className={classes.main}>{member?.user?.name}</Typography>
      <Typography className={classes.main} variant='caption'>{member?.user?.email}</Typography>
    </Box>
    {getChip(member, owner, classes.chip)}
    <Typography className={classes.secondary} variant='caption'>{getText(member)}</Typography>
    {showMenu && <IconButton size='small' onClick={handleMenuClick}>
      <MoreVert />
    </IconButton>}
  </Box>
}

export default OrganizationMember