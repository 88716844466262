import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { CookiesProvider, Cookies } from 'react-cookie'

import moment from 'moment'
import 'moment/locale/sv'
import './index.css'
import App from './App'
import { getGraphQLUrl, getWebsocketUrl } from './util'

console.log(getGraphQLUrl().toString())
moment.locale('sv')
const wsLink = new WebSocketLink({
  uri: getWebsocketUrl(),
  options: {
    reconnect: true,
    lazy: true,
    // Needs to be a function so a new token is obtained when reconnecting
    connectionParams: () => ({
      token: new Cookies().get('ra.sid'),
    }),
  },
})

const httpLink = new HttpLink({
  uri: getGraphQLUrl(),
  credentials: 'include',
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) {
        console.log(`[Network error]: ${JSON.stringify(networkError)}`)
        // see https://github.com/apollographql/subscriptions-transport-ws/issues/171
        if (networkError.message === 'ws-auth-error') {
          const token = new Cookies().get('ra.sid')
          if (token) {
            // Reset the WS connection for it to carry the new JWT.
            wsLink.subscriptionClient.close(false, false)
          }
        }
      }
    }),
    link,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Risk: {
        fields: {
          position: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          sessions: {
            merge: false,
          },
          risks: {
            merge: false,
          },
          actions: {
            merge: false,
          },
          sessionParticipants: {
            merge: false,
          },
        },
      },
      Mutation: {
        fields: {
          cleanupRisks: {
            merge: false,
          },
        },
      },
    },
  }),
})

const ApolloProviderApp = () => (
  <ApolloProvider client={client}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </ApolloProvider>
)

ReactDOM.render(<ApolloProviderApp />, document.getElementById('root'))
