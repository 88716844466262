import { gql } from '@apollo/client'

const UserDetailsFragment = gql`
  fragment userDetails on User {
    _id
    name
    email
    organizationId
    status
    lastActiveAt
  }
`

export { UserDetailsFragment }
