import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import routes from '../routes'
import { SEND_ACCOUNT_VERIFICATION, VERIFY_ACCOUNT } from '../graphql'
import BasicHeader from '../components/BasicHeader'
import ConfirmEmailDialog from '../components/ConfirmEmailDialog'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      fontSize: '14px',
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      maxWidth: 600,
    },
    logo: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      width: 150,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    message: {
      '& span': {
        fontWeight: 'bolder',
      },
    },
  }
})

const VerifyAccountPage = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [verified, setVerified] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [emailRequested, setEmailRequested] = useState()

  useEffect(() => {
    document.title = 'Account verification'
  })

  useEffect(() => {
    if (verified) {
      navigate(routes.LOGIN)
    }
  }, [verified, navigate])

  const classes = useStyles()

  const token = searchParams.get('token')
  const user = location?.state?.user

  const { data, loading, error } = useQuery(VERIFY_ACCOUNT, {
    variables: { token },
    skip: !token,
    onCompleted: (_) => {
      if (data && data.verifyAccount) {
        setTimeout(() => {
          setVerified(true)
        }, 3000)
      }
    },
  })

  const [sendAccountVerification] = useMutation(SEND_ACCOUNT_VERIFICATION, {
    onCompleted: (data) => {
      setEmailRequested({
        ...emailRequested,
        status: data.sendAccountVerification,
      })
    },
  })

  const handleOnClick = () => {
    setShowDialog(true)
  }

  const handeDialogClosed = (email, recaptcha) => {
    setShowDialog(false)
    if (email && recaptcha) {
      sendAccountVerification({ variables: { email, recaptcha } })
      setEmailRequested({ email })
    }
  }

  if (!(user?.status === 'pendingVerification') && !token) {
    return <Navigate to={routes.MAIN} />
  }

  let message
  if (emailRequested) {
    if (!emailRequested.hasOwnProperty('status')) {
      message = (
        <p className={classes.message}>
          Sending email to <span>{emailRequested.email}...</span>
        </p>
      )
    } else {
      if (emailRequested.status) {
        message = (
          <p className={classes.message}>
            We've sent an email to <span>{emailRequested.email}</span>. Head
            over to your inbox and verify your account!
          </p>
        )
      } else {
        message = (
          <p className={classes.message}>
            There was a problem sending the email to{' '}
            <span>{emailRequested.email}</span>. Double-check the email address
            and try later.
          </p>
        )
      }
    }
  } else if (token) {
    if (loading) {
      message = (
        <p className={classes.message}>
          Hold on while we are activating your account...
        </p>
      )
    }
    if (error) {
      message = (
        <p className={classes.message}>
          The was a problem activating your account. Please try again later.
        </p>
      )
    }
    if (data) {
      if (data.verifyAccount) {
        message = (
          <p className={classes.message}>
            Your account has been activated! You will be redirected to the login
            page.
          </p>
        )
      } else {
        message = (
          <p className={classes.message}>
            Activation failed because the token is not valid. Please request a
            new activation email.
          </p>
        )
      }
    }
  } else if (user) {
    message = (
      <div>
        <p className={classes.message}>
          Hi <span>{user.name}</span>!
        </p>
        <p className={classes.message}>
          Your account needs to be activated. We've sent an activation email to:
          <br />
          <br />
          <span>{user.email}</span>
        </p>
        <p className={classes.message}>
          If you haven't received an email you can request a new one using the
          button below. Don't forget to check the spam folder first.
        </p>
      </div>
    )
  }

  const buttonDisabled =
    loading || (data && data.verifyAccount) || emailRequested !== undefined
  return (
    <div className={classes.root}>
      <BasicHeader />
      <div className={classes.content}>
        <Typography variant='h5' className={classes.title}>
          Activate your account
        </Typography>
        <div>
          {message}
          <div>
            <ConfirmEmailDialog
              open={showDialog}
              title='Send account activation mail'
              content='Enter the email address you used when registering'
              onClose={handeDialogClosed}
            />
            <Button
              className={classes.button}
              disabled={buttonDisabled}
              variant='contained'
              color='primary'
              onClick={handleOnClick}
            >
              RESEND ACTIVATION EMAIL
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyAccountPage
