const getActionStatusLabel = (status) => {
  switch (status) {
    case 'acknowledged':
      return 'Acknowledged'
    case 'in_progress':
      return 'In progress'
    case 'completed':
      return 'Done'
    case 'rejected':
      return 'Rejected'
    default:
      return 'Not started'
  }
}

export { getActionStatusLabel }