import { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSearchParams, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import { TOKEN_USER } from '../graphql'

import BasicHeader from '../components/BasicHeader'
import SignupForm from '../components/SignupForm'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      padding: theme.spacing(2),
      alignSelf: 'flex-start',
    },
    link: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      fontSize: '14px',
    },
    content: {
      width: '100%',
      height: '100%',
      maxWidth: '30rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    logo: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      width: 150,
    },
    formWrapper: {
      boxSizing: 'border-box',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
  }
})

const SignupPage = () => {
  const [searchParams] = useSearchParams()
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Signup'
  })

  const token = searchParams.get('token')

  const { data: { tokenUser } = {}, loading, error } = useQuery(TOKEN_USER, {
    variables: { token },
    skip: !token,
  })

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error</div>

  return (
    <div className={classes.root}>
      <BasicHeader />
      <div className={classes.content}>
        <Typography variant='h5' className={classes.title}>
          Join RiskAce
        </Typography>
        <div className={classes.formWrapper}>
          <SignupForm className={classes.form} token={token} user={tokenUser} />
        </div>
        <Link className={classes.link} to='/login'>
          Already have an account?
        </Link>
      </div>
    </div>
  )
}

export default SignupPage
