import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import SessionsPage from './SessionsPage'
import SessionPage from './SessionPage'
import ActionsPage from './ActionsPage'
import OrganizationPage from './OrganizationPage'
import ProfilePage from './ProfilePage'
import Header from '../components/Header'
import ErrorBoundary from '../components/ErrorBoundary'
import routes from '../routes'

const InvitationsPage = lazy(() => import('./InvitationsPage'))

const AuthenticatedPages = () => (
  <>
    <Header />
    <ErrorBoundary>
      <Routes>
        <Route path={`${routes.ACTIONS}`} element={<ActionsPage />} />
        <Route path={`${routes.PROFILE}`} element={<ProfilePage />} />
        <Route
          path={`${routes.INVITATIONS}`}
          element={<InvitationsPage />}
        />
        <Route path={`${routes.ORGANIZATION}`} element={<OrganizationPage />} />
        <Route
          path={`${routes.RISK_ASSESSMENTS}/:id/:action`}
          element={<SessionPage />}
        />
        <Route
          path={`${routes.RISK_ASSESSMENTS}/:id`}
          element={<SessionPage />}
        />
        <Route path={`${routes.RISK_ASSESSMENTS}`} element={<SessionsPage />} />
      </Routes>
    </ErrorBoundary>
  </>
)

export default AuthenticatedPages
