import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Typography from '@material-ui/core/Typography'

import { RESET_PW } from '../graphql'
import routes from '../routes'
import BasicHeader from '../components/BasicHeader'
import ResetPasswordForm from '../components/ResetPasswordForm'

const KEY_START = '-----BEGIN ENCRYPTED PRIVATE KEY-----'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      fontSize: '14px',
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      maxWidth: 600,
    },
    logo: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      width: 150,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    message: {
      '& span': {
        fontWeight: 'bolder',
      },
    },
  }
})

const ResetPasswordPage = (props) => {
  const [searchParams] = useSearchParams()
  const [submitting, setSubmitting] = useState(false)
  const [complete, setComplete] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [resetPW] = useMutation(RESET_PW, { ignoreResults: true })
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Reset password'
  })

  const token = searchParams.get('token')

  if (redirect || !token) {
    return <Navigate to={routes.LOGIN} />
  }

  const handleSubmit = (password, keyFile) => {
    setSubmitting(true)
    var reader = new FileReader()
    reader.addEventListener('load', function (e) {
      const key = e.target.result
      if (key.length < 37 || key.substring(0, 37) !== KEY_START) {
        console.error('Invalid key file')
        setSubmitting(false)
      }
      resetPW({
        variables: {
          password,
          privateKey: btoa(e.target.result),
          token,
        },
        update: (proxy, result) => {
          setSubmitting(false)
          if (result.data.resetPassword) {
            setComplete(true)
            setTimeout(() => {
              setRedirect(true)
            }, 3000)
          }
        },
      })
    })
    reader.readAsText(keyFile)
  }

  return (
    <div className={classes.root}>
      <BasicHeader />
      <div className={classes.content}>
        <Typography variant='h5' className={classes.title}>
          {!complete
            ? 'Reset password and two-factor authentication'
            : 'Password successfully changed!'}
        </Typography>
        {complete && (
          <Typography variant='body1'>
            You will be automatically redirected to the login page in a few
            seconds...
          </Typography>
        )}
        {!complete && (
          <ResetPasswordForm
            disabled={submitting}
            submitting={submitting}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  )
}

export default ResetPasswordPage
