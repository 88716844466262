import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

import DatePicker from './DatePicker'
import TimePicker from './TimePicker'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: '7rem',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: theme.palette.primary.dark,
    },
    contentText: {
      marginBottom: '1rem',
    },
    dialogContent: {
      padding: 0,
    },
    label: {
      width: 40,
    },
    timeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    picker: {
      padding: '0 0.25em',
      marginLeft: '1.5em',
    },
    invitationLabel: {
      marginTop: theme.spacing(2),
    },
  }
})

const getDefaultDates = () => {
  var now = new Date()
  now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30)
  return {
    start: now,
    end: new Date(now.getTime() + 3600000),
  }
}

const getTime = (date) => {
  return (
    date?.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    }) ?? ''
  )
}

const ScheduleSessionDialog = (props) => {
  const { open, onClose } = props

  const classes = useStyles()

  useEffect(() => {
    if (open) {
      const defaultDates = getDefaultDates()
      setStart(defaultDates.start)
      setEnd(defaultDates.end)
    }
  }, [open])

  const [start, setStart] = useState()
  const [end, setEnd] = useState()

  const [sendInvitations, setSendInvitations] = useState(true)

  const handleClose = () => {
    if (onClose) {
      onClose(start, end, sendInvitations)
    }
  }

  const toggleSendInvitations = (evt) => {
    setSendInvitations(!sendInvitations)
  }

  const handleChangeStartDate = (value) => {
    const newStart = new Date(value)
    newStart.setHours(start.getHours())
    newStart.setMinutes(start.getMinutes())
    const duration = end.getTime() - start.getTime()
    setStart(newStart)
    setEnd(new Date(newStart.getTime() + duration))
  }

  const handleChangeStartTime = (value) => {
    const time = value.split(':')
    const duration = end.getTime() - start.getTime()
    const newStart = new Date(start)
    newStart.setHours(time[0], time[1])
    setStart(newStart)
    setEnd(new Date(newStart.getTime() + duration))
  }

  const handleChangeEndDate = (value) => {
    const newValue = new Date(value)
    newValue.setHours(end.getHours())
    newValue.setMinutes(end.getMinutes())
    setEnd(newValue)
  }

  const handleChangeEndTime = (value) => {
    const time = value.split(':')
    const newEnd = new Date(end)
    newEnd.setHours(time[0], time[1])
    setEnd(newEnd)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Schedule meeting</DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.timeContainer}>
          <Typography variant='body1' className={classes.label}>
            Start:
          </Typography>
          <DatePicker
            className={classes.picker}
            value={start}
            onChange={handleChangeStartDate}
          />
          <TimePicker value={getTime(start)} onChange={handleChangeStartTime} />
        </div>
        <div className={classes.timeContainer}>
          <Typography variant='body1' className={classes.label}>
            End:
          </Typography>
          <DatePicker
            className={classes.picker}
            value={end}
            onChange={handleChangeEndDate}
            CalendarProps={{
              minDate: start,
            }}
          />
          <TimePicker value={getTime(end)} onChange={handleChangeEndTime} />
        </div>
        <FormControlLabel
          className={classes.invitationLabel}
          control={
            <Checkbox
              checked={sendInvitations}
              onChange={toggleSendInvitations}
            />
          }
          label='Send meeting invites'
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.button} onClick={handleClose}>
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduleSessionDialog
