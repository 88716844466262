import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import routes from '../../routes'
import { isGuest } from '../../util'
import { SearchInput } from './SearchInput'

const useStyles = makeStyles((theme) => {
  return {
    search: {
      marginRight: theme.spacing(2),
    },
    checkbox: {
      fontSize: theme.typography.body2.fontSize,
    },
    checkboxRoot: {
      paddingRight: theme.spacing(0.5),
    },
    createButton: {
      marginLeft: 'auto',
    },
  }
})

const SessionsToolbar = ({
  filter,
  user,
  org,
  onSearchFilterChange,
  onStatusFilterChange,
  onOwnerFilterChange,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleOwnerClick = () => {
    onOwnerFilterChange?.(!filter.owner)
  }

  const handleStatusFilterClick = () => {
    onStatusFilterChange?.(!filter.showClosed)
  }

  const handleCreateClick = () => {
    navigate(`${routes.RISK_ASSESSMENTS}/new`)
  }

  const disableCreate = isGuest(org?.billing)
  return (
    <Toolbar className={classes.toolbar} disableGutters>
      <SearchInput
        classes={{ root: classes.search }}
        onChange={onSearchFilterChange}
      />
      <Tooltip title={'Only show risk assessments owned by me'}>
        <FormControlLabel
          classes={{ label: classes.checkbox }}
          control={
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              size='small'
              checked={filter.owner}
              onChange={handleOwnerClick}
              color='primary'
            />
          }
          label='Owned by me'
        />
      </Tooltip>
      <Tooltip title={'Show closed risk assessments'}>
        <FormControlLabel
          classes={{ label: classes.checkbox }}
          control={
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              size='small'
              checked={filter.showClosed}
              onChange={handleStatusFilterClick}
              color='primary'
            />
          }
          label='Show closed'
        />
      </Tooltip>
      <Button
        className={classes.createButton}
        variant='contained'
        color='secondary'
        onClick={handleCreateClick}
        disabled={disableCreate}
      >
        CREATE
      </Button>
    </Toolbar>
  )
}

export { SessionsToolbar }
