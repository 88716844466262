import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CopyIcon from '@material-ui/icons/FileCopy'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { RESET_MEMBER_PASSWORD } from '../graphql'

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(2),
    },
    email: {
      fontFamily: 'monospace',
      backgroundColor: '#eee',
      padding: '2px 16px',
      borderRadius: '4px',
    },
    password: {
      fontFamily: 'monospace',
      fontSize: '18px',
      backgroundColor: '#eee',
      padding: '8px 64px',
      marginRight: theme.spacing(2),
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    },
    alert: {
      marginLeft: theme.spacing(2),
    },
    revealIcon: {
      position: 'absolute',
      top: '6px',
      right: '24px',
    }
  }
})

const ResetMemberPasswordDialog = (props) => {
  const { open, onClose, user, organizationId } = props
  const classes = useStyles()

  const [confirmationText, setConfirmationText] = useState('')
  const [password, setPassword] = useState()
  const [maskPassword, setMaskPassword] = useState(true)
  const [copied, setCopied] = useState(false)

  const [resetMemberPassword, { loading }] = useMutation(
    RESET_MEMBER_PASSWORD,
    {
      onCompleted: (data) => {
        const password = data.resetMemberPassword
        setPassword(password)
      },
      onError: (error) => {
        resetAndClose({ status: 'error' })
      }
    }
  )

  const resetAndClose = (result) => {
    setConfirmationText('')
    setPassword()
    setCopied(false)
    onClose?.(result || { status: 'ok' })
  }

  const handleReset = () => {
    if (copied) {
      resetAndClose()
      return
    }
    submitReset()
  }

  const submitReset = () => {
    resetMemberPassword({ variables: { userId: user._id, organizationId } })
  }

  const handleClose = (evt, reason) => {
    if (!canClose()) {
      return
    }
    resetAndClose()
  }

  const handleCopy = async (evt) => {
    await navigator.clipboard.writeText(password)
    setCopied(true)
  }

  const handleToggleVisibility = (evt) => {
    evt.stopPropagation()
    setMaskPassword(!maskPassword)
  }

  const handleConfirmationTextKeyPress = (e) => {
    if (e.key === 'Enter' && confirmationText === user?.email) {
      submitReset()
    }
  }

  const canClose = () => {
    if (loading) {
      return false
    }
    if (password && !copied) {
      return false
    }
    return true
  }

  const getContent = () => {
    if (loading) {
      return <Box display='flex' height='150px' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    }
    if (password) {
      return <Grid container spacing={2}>
        <Grid item>
          <Alert severity='warning'>
            The password for {user?.email} has been reset. Copy the password and deliver it to the user in a safe way.
          </Alert>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Box display='flex' alignItems={'center'} height={48} onClick={handleCopy}>
              <Box position={'relative'} minWidth={'260px'}>
                <Typography variant='body1' className={classes.password}>
                  {maskPassword ? '************' : password}
                </Typography>
                <IconButton size='small' className={classes.revealIcon} onClick={handleToggleVisibility}>
                  {maskPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </Box>
              <IconButton size='small' onClick={handleCopy}>
                <CopyIcon />
              </IconButton>
              {
                copied && <Alert className={classes.alert} severity='success'>
                  Password copied!
                </Alert>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    }
    return <Grid container spacing={2}>
      <Grid item>
        <Alert severity='error'>
          You are about to reset the password for a user in your organization. The user will lose access to their account until given the new password.
        </Alert>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant='body1'>
            To reset the password enter:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex'>
            <Typography variant='body1' className={classes.email}>
              {user?.email}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex'>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              value={confirmationText}
              onChange={(e) => {
                setConfirmationText(e.target.value)
              }}
              onKeyPress={handleConfirmationTextKeyPress}
              required
              autoFocus
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  }

  return <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose}>
    <Box p={2} minHeight={209}>
      <Typography variant='h6' className={classes.title}>
        Password reset
      </Typography>
      {getContent()}
    </Box>
    <DialogActions>
      {password === undefined && !loading &&
        <Button disabled={loading} className={classes.button} onClick={handleClose}>
          Cancel
        </Button>}
      <Button
        disabled={confirmationText !== user?.email || !canClose()}
        variant='contained'
        color='primary'
        onClick={handleReset}
      >
        {password ? 'Close' : 'Reset Password'}
      </Button>
    </DialogActions>
  </Dialog>
}

export default ResetMemberPasswordDialog
