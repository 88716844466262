import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/client'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Alert from '@material-ui/lab/Alert'

import { isValidEmail } from '../util'
import { ORGANIZATION_MEMBERS } from '../graphql'

import UserSearchInput from './UserSearchInput'

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5),
      },
    },
    root: {
      [theme.breakpoints.down('xs')]: {
        minWidth: '310px',
      },
      minWidth: '340px',
      minHeight: '8rem',
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.primary.dark,
    },
    alert: {
      maxWidth: '308px',
      marginTop: theme.spacing(1),
    }
  }
})

const AddInvitationDialog = (props) => {
  const [user, setUser] = useState()
  const [closing, setClosing] = useState(false)

  const { open, onClose, participants, auth } = props
  const classes = useStyles()
  const btnRef = useRef(null)

  const { data } = useQuery(ORGANIZATION_MEMBERS, {
    variables: { id: auth?.org?._id },
    skip: !auth?.org?._id
  })

  useEffect(() => {
    if (open) {
      setUser()
      setClosing(false)
    }
  }, [open])

  const handleClose = (evt) => {
    setClosing(true)
    if (onClose) {
      onClose()
    }
  }

  const handleChangeUser = (value) => {
    setUser(value)
  }

  const handleAddClicked = (e) => {
    e.preventDefault()
    setClosing(true)
    onClose?.(user)
  }

  const emailValid = user && isValidEmail(user.email)
  const participating = user && participants?.find(p => p.user.email === user?.email)
  const canSubmit = !closing && emailValid && !participating

  const users = data?.organizationMembers?.filter(m => !participants?.find(p => p.user._id === m.user._id)).map(m => ({ ...m.user })) ?? []
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      onClose={handleClose}
      aria-labelledby='invite-user-dlg-title'
    >
      <form onSubmit={handleAddClicked}>
        <DialogTitle id='invite-user-dlg-title'>Invite participant</DialogTitle>
        <DialogContent classes={{ root: classes.root }}>
          <UserSearchInput className={classes.input} users={users} onSelect={handleChangeUser} />
          {participating &&
            <Alert className={classes.alert} severity='error'>
              The user is already participating in the session.
            </Alert>}
          {user && !emailValid && <Alert className={classes.alert} severity='error'>
            Invalid email address.
          </Alert>}
          {user && auth?.org?.domain && !user?.email?.includes(auth?.org?.domain) && emailValid &&
            <Alert className={classes.alert} severity='warning'>
              You are about to invite a user outside of your organization.
            </Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={!canSubmit}
            variant='contained'
            color='primary'
            ref={btnRef}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddInvitationDialog
