import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

const AlertSnackbar = ({ open, severity, text, onClose }) => {
  const handleClose = (evt) => {
    onClose?.()
  }

  return <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={open}
    autoHideDuration={3000}
    onClose={handleClose}
  >
    <Alert onClose={handleClose} severity={severity || 'error'}>
      {text || 'There was an unexpected error'}
    </Alert>
  </Snackbar>
}

export default AlertSnackbar