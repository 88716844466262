import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: '7rem',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: theme.palette.primary.dark,
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }
})

const SessionCloneDialog = ({ open, onClose }) => {
  const classes = useStyles()

  const [includeActionPlan, setIncludeActionPlan] = useState(false)
  const [includeUsers, setIncludeUsers] = useState(false)

  useEffect(() => {
    if (open) {
      setIncludeActionPlan(false)
      setIncludeUsers(false)
    }
  }, [open])

  const handleClone = (evt, reason) => {
    if (onClose) {
      onClose(evt, reason, { includeActionPlan, includeUsers })
    }
  }

  const toggleActionPlan = (evt) => {
    setIncludeActionPlan(!includeActionPlan)
  }

  const toggleUsers = (evt) => {
    setIncludeUsers(!includeUsers)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Clone assessment</DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <FormControlLabel
          className={classes.label}
          control={<Checkbox checked={includeUsers} onChange={toggleUsers} />}
          label='Include users'
        />
        <FormControlLabel
          className={classes.label}
          control={
            <Checkbox checked={includeActionPlan} onChange={toggleActionPlan} />
          }
          label='Include action plan'
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button className={classes.button} onClick={handleClone}>
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SessionCloneDialog
