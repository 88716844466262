import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      position: 'absolute',
      color: theme.palette.primary.dark,
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      margin: 'auto',
    },
  }
})

const ProgressButton = ({ loading, children = [], classes = {}, ...props }) => {
  const _classes = useStyles()

  return (
    <div className={`${_classes.wrapper} ${classes.root}`}>
      <Button className={classes.button} {...props}>
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={_classes.progress} />}
    </div>
  )
}

export default ProgressButton
