import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import Avatar from './Avatar'

const useStyles = makeStyles((theme) => {
  return {
    avatarWrapper: {
      position: 'relative',
      width: '36px',
      height: '36px',
    },
    avatar: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transition: 'transform 0.1s',
      transform: 'translate(-50%,-50%)',
      '@media (hover)': {
        '&:hover': {
          transform: 'translate(-50%,-65%)',
        },
      },
    },
  }
})

const AvatarList = ({
  users,
  selected,
  showStatus,
  hideAdd,
  onClick,
  onAdd,
  loading,
}) => {
  const classes = useStyles()
  const handleClick = (e, user) => {
    onClick && onClick(e, user)
  }

  const handleAdd = (e) => {
    onAdd && onAdd(e)
  }

  return (
    <Grid container>
      {loading &&
        [1, 2, 3].map((i) => (
          <Grid item key={`as-${i}`}>
            <div className={classes.avatarWrapper}>
              <Skeleton variant='circle' width={36} height={36} />
            </div>
          </Grid>
        ))}
      {users &&
        users.map((user) => {
          return (
            <Grid item key={`avatarlist${user._id}`}>
              <div className={classes.avatarWrapper}>
                <Avatar
                  className={classes.avatar}
                  user={user}
                  selected={selected && selected.includes(user._id)}
                  showStatus
                  onClick={handleClick}
                />
              </div>
            </Grid>
          )
        })}
      {!hideAdd && !loading && (
        <Grid item>
          <div className={classes.avatarWrapper}>
            <Avatar add className={classes.avatar} onClick={handleAdd} />
          </div>
        </Grid>
      )}
    </Grid>
  )
}

export default AvatarList
