import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Clear from '@material-ui/icons/Clear'

import DatePicker from './DatePicker'
import ActionRisk from './ActionRisk'
import InputField from './InputField'
import CommittableInputField from './CommittableInputField'
import UserSelect from './UserSelect'
import { getActionStatusLabel } from '../utils/actions'

const useStyles = makeStyles((theme) => {
  return {
    action: {
      padding: theme.spacing(1),
    },
    risk: {
      alignSelf: 'start',
    },
    more: {
      position: 'absolute',
      right: 0,
      top: 0,
      alignSelf: 'start',
    },
    priority: {
      minWidth: '6rem',
    },
    datepicker: {
      color: 'grey',
      fontSize: '0.75rem',
      paddingLeft: theme.spacing(1),
    },
    resolutionBox: {
      '& button:last-child': {
        display: 'none',
      },
      '&:hover': {
        '& button:last-child': {
          display: 'unset',
        },
      },
    },
  }
})

const Action = ({
  data: { action, risk, responsibleUser, sessionId, disabled, user },
  onPrioChanged,
  onStatusChanged,
  onResolutionAtChanged,
  onTitleChanged,
  onDescriptionChanged,
  onClickResponsible,
  onClickMore,
}) => {
  const classes = useStyles()
  const handlePrioChanged = (e) => {
    if (onPrioChanged && e.target.value !== action.priority) {
      onPrioChanged(e.target.value)
    }
  }

  const handleStatusChanged = (e) => {
    let value = e.target.value
    if (onStatusChanged && value !== action.status) {
      onStatusChanged(value)
    }
  }
  const handleResolutionAtChanged = (value) => {
    if (onResolutionAtChanged && value !== action.resolutionAt) {
      onResolutionAtChanged(value)
    }
  }
  const handleClearResolutionAt = () => {
    onResolutionAtChanged(null)
  }
  const handleChangeTitle = (value) => {
    onTitleChanged && onTitleChanged(value)
  }
  const handleChangeDescription = (value) => {
    onDescriptionChanged && onDescriptionChanged(value)
  }
  const handleResponsibleClick = (e, user) => {
    if (onClickResponsible) {
      onClickResponsible(e, user)
    }
  }

  const handleClickMore = (e) => {
    if (onClickMore) {
      onClickMore(e, action)
    }
  }

  const canEdit = !disabled
  return (
    <Paper className={classes.action}>
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <Grid item xs={12} md={10} container spacing={2}>
          <Grid item xs={12} md={3} container spacing={2}>
            <Grid item xs={12}>
              {risk && <ActionRisk className={classes.risk} risk={risk} />}
            </Grid>
            <Grid item xs={12}>
              <InputField
                className={classes.priority}
                disabled={!canEdit}
                label='Priority'
                value={action.priority}
                onChange={handlePrioChanged}
                select
              >
                <MenuItem value={'high'}>High</MenuItem>
                <MenuItem value={'medium'}>Medium</MenuItem>
                <MenuItem value={'low'}>Low</MenuItem>
              </InputField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={true} container spacing={2}>
            <Grid item xs={12}>
              <CommittableInputField
                disabled={!canEdit}
                label='Title'
                value={action.title}
                onChange={handleChangeTitle}
                fullWidth
                bold
              />
            </Grid>
            <Grid item xs={12}>
              <CommittableInputField
                id={`${action._id}-desc`}
                disabled={!canEdit}
                label='Description'
                value={action.description || ''}
                onChange={handleChangeDescription}
                placeholder={
                  disabled !== true ? 'Add a description...' : undefined
                }
                multiline
                minRows='4'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} container spacing={2}>
          <Grid item xs={6} md={12}>
            <Typography variant='body1' className={classes.datepicker}>
              Due date:
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              className={classes.resolutionBox}
            >
              <DatePicker
                disabled={!canEdit}
                value={
                  action.resolutionAt ? new Date(action.resolutionAt) : null
                }
                onChange={handleResolutionAtChanged}
              />
              {action.resolutionAt && (
                <Tooltip title='Clear date'>
                  <IconButton
                    size='small'
                    disabled={!canEdit}
                    onClick={handleClearResolutionAt}
                  >
                    <Clear fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} md={12}>
            <UserSelect
              label='Responsible:'
              small
              user={responsibleUser}
              defaultTooltip={'Set responsible'}
              disabled={!canEdit}
              onClick={handleResponsibleClick}
            />
          </Grid>
          <Grid item xs={12} md={3} container spacing={2}>
            <Grid item xs={12}>
              <InputField
                className={classes.priority}
                disabled={!canEdit}
                label='Status'
                value={action.status}
                onChange={handleStatusChanged}
                select
              >
                <MenuItem value={'not_started'}>
                  {getActionStatusLabel('not_started')}
                </MenuItem>
                <MenuItem value={'acknowledged'}>
                  {getActionStatusLabel('acknowledged')}
                </MenuItem>
                <MenuItem value={'in_progress'}>
                  {getActionStatusLabel('in_progress')}
                </MenuItem>
                <MenuItem value={'completed'}>
                  {getActionStatusLabel('completed')}
                </MenuItem>
                <MenuItem value={'rejected'}>
                  {getActionStatusLabel('rejected')}
                </MenuItem>
              </InputField>
            </Grid>
          </Grid>
        </Grid>
        <IconButton
          disabled={!canEdit}
          classes={{ root: classes.more }}
          onClick={handleClickMore}
        >
          <MoreVertIcon />
        </IconButton>
      </Grid>
    </Paper>
  )
}

export default Action
