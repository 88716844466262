import { useSubscription } from '@apollo/client'
import { gql } from '@apollo/client'
import { USER_CHANGED } from '../graphql/index'
import AvatarList from './AvatarList'

const SessionUserList = (props) => {
  useSubscription(USER_CHANGED, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const user = subscriptionData?.data?.userChanged
      if (!user) {
        return
      }
      client.writeFragment({
        id: user._id,
        fragment: gql`
          fragment lastActive on User {
            lastActiveAt
          }
        `,
        data: {
          __typename: 'User',
          lastActiveAt: user.lastActiveAt,
        },
      })
    },
  })
  return <AvatarList {...props} />
}

export default SessionUserList
