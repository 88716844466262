import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

const useStyles = makeStyles((theme) => {
  return {
    button: {
      color: theme.palette.primary.dark,
    },
  }
})

const ConfirmationDialog = (props) => {
  const {
    open,
    handleClose,
    handleConfirm,
    data,
    title,
    content,
    cancelButtonText,
    confirmButtonText,
  } = props
  const classes = useStyles()
  const _handleConfirm = () => {
    if (handleConfirm) {
      handleConfirm(data)
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          {cancelButtonText ?? 'CANCEL'}
        </Button>
        <Button className={classes.button} onClick={_handleConfirm} autoFocus>
          {confirmButtonText ?? ''}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
