import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import Skeleton from '@material-ui/lab/Skeleton'

import { SET_INVOICE_ADDRESS } from '../graphql'
import { getFormattedAddress } from '../countries'
import InvoiceAddressDialog from './InvoiceAddressDialog'

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: 'flex',
    },
    editBtn: {
      marginTop: -theme.spacing(0.5),
      marginLeft: theme.spacing(2),
    },
  }
})

const BillingSection = ({ billingInfo, canEdit, refetch }) => {
  const classes = useStyles()
  const [showInvoiceAddressDialog, setShowInvoiceAddressDialog] =
    useState(false)

  const [setInvoiceAddress] = useMutation(SET_INVOICE_ADDRESS, {
    onCompleted: (data) => {
      refetch && refetch({ fetchPolicy: 'network-only' })
    },
  })

  const invoiceAddress = billingInfo?.invoiceAddress

  const handleEditInvoiceAddress = () => {
    setShowInvoiceAddressDialog(!showInvoiceAddressDialog)
  }

  const handleCloseEditInvoiceDialog = (invoiceAddress) => {
    setShowInvoiceAddressDialog(false)
    invoiceAddress &&
      setInvoiceAddress({ variables: { input: invoiceAddress } })
  }

  const getInvoiceAddress = () => {
    return !billingInfo ? (
      <>
        {[...Array(5)].map((e, i) => (
          <Skeleton
            key={`invoiceAddr${i}`}
            variant='text'
            width={150}
            height={24}
          />
        ))}
      </>
    ) : (
      <>
        {getFormattedAddress(invoiceAddress).map(
          (v, i) =>
            v && (
              <Typography key={`invoiceAddress${i}`} variant='body1'>
                {v}
              </Typography>
            )
        )}
      </>
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Box width='100%' m={2} display='flex'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='caption'>Invoice Address</Typography>
              <Box mt={1} display='flex'>
                <Box display='flex' flexDirection='column'>
                  {getInvoiceAddress()}
                </Box>
                {canEdit &&
                  <div>
                    <Tooltip title='Edit Invoice Address'>
                      {invoiceAddress ?
                        <IconButton
                          size='small'
                          className={classes.editBtn}
                          onClick={handleEditInvoiceAddress}
                        >
                          <EditIcon />
                        </IconButton> : <Button
                          variant='contained'
                          color='primary'
                          onClick={handleEditInvoiceAddress}
                        >
                          Set invoice address
                        </Button>}
                    </Tooltip>
                  </div>}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {billingInfo && (
        <InvoiceAddressDialog
          invoiceAddress={invoiceAddress}
          open={showInvoiceAddressDialog}
          onClose={handleCloseEditInvoiceDialog}
        />
      )}
    </>
  )
}

export default BillingSection
