import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from './vfs_fonts'
import moment from 'moment'

import { drawMatrix, drawRiskColor, drawAxis, drawNumbers } from './canvas'
import { formatTimestamp } from './util'

pdfMake.vfs = pdfFonts

pdfMake.fonts = {
  Carlito: {
    normal: 'Carlito-Regular.ttf',
    bold: 'Carlito-Bold.ttf',
  },
  Barlow: {
    normal: 'Barlow-Medium.ttf',
  },
  texgyrecursor: {
    normal: 'texgyrecursor-regular.otf',
  },
}

const RISK_COLOR_SIZE = 12
const CONTENT_MARGIN = 60
const HEADING_SIZE = 18
const COLOR_GREY = '#aaa'
const colorCache = {}

const generateRiskColor = (risk, size) => {
  const color = risk ? risk.color : 'white'
  if (colorCache[`${color}${size}`]) {
    return colorCache[`${color}${size}`]
  }
  const canvasSize = size ? size * 10 : RISK_COLOR_SIZE * 10
  const canvas = document.createElement('canvas')
  canvas.width = canvasSize
  canvas.height = canvasSize
  let ctx = canvas.getContext('2d')
  drawRiskColor(ctx, canvasSize, color)
  const dataUrl = canvas.toDataURL()
  colorCache[`${color}${size}`] = dataUrl
  return dataUrl
}

const getRiskStatus = (risk, size) => {
  return {
    image: generateRiskColor(risk, size),
    width: size || RISK_COLOR_SIZE,
    height: size || RISK_COLOR_SIZE,
  }
}

const ACTION_LAYOUT = {
  defaultBorder: false,
  paddingLeft: function (i, node) {
    return 0
  },
  paddingRight: function (i, node) {
    return 0
  },
  paddingTop: function (i, node) {
    return 0
  },
  paddingBottom: function (i, node) {
    return 0
  },
}

const getLogoObject = (logo) => {
  const result = {
    width: 100,
    alignment: 'right',
    margin: [0, 43, 20, 0],
  }
  if (!logo) {
    return result
  }
  if (logo.startsWith('<svg')) {
    result.svg = logo
  } else {
    result.image = logo
  }
  return result
}

const generatePdf = (
  session,
  risks = [],
  actions = [],
  participants,
  log,
  logo,
  options
) => {
  const download = options ? options.download : false
  const includeActionPlan = options ? options.includeActionPlan : true
  const includeLog = log && (options ? options.includeLog : true)
  const dateStr = moment(new Date(session.createdAt)).format('L')

  const canvas = document.createElement('canvas')

  const sizeX = session.matrix.size.x
  const sizeY = session.matrix.size.y

  const matrixSize = 800
  let matrixWidth
  let matrixHeight
  if (sizeX === sizeY) {
    matrixWidth = matrixHeight = matrixSize
  } else if (sizeX > sizeY) {
    matrixWidth = matrixSize
    matrixHeight = Math.trunc((matrixSize / sizeX) * sizeY)
  } else {
    matrixWidth = Math.trunc((matrixSize / sizeY) * sizeX)
    matrixHeight = matrixSize
  }

  const cellSize = Math.trunc(matrixSize / Math.max(sizeX, sizeY))

  const axisSize = 50
  const margin = 10
  const numberSize = 40

  const indicatorSize = 22
  const matrixPadding = indicatorSize

  const totalWidth =
    matrixWidth + axisSize + margin + numberSize + margin + matrixPadding
  const totalHeight =
    matrixHeight + axisSize + margin + numberSize + margin + matrixPadding

  const matrixXOffset = axisSize + margin + numberSize + margin

  canvas.width = totalWidth
  canvas.height = totalHeight

  let ctx = canvas.getContext('2d')
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, totalWidth, totalHeight)

  drawNumbers(
    ctx,
    session.matrix,
    matrixXOffset,
    matrixHeight + numberSize / 2 + matrixPadding,
    cellSize
  )
  drawNumbers(
    ctx,
    session.matrix,
    axisSize + margin,
    matrixPadding,
    cellSize,
    true
  )

  drawAxis(
    ctx,
    matrixXOffset,
    matrixHeight + numberSize + margin * 2 + matrixPadding,
    matrixWidth,
    axisSize,
    'black',
    session.matrix.hAxisLabel || ''
  )
  drawAxis(
    ctx,
    0,
    matrixPadding,
    axisSize,
    matrixHeight,
    'black',
    session.matrix.vAxisLabel || '',
    true
  )

  drawMatrix(
    ctx,
    session.matrix,
    matrixXOffset,
    matrixPadding,
    cellSize,
    indicatorSize,
    risks
  )

  const matrixImageData = canvas.toDataURL()
  let riskContent = []

  for (const r of risks) {
    riskContent.push({
      table: {
        body: [
          [
            {
              columns: [
                getRiskStatus(r),
                {
                  text: `${r.identifier}. ${r.title}`,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 25,
            },
          ],
          [
            {
              text: r.description,
            },
          ],
        ],
      },
      layout: 'noBorders',
      margin: [0, 0, 0, 30],
      headlineLevel: 1,
    })
  }
  const owner = session.owner
  let participantContent = []
  if (participants?.length > 0) {
    participantContent.push({
      text: 'Participants',
      style: 'subheading',
      margin: [0, 15, 0, 15],
    })
    for (const p of participants) {
      if (p.status !== 'declined') {
        if (p.user) {
          participantContent.push({
            text: p.user.name ? `${p.user.name} (${p.user.email})` : p.user.email,
            margin: [0, 0, 0, 5],
          })
        }
      }
    }
  }

  const mainContent = [
    {
      text: session.name,
      style: 'bold',
      fontSize: HEADING_SIZE,
      margin: [0, 90, 0, 20],
    },
    {
      columns: [
        {
          stack: [
            {
              text: 'Date:',
              margin: [0, 0, 0, 10],
            },
            {
              text: 'Author:',
              margin: [0, 0, 0, 5],
            },
            'Organization:',
          ],
          width: 'auto',
        },
        {
          stack: [
            {
              text: dateStr,
              margin: [0, 0, 0, 10],
            },
            {
              text: owner ? `${owner.name} (${owner.email})` : `Deleted user`,
              margin: [0, 0, 0, 5],
            },
            session.organizationName || ''
          ],
        },
      ],
      columnGap: 30,
      margin: [0, 0, 0, 40],
    },
    {
      text: session.description,
      margin: [0, 0, 0, 30],
    },
    ...participantContent,
    {
      text: 'Risk assessment',
      style: {
        fontSize: HEADING_SIZE,
        bold: true,
      },
      margin: [0, 15, 0, 25],
      pageBreak: 'before',
    },
    {
      image: matrixImageData,
      width: Math.trunc(totalWidth / 2.75),
      height: Math.trunc(totalHeight / 2.75),
      margin: [0, 0, 0, 25],
      style: 'center',
    },
    ...riskContent,
  ]

  if (includeActionPlan) {
    mainContent.push({
      text: 'Action Plan',
      style: {
        fontSize: HEADING_SIZE,
        bold: true,
      },
      margin: [0, 15, 0, 25],
      pageBreak: 'before',
    })
    for (const a of actions) {
      const risk = risks.find((r) => r._id === a.riskId)
      const borderColor = a.priority === 'high' ? 'red' : 'green'
      const user = participants.find((p) => a.ownerId === p.user._id)?.user
      const responsible = user ? user.name || user.email : ''
      const priority = a.priority
        ? a.priority.charAt(0).toUpperCase() + a.priority.slice(1)
        : ''
      const dueDate = a.resolutionAt
        ? moment(new Date(a.resolutionAt)).format('L')
        : ''
      mainContent.push({
        table: {
          widths: ['*'],
          body: [
            [{ text: ' ', style: 'invisibleParagraph' }],
            [
              {
                table: {
                  widths: ['auto', '*'],
                  body: [
                    [
                      { ...getRiskStatus(risk, 8), margin: [0, 3, 0, 0] },
                      [
                        {
                          columns: [
                            {
                              stack: [
                                {
                                  text: a.title,
                                  margin: [0, 0, 0, 3],
                                },
                                `Due:\t${dueDate}`,
                              ],
                              width: '*',
                            },
                            {
                              stack: [
                                {
                                  text: 'Priority:',
                                  margin: [0, 0, 0, 3],
                                },
                                'Responsible:',
                              ],
                              width: 'auto',
                            },
                            {
                              stack: [
                                {
                                  text: priority,
                                  margin: [0, 0, 0, 3],
                                },
                                responsible,
                              ],
                              width: 'auto',
                            },
                          ],
                          columnGap: 20,
                        },
                        {
                          text: a.description,
                          margin: [0, 20, 0, 0],
                        },
                      ],
                    ],
                  ],
                },
                layout: {
                  vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 1 : 0
                  },
                  hLineColor: function (i, node) {
                    return borderColor
                  },
                  vLineColor: function (i, node) {
                    return borderColor
                  },
                  paddingLeft: function (i, node) {
                    return i === 0 ? 15 : 5
                  },
                  paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 15 : 5
                  },
                  paddingTop: function (i, node) {
                    return 20
                  },
                  paddingBottom: function (i, node) {
                    return 20
                  },
                },
              },
            ],
            [' '],
          ],
        },
        layout: ACTION_LAYOUT,
        headlineLevel: 1,
      })
    }
  }

  if (includeLog && log) {
    mainContent.push({
      text: 'Event Log',
      style: {
        fontSize: HEADING_SIZE,
        bold: true,
      },
      margin: [0, 15, 0, 25],
      pageBreak: 'before',
    })
    for (const e of log) {
      mainContent.push({
        columns: [
          {
            text: formatTimestamp(e.t, true),
            width: '27%',
          },
          e.text,
        ],
        style: 'log',
      })
    }
  }

  var docDefinition = {
    info: {
      title: session.name,
      author: owner ? owner.name : 'Deleted user',
      subject: session.description,
      creator: 'RiskAce',
      producer: 'RiskAce',
    },
    defaultStyle: {
      font: 'Carlito',
      lineHeight: 1.1,
    },
    header: function (currentPage, pageCount, pageSize) {
      const colTitlePage = {
        text: 'Risk assessment report',
        margin: [CONTENT_MARGIN, 45, 0, 30],
        style: ['barlow', { fontSize: HEADING_SIZE }],
      }
      const col = {
        stack: [session.name, ' ', dateStr],
        margin: [CONTENT_MARGIN, 40, 0, 0],
        style: ['barlow', { fontSize: 12 }],
      }
      return [
        {
          columns: [
            currentPage === 1 ? colTitlePage : col,
            getLogoObject(logo),
          ],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 25,
              y1: 0,
              x2: pageSize.width - 25,
              y2: 0,
              lineWidth: 1,
              lineColor: '#9ccc65',
            },
          ],
          margin: [0, 20, 0, 0],
        },
      ]
    },
    content: mainContent,
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text: `Powered by Riskace.com – leader in digital risk management`,
            width: 'auto',
          },
          {
            text: `Page ${currentPage.toString()} (${pageCount})`,
            alignment: 'right',
          },
        ],
        margin: [CONTENT_MARGIN, 20, 50, 0],
        style: ['barlow', { fontSize: 10 }],
      }
    },
    pageMargins: [CONTENT_MARGIN, 130, CONTENT_MARGIN, 60],
    styles: {
      subheading: {
        fontSize: 14,
        bold: true,
      },
      barlow: {
        font: 'Barlow',
        color: COLOR_GREY,
      },
      bold: {
        bold: true,
      },
      log: {
        font: 'texgyrecursor',
        fontSize: 10,
      },
      center: {
        alignment: 'center',
      },
      invisibleParagraph: {
        lineHeight: 0,
      },
    },
    pageBreakBefore: function (currentNode) {
      return (
        currentNode.headlineLevel === 1 && currentNode.pageNumbers.length > 1
      )
    },
  }
  if (download) {
    pdfMake.createPdf(docDefinition).download(`${session.name}.pdf`)
  } else {
    pdfMake.createPdf(docDefinition).open()
  }
}

export default generatePdf
